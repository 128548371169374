import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import HeaderDefaultOptionInput from './header-default-option-input.component';

interface Properties {
	value: string;
	onOptionChange: (e: SelectMenuOptionType) => void;
	options: string[];
}

const HeaderDefaultOptionWrapper: FunctionComponent<Properties> = ({ value, onOptionChange, options }) => {
	return (
		<Row className='mt-2 mb-2'>
			<Col>
				<HeaderDefaultOptionInput value={value} onOptionChange={onOptionChange} options={options} />
			</Col>
		</Row>
	);
};

export default HeaderDefaultOptionWrapper;
