import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

type Properties = {
	children: any;
	onSave: () => void;
	onCancel: () => void;
	name: string;
	t: any;
	dragHandler: any;
	widgetId?: string;
};

export const EditView = ({ children, onSave, onCancel, name, t, dragHandler, widgetId }: Properties) => {
	return (
		<div className='block bg-light mb-2 rounded border'>
			<h6 className='p-2'>
				{dragHandler}
				<span data-qa={widgetId} className='blocky-title'>
					{t(name)}
				</span>
			</h6>
			<div className='block-content'>
				<Row>
					<Col>{children}</Col>
				</Row>
				<Row>
					<Col className='clearfix'>
						<Button color='primary' bssize='sm' className='cursor-pointer mr-1' onClick={onSave}>
							<i className='fa fa-floppy-o'></i> {t('save')} {t('block')}
						</Button>
						<Button color='outline-secondary' className='pull-right cursor-pointer' bssize='sm' onClick={onCancel}>
							<i className='fa fa-ban'></i> {t('cancel')}
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};
