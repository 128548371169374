import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TennisTournamentModel from '../../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisRoundModel from '../../../../../../../models/v2/round/tennis-round/tennis-round.model';
import { TennisTournamentProgrammeJson } from './tennis-tournament-programme.json';
import TennisTournamentProgrammeBuilder from './tennis-tournament-programme.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TennisTournamentProgrammeModel {
	readonly competition: CompetitionModel;
	readonly season: TennisCompetitionSeasonModel;
	readonly tournament: TennisTournamentModel;
	readonly tournaments: TennisTournamentModel[];
	readonly rounds: TennisRoundModel[];
	readonly availableRounds: TennisRoundModel[];
	readonly limit: string;
	readonly dataDateFrom: string;
	readonly dataDateTo: string;
	readonly sortDirectionFixtures: string;
	readonly sortDirectionResults: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType;
	readonly gender: string;
	readonly gameType: string;
	readonly displayHeader: boolean = false;

	private constructor(
		competition: CompetitionModel,
		season: TennisCompetitionSeasonModel,
		tournament: TennisTournamentModel,
		tournaments: TennisTournamentModel[],
		rounds: TennisRoundModel[],
		availableRounds: TennisRoundModel[],
		limit: string,
		dataDateFrom: string,
		dataDateTo: string,
		sortDirectionFixtures: string,
		sortDirectionResults: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
		gender: string,
		gameType: string,
		displayHeader: boolean,
	) {
		this.competition = competition;
		this.season = season;
		this.tournament = tournament;
		this.tournaments = tournaments;
		this.rounds = rounds;
		this.availableRounds = availableRounds;
		this.limit = limit;
		this.dataDateFrom = dataDateFrom;
		this.dataDateTo = dataDateTo;
		this.sortDirectionFixtures = sortDirectionFixtures;
		this.sortDirectionResults = sortDirectionResults;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
		this.gender = gender;
		this.gameType = gameType;
		this.displayHeader = displayHeader;
	}

	toJson(): TennisTournamentProgrammeJson {
		return {
			competition: this.competition,
			season: this.season,
			tournament: this.tournament,
			tournaments: this.tournaments,
			rounds: this.rounds,
			availableRounds: this.availableRounds,
			limit: this.limit,
			dataDateFrom: this.dataDateFrom,
			dataDateTo: this.dataDateTo,
			sortDirectionFixtures: this.sortDirectionFixtures,
			sortDirectionResults: this.sortDirectionResults,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
			gender: this.gender,
			gameType: this.gameType,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: TennisTournamentProgrammeJson): TennisTournamentProgrammeModel {
		return new TennisTournamentProgrammeModel(
			json.competition,
			json.season,
			json.tournament,
			json.tournaments,
			json.rounds,
			json.availableRounds,
			json.limit,
			json.dataDateFrom,
			json.dataDateTo,
			json.sortDirectionFixtures,
			json.sortDirectionResults,
			json.displayOdds,
			json.bookmakers,
			json.headerDefaultOption,
			json.refreshTime,
			json.gender,
			json.gameType,
			json.displayHeader,
		);
	}

	static builder(tournamentProgrammeWidget?: TennisTournamentProgrammeModel): TennisTournamentProgrammeBuilder {
		return new TennisTournamentProgrammeBuilder(tournamentProgrammeWidget);
	}
}
