import React, { FunctionComponent, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import '../../../style/live-blog.style.scss';
import { emptyEventRowAvailable, findIsEventAlreadySelected, removeEventById } from './live-blog-events.helper';
import { EventInfoCard } from './live-blog-selected-events-card.component';
import { EventsModal } from './live-blog-events.modal';
import LiveBlogMatchModel from './models/live-blog-match-model';
import { useTranslation } from 'react-i18next';
import { LiveBlogTypes } from '../../../helpers/utility.helper';

type Properties = {
	liveBlog: LiveBlogModel;
	setSelectedLiveBlogEvents: (events: LiveBlogMatchModel[]) => void;
	seasonId: string;
};

export const LiveBlogEventsSelect: FunctionComponent<Properties> = ({ liveBlog, setSelectedLiveBlogEvents, seasonId }) => {
	const [toggle, setToggle] = useState<boolean>(false);
	const [selectedEventForEdit, setSelectedEventForEdit] = useState({});
	const [t] = useTranslation();

	const handleAddEventRow = (event: any) => {
		if (!emptyEventRowAvailable(liveBlog.sport_events)) {
			const newEventsArr = [...liveBlog.sport_events, event].reverse();
			setSelectedLiveBlogEvents(newEventsArr);
		}
	};

	const handleDeleteRow = (eventId: number) => {
		const newCollaboratorArr = removeEventById(liveBlog.sport_events, eventId);
		setSelectedLiveBlogEvents(newCollaboratorArr);
	};

	const onLiveBlogEventSelect = (event: any) => {
		let isAlreadyExist = findIsEventAlreadySelected(liveBlog.sport_events, event);

		if (isAlreadyExist) {
			const array = liveBlog.sport_events.filter((element: any) => {
				return element.id !== event.id;
			});
			const newEventsArr = [...array, event].reverse();
			setSelectedLiveBlogEvents(newEventsArr);
			setSelectedEventForEdit({});
		} else {
			handleAddEventRow(event);
			setSelectedEventForEdit({});
		}
	};

	const toggleModal = () => {
		setToggle(!toggle);
	};

	const removeSelectedEvent = () => {
		setSelectedEventForEdit({});
	};

	return (
		<>
			<Row>
				<Col>
					<Label className='events-label' htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_EVENTS}>
						<strong>{t('events')}</strong>
					</Label>
					{liveBlog.type === LiveBlogTypes.MATCH && <span className='text-danger'>*</span>}
				</Col>
			</Row>
			{liveBlog.sport_events &&
				liveBlog.sport_events.length > 0 &&
				liveBlog.sport_events.map((event: any, index: number) => (
					<div className='card events-list' key={DATA_QA_ATTRIBUTES.LIVE_BLOG_EVENTS + `-${event.id}`}>
						<EventInfoCard event={event} index={index} handleDeleteRow={handleDeleteRow} />
					</div>
				))}
			{toggle && (
				<EventsModal
					toggleModal={setToggle}
					isOpen={toggle}
					liveBlog={liveBlog}
					pickNewEvent={onLiveBlogEventSelect}
					initialAdd={liveBlog.sport_events.length < 1}
					selectedEventForEdit={selectedEventForEdit}
					removeSelectedEvent={removeSelectedEvent}
					seasonId={seasonId}
				/>
			)}
			<Row>
				<Button className='events-button' color='primary' outline onClick={toggleModal}>
					<i className='fa fa-plus mx-1' />
					{t('add_event')}
				</Button>
			</Row>
		</>
	);
};
