import BlockModel from '../../../../../models/block.model';
import { ViewTypes } from '../../../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../../../block-update-controller.component';
import { compose } from 'redux';
import { WidgetPollManagerView } from './polls-manager-view.component';
import PollManagerEditComponent from './polls-manager-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
};

const PollsManager: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <PollManagerEditComponent block={props.block} />;
	}

	if (props.view === ViewTypes.normal) {
		return <WidgetPollManagerView widgetPollsManager={props.block.data.widget} />;
	}

	return null;
};

export default compose(BlockUpdateController)(PollsManager);
