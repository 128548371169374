import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../constants/general.constants';
import BlockUpdateController from '../../../blocks/block-update-controller.component';
import { SportTypes } from '../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import TennisPlayoffWidgetEdit from './tennis-playoff-edit.component';
import { TennisPlayoffViewComponent } from './tennis-playoff-view.component';
import TennisPlayoffModel from './models/tennis-playoff.model';
import { BlockyV2Properties, shouldNormalViewBlocky, shouldPreviewBlocky } from '../../utils/helper';

const TennisPlayoffWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <TennisPlayoffWidgetEdit block={block} contentData={contentData} t={t} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.TENNIS} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<TennisPlayoffViewComponent
				t={t}
				preview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: TennisPlayoffModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(TennisPlayoffWidget);
