import React, { FunctionComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DateTimeSelect from '../../../../Sidebar/general-content/subcomponents/date-time/date-time-select';
import moment from 'moment';

type Properties = {
	date: string;
	onChange: (value: string) => void;
	label: string;
	id: string;
};

export const DateRangeInput: FunctionComponent<Properties> = ({ date, onChange, label, id }) => {
	const { t } = useTranslation();

	const onDateChange = (date: Date[] | null) => {
		
		const selectedDate = date && date.length >= 0 ? date[0] : '';
		const formatedDate = moment(selectedDate).isValid() ? moment(selectedDate).format('YYYY-MM-DD') : '';
		onChange(formatedDate);
	};

	return (
		<FormGroup>
			<Label htmlFor={id}>{label}:</Label>
			<DateTimeSelect date={date} onChange={onDateChange} id={id} t={t} withoutTime={true} placeholder={t('date_input_placeholder')}/>
		</FormGroup>
	);
};
