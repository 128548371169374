import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import moment from 'moment';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	widgetPollsManager: FansUnitedWidgetModel;
};

export const WidgetPollManagerView: React.FunctionComponent<Properties> = ({ widgetPollsManager }) => {
	const [t] = useTranslation();

	if (widgetPollsManager && widgetPollsManager.poll && widgetPollsManager.poll.id) {
		return (
			<div data-qa='widget-poll-view'>
				<Row className='d-flex p-2  align-items-center mb-1'>
					<Col className='ml-2'>
						<strong>{moment(widgetPollsManager.poll.created_at ? widgetPollsManager.poll.created_at : '').format('MMM Do YYYY, HH:mm')}</strong>
					</Col>
					<Col className='mr-1'>
						<strong>{widgetPollsManager.poll.title}</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				<Row className='px-4 pt-1'>
					{widgetPollsManager.classicQuiz && widgetPollsManager.poll.status && (
						<Col className='text-left'>
							<span>{t('status')}: </span>
							<Badge
								color={`${widgetPollsManager.poll.status === 'ACTIVE' ? 'success' : 'secondary'} `}
							>{`${widgetPollsManager.poll.status}`}</Badge>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetPollsManager.poll && widgetPollsManager.poll.description && (
						<Col className='text-left'>
							<span>{t('description')}: </span>
							<strong>{t(widgetPollsManager.poll.description)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetPollsManager.poll && (
						<Col className='text-left'>
							<span>{t('votes_count')}: </span>
							<strong>{widgetPollsManager.poll.total_votes}</strong>
						</Col>
					)}
				</Row>
			</div>
		);
	}

	return null;
};
