import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';
import LivescoreWidgetModel from '../../../blocks/widgets/livescore/models/livescore-widget.model';
import { generateRefreshTimeOptionViewText } from '../../partials/refresh-time-input/helper';
import { generateDisplayHeaderOptionViewText } from '../../partials/header-display-input/helper';
import { generateHeaderDefaultOptionViewText } from '../../partials/header-default-option-input/helper';

type Properties = {
	livescorePreview: LivescoreWidgetModel;
	t: any;
};

export const TennisLivescoreWidgetView: React.FunctionComponent<Properties> = ({ livescorePreview, t }) => {
	if (livescorePreview && livescorePreview.date && livescorePreview.date !== '') {
		const formatedDate = moment(livescorePreview.date).format('YYYY.MM.DD');
		return (
			<>
				<Row className={'pb-2 pl-4 pr-4'} data-qa='tennis-livescore-view'>
					<Col className='text-center'>
						<Row>
							<Col className='text-left'>
								{t('selected_date')}:
								<strong>
									<span>{` ${formatedDate}`}</span>
								</strong>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className={'pl-4 pr-4'}>
					<Col className='text-center'>
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={livescorePreview.displayOdds ? 'text-success' : 'text-danger'}>
										{livescorePreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
					</Col>
				</Row>
				{livescorePreview.displayOdds && livescorePreview.bookmakers && livescorePreview.bookmakers.length > 0 && (
					<Row className='pb-4 pl-4 pr-4'>
						<Col>
							{t('show')}:
							{livescorePreview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
								return (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				{generateHeaderDefaultOptionViewText(livescorePreview.headerDefaultOption)}
				{generateDisplayHeaderOptionViewText(livescorePreview.displayHeader)}
				{generateRefreshTimeOptionViewText(livescorePreview.refreshTime)}
			</>
		);
	}

	return null;
};
