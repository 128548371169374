import React from 'react';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

type Properties = {
	children: any;
	onRemove: () => void;
	t: any;
	name: string;
	dragHandler: any;
	id: string;
	changeBlocksToCopy: (value: boolean) => void;
	selected: boolean;
	blocksLength: number;
};

export const EditorView = ({ children, t, name, onRemove, dragHandler, changeBlocksToCopy, id, selected, blocksLength }: Properties) => {
	const toggleCopyBlock = (e: any) => changeBlocksToCopy(e.target.checked);

	return (
		<div className='block'>
			<h6 className='p-1 d-flex align-items-center'>
				{dragHandler}
				<input className='mr-1' id={`copy-block-${name}-${id}`} onChange={toggleCopyBlock} checked={selected} type='checkbox' />
				<label className='m-0 blocky-title' htmlFor={`copy-block-${name}-${id}`} data-qa='editor_block'>
					{t(name)}
				</label>
			</h6>
			<div className='block-actions d-none'>
				{blocksLength > 1 && (
					<div className='btn-group btn-group-sm' role='group' aria-label='Basic example'>
						<Button color='danger' onClick={onRemove}>
							<i className='fa fa-trash'></i> {t('remove')}
						</Button>
					</div>
				)}
			</div>
			<Row>
				<Col>{children}</Col>
			</Row>
		</div>
	);
};
