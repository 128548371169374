import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../constants/general.constants';
import BlockUpdateController from '../../../blocks/block-update-controller.component';
import { SportTypes } from '../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import TennisAthleteProgrammeModel from './models/tennis-athlete-programme.model';
import { TennisAthleteProgrammeViewComponent } from './tennis-athlete-programme-view.component';
import TennisAthleteProgrammeEditComponent from './tennis-athlete-programme-edit.component';
import { BlockyV2Properties, shouldNormalViewBlocky, shouldPreviewBlocky } from '../../utils/helper';

const TennisAthleteProgrammeWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <TennisAthleteProgrammeEditComponent block={block} contentData={contentData} contentLanguage={contentLanguage} t={t} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.TENNIS} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<TennisAthleteProgrammeViewComponent
				preview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: TennisAthleteProgrammeModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(TennisAthleteProgrammeWidget);
