import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import FootballSingleEventWidgetModel from '../models/football-single-event-widget.model';
import BlockModel from '../../../../models/block.model';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import { MatchesContainer } from '../../../partials/matches/matches-container';
import { OddsEditComponent } from '../../odds/components/odds-edit.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import FansUnitedEnabledComponent from '../../../partials/fans-united/fans-united-enabled.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { MatchStatuses } from '../../../../../../../constants/event.types';
import { DisplayOddsCheckbox } from '../../odds/components/display-odds-checkbox.component';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: any;
};

type State = {
	isValid: boolean;
	widgetModel: FootballSingleEventWidgetModel;
	oddsModel: OddsModelV2;
};

export default class FootballSingleEventEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballSingleEvent,
				props.block.data.preview && props.block.data.preview.match,
				props.contentData,
			),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsModelV2.builder(props.block.data.preview.odds).build()
					: OddsModelV2.builder().withMainBookmaker(null).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds &&
			this.state.widgetModel.teams &&
			this.state.widgetModel.teams.length < 1
		) {
			const emptyOddsModel = OddsModelV2.builder().build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsModelV2.builder(this.state.oddsModel)
			.withMainBookmaker(bookmakerSelectionEnabled ? this.state.oddsModel.mainBookmaker : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { match: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: FootballSingleEventWidgetModel) =>
		this.setState({
			...this.state,
			widgetModel: model,
		});

	onTeamChange = (teams: TeamModel[]) => {
		this.updateWidgetModelState(
			FootballSingleEventWidgetModel.builder(this.state.widgetModel)
				.withTeams(teams)
				.withMatch(new MatchModel())
				.withDisplayOdds(false)
				.build(),
		);
	};

	onMatchChange = (match: MatchModel) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();
		const displayOdds = match && match.type !== MatchStatuses.FINISHED;

		const widgetModel = FootballSingleEventWidgetModel.builder(this.state.widgetModel)
			.withMatch(match)
			.withDisplayOdds(isAutoCheckEnabled && displayOdds)
			.build();
		const oddsModel = OddsModelV2.builder().withMainBookmaker(null).build();

		this.setState({
			...this.state,
			widgetModel,
			oddsModel,
		});
	};

	onDisplayOddsChange = (event: any) => {
		this.updateWidgetModelState(FootballSingleEventWidgetModel.builder(this.state.widgetModel).withDisplayOdds(event.target.checked).build());
	};

	onFansUnitedEnabledChange = (event: any) => {
		this.updateWidgetModelState(
			FootballSingleEventWidgetModel.builder(this.state.widgetModel)
				.withFansUnitedEnabled(event.target.checked)
				.withFansUnitedExpanded(event.target.checked)
				.build(),
		);
	};

	onFansUnitedExpandedChange = (event: any) => {
		this.updateWidgetModelState(
			FootballSingleEventWidgetModel.builder(this.state.widgetModel).withFansUnitedExpanded(event.target.checked).build(),
		);
	};

	onOddsWidgetChange = (oddsModel: OddsModelV2) => this.setState({ ...this.state, oddsModel });

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(
			refreshTimeData,
			FootballSingleEventWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { isValid, widgetModel, oddsModel } = this.state;
		const { t, block } = this.props;

		return (
			<>
				<div data-qa='football-single-event-edit'>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={true}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.teams ? widgetModel.teams : []}
									onTeamSelect={(team: TeamModel[]) => this.onTeamChange(team)}
									t={this.props.t}
									isClearable
									isRequired
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<MatchesContainer
								t={t}
								teamIds={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? [widgetModel.teams.filter((team) => team).map((team: any) => team.id)]
										: []
								}
								isMulti={false}
								maxNotStartedMatches={20}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? widgetModel.teams.filter((team) => team).map((team: TeamModel) => team.name)
										: ['']
								}
								selectedMatchId={widgetModel && widgetModel.match ? widgetModel.match.id : null}
								onSelect={this.onMatchChange}
							/>
						</Col>
					</Row>
					<div className='mb-3 mt-2'>
						<ErrorHandler
							arePropsValid={isValid}
							isValueSelected={widgetModel.match && !widgetModel.match.id}
							t={t}
							errorMessage='no_matches_selected'
						/>
					</div>
					<hr />
					{widgetModel && widgetModel.match && widgetModel.match.type !== MatchStatuses.FINISHED && (
						<DisplayOddsCheckbox
							blockId={this.props.block.id}
							onDisplayOddsChange={this.onDisplayOddsChange}
							isChecked={widgetModel.displayOdds}
							sport={SportTypes.FOOTBALL}
						/>
					)}
					{widgetModel && widgetModel.match && widgetModel.match.type !== MatchStatuses.FINISHED && widgetModel.displayOdds && (
						<OddsEditComponent
							widgetType={WidgetsV2Ids.widgetFootballSingleEvent}
							block={this.props.block}
							isStandAlone={false}
							t={t}
							match={widgetModel && widgetModel.match ? widgetModel.match : oddsModel.match}
							onOddsWidgetChange={this.onOddsWidgetChange}
							displayOddsLabel={true}
							isValid={this.state.isValid}
							currentOdds={oddsModel}
						/>
					)}
					{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
						<FansUnitedEnabledComponent
							fansUnitedEnabledChecked={widgetModel.fansUnitedEnabled}
							fansUnitedExpandedChecked={widgetModel.fansUnitedExpanded}
							blockId={block.id}
							t={t}
							onFansUnitedEnabledCheck={this.onFansUnitedEnabledChange}
							onFansUnitedExpandCheck={this.onFansUnitedExpandedChange}
							sport={SportTypes.FOOTBALL}
						/>
					)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
