import { ArticleAutoTaggingScope, ArticleJson } from './ArticleJson';
import Article from './Article';
import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import { ArticleRequestJson } from './ArticleRequestJson';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import { filterEmptyBlocks, removeTagsFromParagraphBlocks } from '../helpers/content-helpers';
import TranslationGroupModel from '../translation-groups/translation-group-model';
import { DynamicContentProperty } from '../../constants/content-types';
import { VersionModel } from '../v2/meta-data-versions/versions.model';

export default class ArticleBuilder {
	private json: ArticleJson;

	constructor(article?: Article | ArticleJson) {
		if (article && article instanceof Article) {
			this.json = article.toJSON();
		} else if (article) {
			this.json = article;
		} else {
			this.json = {} as ArticleJson;
		}
	}

	withId(id: string): ArticleBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): ArticleBuilder {
		this.json.title = title;

		return this;
	}

	withSubtitle(subtitle: string): ArticleBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withStrapline(strapline: string): ArticleBuilder {
		this.json.strapline = strapline;

		return this;
	}

	withType(type: string): ArticleBuilder {
		this.json.type = type;

		return this;
	}

	withBody(body: BlockModel[]): ArticleBuilder {
		this.json.body = body;

		return this;
	}

	withPublishedAt(publishedAt: string): ArticleBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): ArticleBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withPublishedUntil(publishedUntil: string): ArticleBuilder {
		this.json.publishedUntil = publishedUntil;

		return this;
	}

	withStatus(status: string): ArticleBuilder {
		this.json.status = status;

		return this;
	}

	withComments(comments: any): ArticleBuilder {
		this.json.comments = comments;

		return this;
	}

	withViewsCount(viewsCount: any): ArticleBuilder {
		this.json.viewsCount = viewsCount;

		return this;
	}

	withTranslationGroup(translationGroup: TranslationGroupModel): ArticleBuilder {
		this.json.translationGroup = translationGroup;

		return this;
	}

	withCommentsCount(commentsCount: any): ArticleBuilder {
		this.json.commentsCount = commentsCount;

		return this;
	}

	withCustomAuthor(customAuthor: string): ArticleBuilder {
		this.json.customAuthor = customAuthor;

		return this;
	}

	withLanguage(language: string): ArticleBuilder {
		this.json.language = language;

		return this;
	}

	withAuthors(authors: Author[]): ArticleBuilder {
		this.json.authors = authors;

		return this;
	}

	withImage(image: any): ArticleBuilder {
		this.json.image = image;

		return this;
	}

	withGeneric(generic: any): ArticleBuilder {
		this.json.generic = generic;

		return this;
	}

	withContentFooter(footer: string): ArticleBuilder {
		this.json.footer = footer;

		return this;
	}

	withUrls(urls: Urls): ArticleBuilder {
		this.json.urls = urls;

		return this;
	}

	withSeo(seo: Seo): ArticleBuilder {
		this.json.seo = seo;

		return this;
	}

	withCategory(category: Category): ArticleBuilder {
		this.json.category = category;

		return this;
	}

	withAdditionalCategories(additionalCategories: Category[]): ArticleBuilder {
		this.json.additionalCategories = additionalCategories;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): ArticleBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	withCreatedBy(createdBy: any[]): ArticleBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withCreatedAt(createdAt: string): ArticleBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withDistributionRegions(distributionRegions: ContentAttributes[]): ArticleBuilder {
		this.json.distributionRegions = distributionRegions;

		return this;
	}

	withDistributionChannels(distributionChannels: ContentAttributes[]): ArticleBuilder {
		this.json.distributionChannels = distributionChannels;

		return this;
	}

	withOrigin(origin: any): ArticleBuilder {
		this.json.origin = origin;

		return this;
	}

	extractDefaultContent = (content: ContentAttributes[]): ContentAttributes => {
		const defaultType = content.find((type: ContentAttributes) => type.isDefault);

		return defaultType as ContentAttributes;
	};

	// this is used to set dynamic 'properties' placed in general content tab
	withDynamicProperties(dynamicProperties: DynamicContentProperty[]): ArticleBuilder {
		this.json.properties = dynamicProperties.reduce((obj, item) => Object.assign(obj, { [item.slug]: item.value }), {});
		return this;
	}

	withAutoTaggingScope(autoTaggingScope: ArticleAutoTaggingScope): ArticleBuilder {
		this.json.autoTaggingScope = autoTaggingScope || {};

		return this;
	}

	withVersions(versions: VersionModel[]): ArticleBuilder {
		this.json.versions = versions;

		return this;
	}

	toRequestJson(): ArticleRequestJson {
		const authorsForRequest = this.json.authors
			? this.json.authors.filter((author: any) => author && author.id).map((author: Author) => author.id)
			: [];

		const metaDataVersionsForRequest = this.json.versions
			? this.json.versions.filter((version: VersionModel) => version && version.id).map((version: VersionModel) => version)
			: [];

		let json = {} as ArticleRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			jsonld: '',
			automatic_seo_title: false,
			automatic_slug: false,
		};

		json.id = this.json.id;
		json.title = this.json.title;
		json.subtitle = this.json.subtitle;
		json.strapline = this.json.strapline;
		json.body = removeTagsFromParagraphBlocks(filterEmptyBlocks(this.json.body));
		json.category_id = this.json && this.json.category ? this.json.category.id : '';
		json.translation_group_id = this.json && this.json.translationGroup && this.json.translationGroup.id ? this.json.translationGroup.id : '';
		json.published_at = this.json.publishedAt;
		json.created_at = this.json.createdAt;
		json.published_until = this.json.publishedUntil;
		json.status = this.json.status || '';
		json.type = this.json.type || '';
		json.allow_comments = true;
		json.comment_collection_id = '';
		json.authors = authorsForRequest;
		json.custom_author = this.json.customAuthor;
		json.language = this.json.language ? this.json.language : '';
		json.image_id = '';
		json.image_description = '';
		json.additional_categories = this.json.additionalCategories
			? this.json.additionalCategories.map((category: Category) => category.id)
			: [];
		json.generic = this.json.generic;
		json.footer = this.json.footer;
		json.published_regions =
			this.json.distributionRegions && this.json.distributionRegions.length > 0
				? this.json.distributionRegions.map((region: ContentAttributes) => region.id)
				: [];
		json.published_channels =
			this.json.distributionChannels && this.json.distributionChannels.length > 0
				? this.json.distributionChannels.map((channel: ContentAttributes) => channel.id)
				: [];
		json.origin_id = this.json.origin && this.json.origin.id ? this.json.origin.id : '';
		json.external_url = this.json.urls && this.json.urls.externalUrl ? this.json.urls.externalUrl : '';
		json.canonical_url = this.json.urls && this.json.urls.canonicalUrl ? this.json.urls.canonicalUrl : '';
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.seo.jsonld = this.json.seo && this.json.seo.jsonld ? this.json.seo.jsonld : '';
		json.seo.automatic_seo_title = this.json.seo && this.json.seo.autoTitle ? this.json.seo.autoTitle : false;
		json.seo.automatic_slug = this.json.seo && this.json.seo.autoSlug ? this.json.seo.autoSlug : false;
		json.comment_policy_id =
			this.json.comments && this.json.comments.policy && this.json.comments.policy.id ? this.json.comments.policy.id : '';
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];
		json.versions = metaDataVersionsForRequest;

		if (this.json.autoTaggingScope && Object.keys(this.json.autoTaggingScope).length > 0) {
			json.entity_scope = this.json.autoTaggingScope;
		}

		// this will extract 'properties' object and will populate the 'json' object with its dynamic entities
		Object.keys(this.json.properties).forEach((propertyKey: string) => (json[propertyKey] = this.json.properties[propertyKey]));

		return json;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isTitleSet = this.json.title && this.json.title.length > 0;
			const isStraplineSet = this.json.strapline && this.json.strapline.length > 0;
			const isSubtitleSet = this.json.subtitle && this.json.subtitle.length > 0;
			const isBodySet =
				this.json.body &&
				((this.json.body.length === 1 &&
					this.json.body[0].data.content &&
					this.json.body[0].data.content.replace(/(<([^>]+)>)/gi, '') !== '') ||
					this.json.body.length > 1);
			const isCategorySet = this.json.category;
			const isAdditionalCategoriesSet = this.json.additionalCategories && this.json.additionalCategories.length > 0;
			const isPublishedAtSet = this.json.publishedAt && this.json.publishedAt.length > 0;
			const isPublishedUntilSet = this.json.publishedUntil && this.json.publishedUntil.length > 0;
			const isCustomAuthorSet = this.json.customAuthor && this.json.customAuthor.length > 0;
			const isSeoSet = !Seo.builder(this.json.seo).isEmpty();
			const isUrlsSet = !Urls.builder(this.json.urls).isEmpty();
			const isMainMediaSet = this.json.mainMedia && this.json.mainMedia.length > 0;
			const isVersionsSet = this.json.versions && this.json.versions.length > 0;

			return !(
				isTitleSet ||
				isStraplineSet ||
				isSubtitleSet ||
				isBodySet ||
				isPublishedAtSet ||
				isPublishedUntilSet ||
				isCustomAuthorSet ||
				isAdditionalCategoriesSet ||
				isSeoSet ||
				isUrlsSet ||
				isMainMediaSet ||
				isCategorySet ||
				isVersionsSet
			);
		}

		return true;
	}

	build(): Article {
		return Article.fromJSON(this.json);
	}
}
