import ListModel from '../../../../../models/v2/list/list';
import ListItemModel from '../../../../../models/v2/list/list-item';

export const areNumberOfItemsValid = (selectedList: ListModel) => {
	const listItemsLength = selectedList.items.length || 0;
	const minItemsFromConfiguration = (selectedList.configuration && selectedList.configuration.minItems) || null;
	const maxItemsFromConfiguration = (selectedList.configuration && selectedList.configuration.maxItems) || null;

	if (minItemsFromConfiguration && maxItemsFromConfiguration) {
		return listItemsLength >= +minItemsFromConfiguration && listItemsLength <= +maxItemsFromConfiguration;
	}

	return false;
};

export const areLockPositionsValid = (list: ListModel) => {
	if (list && list.items && list.lockedPositions) {
		const listItems = Array.isArray(list.items) ? list.items : [];
		return (listItems || []).findIndex((listItem, index) => listItem.lockedPosition !== null && listItem.lockedPosition !== index) < 0;
	}
	return true;
};

export const lockPositionsAlert = (list: ListModel) => {
	if (list && list.items) {
		return (
			'- ' +
			list.items
				.filter((item: ListItemModel) => item.lockedPosition !== null)
				.map((item: ListItemModel) => `[${item.lockedPosition! + 1}] ${item.data.title}`)
				.join('; ')
		);
	}
	return '';
};
