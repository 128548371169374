// this will be a component that will be used to load the feature service related information
// in the App component we don't have loaded data for feature service or action service
import React, { useEffect } from 'react';
import { addWidgetsV2Scripts } from '../views/Partials/Blocky/v2/widgets-v2/helpers/helpers';

type Properties = {};

export const ScriptsInjector: React.FunctionComponent<Properties> = () => {
	useEffect(() => {
		addWidgetsV2Scripts();
	}, []);

	return null;
};
