import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ImageModel {
	@jsonMember(String)
	public main: string | null = null;
	@jsonMember(String)
	public mobile: string | null = null;
	@jsonMember(String)
	public cover: string | null = null;
}

export class OptionModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(ImageModel)
	public images: ImageModel = new ImageModel();
	@jsonMember(Number)
	public votes: number | null = null;
}

@jsonObject
export default class FansUnitedPollModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(String)
	public status: string | null = null;
	@jsonMember(Object)
	public labels: any = {};
	@jsonMember(ImageModel)
	public images: ImageModel = new ImageModel();
	@jsonArrayMember(String)
	public flags: string[] | null = null;
	@jsonArrayMember(OptionModel)
	public options: OptionModel[] | null = null;
	@jsonMember(Number)
	public total_votes: number = 0;
	@jsonMember(Object)
	public custom_fields: any = null;
	@jsonMember(Object)
	public branding: any = null;
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(String)
	public embed_code: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
