import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { widgetsBackgrounds } from '../../../../../../../constants/resource-constants';
import { BugWidget, FansUnitedWidgetsIds, WidgetsIds, WidgetsV2Ids } from '../../../../constants/block.types';
import { extractBlockIcons, isCustomBlock } from '../../../../subcomponents/helpers/block-config.helper';
import { checkBlockIsSetAndEnabled } from '../add-block/hepers/block-list-category.helper';
import { Tooltip } from 'reactstrap';

type Properties = {
	blockIndex: number;
	children: any;
	onPreview: () => void;
	onEdit: () => void;
	onRemove: () => void;
	t: any;
	name: string;
	dragHandler: any;
	id: string;
	changeBlocksToCopy: (value: boolean) => void;
	selected: boolean;
	blocksLength: number;
	singleBlock: boolean;
	onCopyEmbedCode: () => void;
	customBlockIcon?: string;
	customBlockName?: string;
	widgetId?: string;
};

export const NormalView = ({
	children,
	onPreview,
	onEdit,
	onRemove,
	t,
	name,
	dragHandler,
	changeBlocksToCopy,
	id,
	selected,
	blocksLength,
	singleBlock,
	onCopyEmbedCode,
	blockIndex,
	customBlockIcon,
	customBlockName,
	widgetId,
}: Properties) => {
	const [tooltipState, toggle] = useState({ id: '', isOpen: false });
	const style = widgetsBackgrounds[name] ? { backgroundImage: `url(${widgetsBackgrounds[name]})`, backgroundPosition: 'center' } : {};
	const toggleCopyBlock = (e: any) => changeBlocksToCopy(e.target.checked);
	const toggleTooltip = (id: string) =>
		toggle((tooltipState: any) => {
			return { id, isOpen: !tooltipState.isOpen };
		});

	return (
		<div className='block bg-light mb-2 rounded border' style={style}>
			<h6 className='p-1 d-flex align-items-center'>
				{singleBlock || name === BugWidget.arrayBug ? null : (
					<>
						{dragHandler}
						<input className='mr-1' id={`copy-block-${name}-${blockIndex}`} onChange={toggleCopyBlock} checked={selected} type='checkbox' />
					</>
				)}
				<div className='font-weight-bold p-2'>
					{isCustomBlock(name) ? (
						<i className={`mr-1 ${customBlockIcon}`} />
					) : (
						<i className={`mr-1 ${extractBlockIcons(name)[0] && extractBlockIcons(name)[0].icon}`} />
					)}
					<span data-qa={widgetId} className='blocky-title'>
						{isCustomBlock(name) ? customBlockName : t(name)}
					</span>
				</div>
			</h6>
			<div className='block-actions d-none'>
				<div className='btn-group btn-group-sm' role='group' aria-label='Basic example'>
					{name && (WidgetsV2Ids[name] || WidgetsIds[name] || FansUnitedWidgetsIds[name]) && (
						<>
							<Button
								id={`normal-view-copy-block-embedcode-btn-${blockIndex}`}
								disabled={name === BugWidget.arrayBug}
								color='success'
								onClick={onCopyEmbedCode}
							>
								{t('copy_embed_code')}
							</Button>
							{!isCustomBlock(name) && !FansUnitedWidgetsIds[name] && (
								<Button id={`normal-view-preview-block-btn-${blockIndex}`} disabled={name === BugWidget.arrayBug} color='info' onClick={onPreview}>
									<i className='fa fa-eye' /> {t('preview')}
								</Button>
							)}
						</>
					)}
					{!isCustomBlock(name) && (
						<Button
							className='p-0'
							id={`normal-view-edit-block-btn-${blockIndex}`}
							disabled={name === BugWidget.arrayBug || !checkBlockIsSetAndEnabled(name)}
							color='warning'
							onClick={onEdit}
						>
							<span className='p-2' id={`tooltip-edit-button-${id}`}>
								<i className='fa fa-pencil' /> {t('edit')}
							</span>
						</Button>
					)}

					{!checkBlockIsSetAndEnabled(name) && (
						<Tooltip placement='bottom' isOpen={tooltipState.isOpen} target={`tooltip-edit-button-${id}`} toggle={() => toggleTooltip(id)}>
							{t('edit_block_permissions')}
						</Tooltip>
					)}
					{blocksLength > 1 && (
						<Button id={`normal-view-remove-block-${blockIndex}`} color='danger' onClick={onRemove}>
							<i className='fa fa-trash'></i> {t('remove')}
						</Button>
					)}
				</div>
			</div>
			<Row>
				<Col>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>{children}</Col>
			</Row>
		</div>
	);
};
