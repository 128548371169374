import { AuthorJson } from './AuthorJson';
import Author from './Author';
import { AuthorRequestJson } from './AuthorRequestJson';
import Seo from '../seo/Seo';
import AuthorSocialModel from '../v2/author/social.model';

export default class AuthorBuilder {
	private json: AuthorJson;

	constructor(author?: Author | AuthorJson) {
		if (author && author instanceof Author) {
			this.json = author.toJSON();
		} else if (author) {
			this.json = author;
		} else {
			this.json = {} as AuthorJson;
		}
	}

	withId(id: string): AuthorBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): AuthorBuilder {
		this.json.name = name;

		return this;
	}

	withBio(bio: string): AuthorBuilder {
		this.json.bio = bio;

		return this;
	}

	withBioHtml(bio_html: string): AuthorBuilder {
		this.json.bio_html = bio_html;

		return this;
	}

	withActive(active: boolean): AuthorBuilder {
		this.json.active = active;

		return this;
	}

	withIsDefault(isDefault: boolean): AuthorBuilder {
		this.json.isDefault = isDefault;

		return this;
	}

	withAvatarImage(avatarImage: string): AuthorBuilder {
		this.json.avatarImage = avatarImage;

		return this;
	}

	withGeneric(generic: string): AuthorBuilder {
		this.json.generic = generic;

		return this;
	}

	withCreatedAt(createdAt: string): AuthorBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): AuthorBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withSeo(seo: Seo): AuthorBuilder {
		this.json.seo = seo;

		return this;
	}

	withSocialMediaLinks(social_media_links: AuthorSocialModel[]): AuthorBuilder {
		this.json.social_media_links = social_media_links;

		return this;
	}

	build(): Author {
		return Author.fromJSON(this.json);
	}

	equals(author: Author): boolean {
		const idEqual = this.json.id === author.id;
		const nameEqual = this.json.name === author.name;
		const bioEqual = this.json.bio === author.bio;
		const bioHtmlEqual = this.json.bio_html === author.bio_html;
		const activeEqual = this.json.active === author.active;
		const isDefaultEqual = this.json.isDefault === author.isDefault;
		const avatarImageEqual = this.json.avatarImage === author.avatarImage;
		const createdAtEqual = this.json.createdAt === author.createdAt;
		const updatedAttEqual = this.json.updatedAt === author.updatedAt;

		return (
			idEqual &&
			nameEqual &&
			bioEqual &&
			bioHtmlEqual &&
			isDefaultEqual &&
			activeEqual &&
			avatarImageEqual &&
			createdAtEqual &&
			updatedAttEqual
		);
	}

	toRequestJson(): AuthorRequestJson {
		let json = {} as AuthorRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			jsonld: '',
			automatic_seo_title: false,
			automatic_slug: false,
		};

		json.id = this.json.id;
		json.name = this.json.name;
		json.bio = this.json.bio;
		json.bio_html = this.json.bio_html;
		json.avatar_url = this.json.avatarImage;
		json.active = this.json.active;
		json.default = this.json.isDefault;
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.seo.jsonld = this.json.seo && this.json.seo.jsonld ? this.json.seo.jsonld : '';
		json.seo.automatic_seo_title = this.json.seo && this.json.seo.autoTitle ? this.json.seo.autoTitle : false;
		json.seo.automatic_slug = this.json.seo && this.json.seo.autoSlug ? this.json.seo.autoSlug : false;
		json.social_media_links = this.json.social_media_links;

		return json;
	}
}
