import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { useTranslation } from 'react-i18next';

type Properties = {
	isChecked: boolean;
	onDisplayOddsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	blockId: string;
	sport: string;
};

export const DisplayOddsCheckbox: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();

	return (
		<Row className='mb-2'>
			<Col>
				<FormGroup key={`display-odds-markets`} check className='radio'>
					<Input
						key={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
						className='form-check-input'
						type='checkbox'
						id={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${props.blockId}`}
						name='radios'
						onChange={props.onDisplayOddsChange}
						checked={props.isChecked || false}
						data-qa={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
					/>
					<Label
						check
						htmlFor={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${props.blockId}`}
						key='display-odds-markets-label'
						className='form-check-label'
						for={`${props.sport}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${props.blockId}`}
					>
						{t('display_odds')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};
