import React, { ChangeEvent, FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';

interface Properties {
	value: boolean;
	onDisplayHeaderChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const HeaderDisplayInput: FunctionComponent<Properties> = ({ value, onDisplayHeaderChange }) => {
	const { t } = useTranslation();
	const id = useRef('widget-header-display-select-' + Math.random().toString(36).substring(7));

	return (
		<FormGroup data-qa='header-display-select' check className='radio'>
			<Input
				className='form-check-input'
				type='checkbox'
				id={id.current}
				name='radios'
				onChange={onDisplayHeaderChange}
				checked={value ? value : false}
			/>
			<Label htmlFor={id.current} check className='form-check-label'>
				{t('header_display')}
			</Label>
		</FormGroup>
	);
};

export default HeaderDisplayInput;
