import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import TeamProgrammeWidgetModel from './team-programme-widget.model';
import { TeamProgrammeWidgetJson } from './team-programme-widget.json';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

export default class TeamProgrammeWidgetBuilder {
	readonly json: TeamProgrammeWidgetJson;

	constructor(model?: TeamProgrammeWidgetModel | TeamProgrammeWidgetJson) {
		if (model && model instanceof TeamProgrammeWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as TeamProgrammeWidgetJson;
		}
	}

	withTournament = (tournament: TournamentModel): TeamProgrammeWidgetBuilder => {
		this.json.tournament = tournament;

		return this;
	};

	withSeason = (season: SeasonModel): TeamProgrammeWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: StageModel): TeamProgrammeWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withRound = (rounds: RoundModel[]): TeamProgrammeWidgetBuilder => {
		this.json.rounds = rounds;

		return this;
	};

	withHeaderDefaultOption(headerDefaultOption: string | null): TeamProgrammeWidgetBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withTeam = (team: TeamModel): TeamProgrammeWidgetBuilder => {
		this.json.team = team;

		return this;
	};

	withLimit = (limit: string): TeamProgrammeWidgetBuilder => {
		this.json.limit = limit;

		return this;
	}

	withSortDirectionResults = (sortDirectionResults: string): TeamProgrammeWidgetBuilder => {
		this.json.sortDirectionResults = sortDirectionResults;

		return this;
	};


	withDataDateFrom = (date: string): TeamProgrammeWidgetBuilder => {
		this.json.dataDateFrom = date;

		return this;
	};

	withDataDateTo = (date: string): TeamProgrammeWidgetBuilder => {
		this.json.dataDateTo = date;

		return this;
	};

	withSortDirectionFixtures = (sortDirectionFixtures: string): TeamProgrammeWidgetBuilder => {
		this.json.sortDirectionFixtures = sortDirectionFixtures;

		return this;
	};

	withMatchType = (matchType: string): TeamProgrammeWidgetBuilder => {
		this.json.matchType = matchType;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): TeamProgrammeWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): TeamProgrammeWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TeamProgrammeWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): TeamProgrammeWidgetBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): TeamProgrammeWidgetModel {
		return TeamProgrammeWidgetModel.fromJson(this.json);
	}
}
