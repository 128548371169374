import i18next from 'i18next';
import { GenderEnum } from '../views/Pages/Tournaments/constants/Contstants';

export enum TennisGameTypeEnum {
	SINGLE = 'SINGLE',
	DOUBLE = 'DOUBLE',
	TEAM = 'TEAM',
}

export const genderOptions = [
	{ label: i18next.t('male'), value: GenderEnum.MALE },
	{ label: i18next.t('female'), value: GenderEnum.FEMALE },
	{ label: i18next.t('mixed'), value: GenderEnum.MIXED },
];

export const tennisGameTypeOptions = [
	{ label: i18next.t('single'), value: TennisGameTypeEnum.SINGLE },
	{ label: i18next.t('double'), value: TennisGameTypeEnum.DOUBLE },
	{ label: i18next.t('team'), value: TennisGameTypeEnum.TEAM },
];
