import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import PlayerSeasonSelectComponent from '../../../partials/player-select/player-season-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { ContentTypes } from '../../../../../../../constants/content-types';
import FootballPlayerH2HWidgetModel from '../models/football-player-h2h.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import PlayerSelectComponent from '../../../partials/player-select/player-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { playerH2HStatsOptions } from '../../football-team/constants/football-team.constants';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import HeaderDisplayInput from '../../../partials/header-display-input/header-display-input.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballPlayerH2HWidgetModel;
	isValid: boolean;
};

export default class FootballPlayerH2HEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballPlayerH2H,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballPlayerH2HWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onPlayer1Change = (player: PlayerModel) => {
		this.updateWidgetModelState(
			FootballPlayerH2HWidgetModel.builder(this.state.widgetModel)
				.withPlayerOne(player)
				.withPlayerOneSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onPlayer2Change = (player: PlayerModel) => {
		this.updateWidgetModelState(
			FootballPlayerH2HWidgetModel.builder(this.state.widgetModel)
				.withPlayerTwo(player)
				.withPlayerTwoSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onPlayer1SeasonsChange = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(FootballPlayerH2HWidgetModel.builder(this.state.widgetModel).withPlayerOneSeason(season).build());
	};

	onPlayer2SeasonsChange = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(FootballPlayerH2HWidgetModel.builder(this.state.widgetModel).withPlayerTwoSeason(season).build());
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballPlayerH2HWidgetModel.builder(this.state.widgetModel).withStats(statsParameters).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballPlayerH2HWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(FootballPlayerH2HWidgetModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build());
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		return (
			<>
				<div data-qa='football-player-h2h-block-edit'>
					<Row>
						<Col>
							<FormGroup>
								<PlayerSelectComponent
									isBlocky
									isRequired={true}
									isClearable
									isValid={isValid}
									selectedPlayer={widgetModel.player1}
									onPlayerSelect={this.onPlayer1Change}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}-1`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.player1 && widgetModel.player1.id && widgetModel.player1.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<PlayerSeasonSelectComponent
										isClearable
										isRequired={true}
										isBlocky
										entityId={widgetModel.player1.id}
										selectedSeason={widgetModel.player1TournamentSeason}
										onEntitySeasonSelect={this.onPlayer1SeasonsChange}
										isValid={isValid}
										t={t}
										entityType={ContentTypes.PLAYER}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}-1`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					<hr />
					<Row>
						<Col>
							<FormGroup>
								<PlayerSelectComponent
									isBlocky
									isRequired={true}
									isClearable
									isValid={isValid}
									selectedPlayer={widgetModel.player2}
									onPlayerSelect={this.onPlayer2Change}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}-1`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.player2 && widgetModel.player2.id && widgetModel.player2.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<PlayerSeasonSelectComponent
										isClearable
										isRequired={true}
										isBlocky
										entityId={widgetModel.player2.id}
										selectedSeason={widgetModel.player2TournamentSeason}
										onEntitySeasonSelect={this.onPlayer2SeasonsChange}
										isValid={isValid}
										t={t}
										entityType={ContentTypes.PLAYER}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}-2`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.player1TournamentSeason &&
						widgetModel.player2TournamentSeason &&
						widgetModel.player1TournamentSeason.seasonId !== '' &&
						widgetModel.player2TournamentSeason.seasonId !== '' && (
							<Row className='mt-2 mb-2' data-qa='football-player-h2h-elements-select'>
								<Col>
									<FormGroup>
										<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
										<ElementsSelect
											value={widgetModel.stats}
											onSelect={this.onStatsParametersChange}
											t={t}
											isMulti
											elements={playerH2HStatsOptions}
										/>
									</FormGroup>
								</Col>
							</Row>
						)}
					<Row className='mb-4'>
						<Col>
							<HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
						</Col>
					</Row>
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
