import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../../helpers/block-validation.helper';
import { Col, FormGroup, Row } from 'reactstrap';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import ErrorHandler from '../../../../../partials/error/error-handler-component';
import BlockModel from '../../../../../models/block.model';
import { FansUnitedContentContainer } from '../../../../../partials/fans-united/fu-managers-content-container';
import { FansUnitedWidgetsTypes } from '../../../../../constants/block.types';
import FansUnitedClassicQuizModel from '../../models/fans-united-classic-quiz.model';

type Properties = {
	block: BlockModel;
};

type State = {
	widgetModel: FansUnitedWidgetModel;
	isValid: boolean;
};

export default class ClassicQuizManagerEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel:
				props.block && props.block.data.widget && props.block.data.widget.classicQuiz && props.block.data.widget.classicQuiz.id
					? FansUnitedWidgetModel.builder(props.block.data.widget).build()
					: FansUnitedWidgetModel.builder().build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widget: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.widget = {
				classicQuiz: this.state.widgetModel.classicQuiz,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onQuizChange = (quiz: FansUnitedClassicQuizModel) => {
		const widgetModel = FansUnitedWidgetModel.builder(this.state.widgetModel).withClassicQuiz(quiz).build();
		this.setState({ ...this.state, isValid: true, widgetModel });
	};

	render() {
		const { widgetModel, isValid } = this.state;

		return (
			<div data-qa='widget-classic-quiz-edit'>
				<Row>
					<Col>
						<FormGroup>
							<FansUnitedContentContainer
								selectedId={widgetModel && widgetModel.classicQuiz && widgetModel.classicQuiz.id ? widgetModel.classicQuiz.id : ''}
								onSelect={this.onQuizChange}
								maxItems={30}
								componentType={FansUnitedWidgetsTypes.classicQuiz}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid &&
					((widgetModel.classicQuiz && !widgetModel.classicQuiz.id) ||
						(!widgetModel.classicQuiz && (
							<div className='mb-3'>
								<ErrorHandler errorMessage='no_quiz_selected' />
							</div>
						)))}
			</div>
		);
	}
}
