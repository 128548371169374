import { toast } from 'react-toastify';
import { extractMainContentTypeBasedOnUrl, isEditScreen } from '../../../../../global-helpers/global.helpers';
import PaginationModel from '../../../../../models/v2/Pagination/PaginationModel';
import ImageModel from '../../../../../models/v2/image/image.model';
import HttpService from '../../../../../services/rest/HttpService';
import { store } from '../../../../../store/store';
import { AlreadyUploadedImagoImages, ImagesRequestData, imagoResource } from '../constants/general';
import {
	generateRequestSearchQuery,
	imageToMainImageModel,
	isWatermarkAvailableForContent,
	remapResponseAsMetaResource,
	remapResponseListImages,
	validateSelectedPage,
} from './general';
import ImagoHttpService from '../../../../../services/rest/imago/ImagoHttpService';
import ImagoImageModel from '../../../../../models/v2/imago-image/imago-image';
import { responseToModelImage } from '../../../../../models/v2/image/image.mapper';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import { onMainImageMediaUpdate } from './redux-updater';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';

export const populateMainMedia3rdPartyAttributes = (mainMedia: MainMediaModel): MainMediaModel => {
	const mainMediaImagePrefillEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MAIN_MEDIA_IMAGE_PREFILL);

	if (mainMediaImagePrefillEnabled) {
		const originalImageCaption = (mainMedia && mainMedia.data && mainMedia.data.generic && mainMedia.data.generic.description) || '';

		return {
			...mainMedia,
			description: originalImageCaption,
		};
	}

	return mainMedia;
};

export const buildCopyRightProperty = (image: ImagoImageModel): string => {
	const keyWord = 'Copyright:';
	const caption = image.description;

	if (caption.indexOf(keyWord) !== -1) {
		return keyWord + caption.split(keyWord).pop();
	} else {
		return `${keyWord} + ${image.source}`;
	}
};

export const searchImagesRequest = (
	projectDomain: string,
	searchedText: string,
	advancedContentSearchInput: string,
	page: number,
): Promise<any> => {
	const headers = { Project: projectDomain };
	const requestPage = validateSelectedPage(page);
	const searchQuery = generateRequestSearchQuery(searchedText, advancedContentSearchInput);
	return HttpService.get(`/v2/images/search?query=${searchQuery}&page=${requestPage}`, null, headers);
};

export const getAllImagesRequest = (projectDomain: string, page: number): Promise<any> => {
	const headers = { Project: projectDomain };
	const requestPage = validateSelectedPage(page);
	return HttpService.get(`/images?page=${requestPage}`, null, headers);
};

export const setStateVariablesAfterRequest = (
	responsePromise: Promise<any>,
	setImages: (value: ImageModel[]) => void,
	setPagination: (value: PaginationModel) => void,
	setIsContentLoading: (value: boolean) => void,
) => {
	return responsePromise
		.then((response: any) => {
			const images = remapResponseListImages(response.data.data);
			const pagination = remapResponseAsMetaResource(response);
			return { images, pagination } as ImagesRequestData;
		})
		.then(({ images, pagination }) => {
			setImages(images);
			setPagination(pagination);
		})
		.catch((error: unknown) => console.error(error))
		.finally(() => setIsContentLoading(false));
};

export const getUploadedImage = (imageId: string, projectDomain: string): Promise<any> => {
	const headers = { Project: projectDomain };
	return HttpService.get(`/images/${imageId}`, null, headers)
		.then((response: any) => {
			const imageResponse = response.data.data;
			const imageRemapped = responseToModelImage(imageResponse);
			const imageRemappedAsMainMedia = imageToMainImageModel(imageRemapped);
			onMainImageMediaUpdate(imageRemappedAsMainMedia);
		})
		.catch((error: unknown) => console.error(error));
};

// Imago requests helper
export const getGenericImagoId = (value: any) => {
	if (isEditScreen()) {
		let dataGenericID =
			value && value[0] && value[0].data && value[0].data.generic && value[0].data.generic.imago_id ? value[0].data.generic.imago_id : '';
		let mainMediaGenericID = value && value[0] && value[0].generic && value[0].generic.imago_id ? value[0].generic.imago_id : '';
		return [dataGenericID, mainMediaGenericID];
	} else return value && value[0] && value[0].generic && value[0].generic.imago_id ? [value[0].generic.imago_id] : [];
};

const getImagoIDinMainMedia = (genericData: any): Array<any> => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	return isWatermarkAvailableForContent(contentTypeFromUrl) ? getGenericImagoId(genericData) : [];
};

const checkIfStateContainsImagoID = (imagoID: string, stateImagoID: string[]) => stateImagoID.includes(imagoID);

const checkIfArrayContainsSameImagoID = (array: object[], imagoID: string) => {
	let imagoImageIsAlreadyUploaded = false;
	array.forEach((imageObject) => {
		if (Object.keys(imageObject).includes(imagoID)) {
			imagoImageIsAlreadyUploaded = true;
		}
	});
	return imagoImageIsAlreadyUploaded;
};

const constructRequestFromFilePath = (image: ImagoImageModel, imagePaths: string[], originId: string | null, headers: any) => {
	return imagePaths.map((path: string) => HttpService.post('/images', imagoToJson(image, path, originId), headers));
};

const buildImagoReferralUrl = (image: ImagoImageModel) => {
	if (image) {
		return `https://www.imago-images.com/${image.type === 'sport' ? 'sp' : 'st'}/${image.id}`;
	}
};

const imagoToJson = (image: ImagoImageModel, imagePath: string, projectOriginId: string | null) => {
	return {
		generic: {
			imago_id: image.id,
			type: image.type,
			width: image.width,
			height: image.height,
			image_url: image.image,
			description: image.description,
			dateCreated: image.dateCreated,
			source: buildCopyRightProperty(image),
			copyright: image.source,
			referral_url: buildImagoReferralUrl(image),
		},
		origin_id: projectOriginId,
		path: imagePath,
		copyright: image.source,
		referral_url: buildImagoReferralUrl(image),
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
};

const findImageForMainMedia = (alreadyUploadedImagoImages: AlreadyUploadedImagoImages, imagoID: string): MainMediaModel | null => {
	const isAlreadyUploaded = Object.keys(alreadyUploadedImagoImages).includes(imagoID);
	return isAlreadyUploaded ? alreadyUploadedImagoImages[imagoID] : null;
};

const addImagoImageIDtoState = (
	alreadyUploadedImagoImages: AlreadyUploadedImagoImages,
	image: ImagoImageModel,
	mainMediaObj: MainMediaModel,
): AlreadyUploadedImagoImages => {
	return [...alreadyUploadedImagoImages, { [image.id]: mainMediaObj }];
};

const handlePostImagoImageError = (err: any, t: any) => {
	if (err.response) {
		if (err.response.status == 415) {
			toast.error(t('imago_upload_fail'));
			toast.info(t('imago_415_info'));
		} else {
			toast.error(t('imago_upload_fail'));
		}
	} else {
		console.error('imago error', err);
	}
};

const postImagoImageOnImageApi = (
	t: any,
	image: ImagoImageModel,
	form: FormData,
	alreadyUploadedImagoImages: AlreadyUploadedImagoImages,
	setAlreadyUploadedImagoImages: (data: AlreadyUploadedImagoImages) => void,
): Promise<any> => {
	const projectDomain: string = store.getState().project.currentProject.domain || '';
	const imageOrigins = (store.getState().origins && store.getState().origins.imageOrigins) || [];
	const imageOrigin = imageOrigins.find((el: Record<string, any>) => el.slug === imagoResource) || null;
	const headers = { Project: projectDomain, 'content-type': 'multipart/form-data' };
	let imagePaths: string[] = [];

	// Post the imago image on the image API
	return HttpService.postImg('/upload', form, headers)
		.then((res: any) => {
			const imagePath = res.data.path;
			imagePaths.push(imagePath);
			// Create the image on the Content API
			if (image && imagePaths && imagePaths.length > 0) {
				const requestFromFilePath = constructRequestFromFilePath(image, imagePaths, imageOrigin.id, { Project: projectDomain });
				HttpService.all(requestFromFilePath).then((res: Array<any>) => {
					//Set image as MainMedia
					const responseData = (res && res[0] && res[0].data && res[0].data.data) || null;
					if (responseData) {
						const imagoImageAsImageModel = responseToModelImage(responseData);
						let mainMedia = imageToMainImageModel(imagoImageAsImageModel);
						mainMedia = populateMainMedia3rdPartyAttributes(mainMedia);
						onMainImageMediaUpdate(mainMedia);
						const addedImagoToAlreadyUploadedArray = addImagoImageIDtoState(alreadyUploadedImagoImages, image, mainMedia);
						setAlreadyUploadedImagoImages(addedImagoToAlreadyUploadedArray);
						toast.success(t('imago_success'));
					}
				});
			}
		})
		.catch((err: any) => handlePostImagoImageError(err, t));
};

export const uploadImagoImage = (
	t: any,
	image: ImagoImageModel,
	genericData: any,
	alreadyUploadedImagoImages: AlreadyUploadedImagoImages,
	setAlreadyUploadedImagoImages: (data: AlreadyUploadedImagoImages) => void,
	setIsImagoCollapseOpen: (data: boolean) => void,
) => {
	const form = new FormData();
	// these comments are for the row below (new ImagoHttpService())
	// not the best practice to create instance every time but the instance requires data from features config
	// and if I export it from external variable there are problems with the request headers
	// Also cannot make the methods static because they are used in old components...
	const imagoHttp = new ImagoHttpService();

	//Download the Imago Image
	let imagoID: string[] = getImagoIDinMainMedia(genericData);
	if (!checkIfStateContainsImagoID(image.id, imagoID) && !checkIfArrayContainsSameImagoID(alreadyUploadedImagoImages, image.id)) {
		imagoHttp
			.downloadImagoImage(image.type, image.id)
			.then(({ data }) => {
				data && data.size && image && image.id && form.append('file', data, `${image.id}.jpeg`);
			})
			.then(() => postImagoImageOnImageApi(t, image, form, alreadyUploadedImagoImages, setAlreadyUploadedImagoImages))
			.then(() => {
				setIsImagoCollapseOpen(false);
			})
			.catch(() => toast.error(t('fetch_image_fail')));
	} else {
		if (checkIfArrayContainsSameImagoID(alreadyUploadedImagoImages, image.id)) {
			const reuseMainMedia = findImageForMainMedia(alreadyUploadedImagoImages, image.id);
			if (reuseMainMedia) {
				onMainImageMediaUpdate(reuseMainMedia);
				toast.success(t('imago_success'));
			}
		} else {
			toast.error(t('image_update_failed'));
		}
	}
};
// Imago requests helper end
