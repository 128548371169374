import React, { FunctionComponent, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { store } from '../../../../../../store/store';
import LiveBlogModel from '../../../models/live-blog.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { sportsConnectionColorStyles } from '../../../../../Partials/Sidebar/tags-refactored/constants/styles';
import debounce from 'lodash.debounce';
import { generateCustomOptionWithImage } from '../../../../../Partials/Sidebar/tags-refactored/helpers/utils';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import '../../../style/live-blog.style.scss';
import LiveBlogTournamentModel from '../../../models/tournaments/live-blog-tournament-model';
import { responseToLiveBlogTournamentModel } from '../../../models/tournaments/live-blog-tournament-response.model';
import { tournamentToOptions } from './live-blog-tournament-select.helper';
import { LiveBlogTypes } from '../../../helpers/utility.helper';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setSelectedTournaments: (tournaments: LiveBlogTournamentModel | null) => void;
};

export const LiveBlogTournamentSelect: FunctionComponent<Properties> = ({ t, liveBlog, setSelectedTournaments }) => {
	const [selectedTournament, setSelectedTournament] = useState<LiveBlogTournamentModel | null>(
		liveBlog.competitions && liveBlog.competitions[0] ? liveBlog.competitions[0] : null,
	);

	const onChangeTournament = (selectedSportOption: ValueType<any>) => {
		liveBlog.teams = [];
		selectedSportOption ? setSelectedTournament(selectedSportOption.data) : setSelectedTournament(null);
		selectedSportOption ? setSelectedTournaments(selectedSportOption.data) : setSelectedTournaments(null);
	};

	const loadTournamentsOnType = (input: string, callback: any) => {
		if (input.length > 2) {
			const language = store.getState().project.currentProject.language;

			HttpService.getFootballData(input, language, relatedConstants.types.tournament).then((response: any) => {
				const fetchedFootballData: Array<any> = response.data;
				const remappedFootballData = fetchedFootballData.map((entity) => responseToLiveBlogTournamentModel(entity));
				const loadedFootballConnectionsAsOptions = tournamentToOptions(remappedFootballData);
				callback(loadedFootballConnectionsAsOptions);
			});
		}
	};

	return (
		<Row className='mb-3 tournament'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_TOURNAMENTS}>
						<strong>{t('tournament')}</strong>
					</Label>
					{liveBlog.type === LiveBlogTypes.MATCH && <span className='text-danger'>*</span>}
					<AsyncSelect
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_TOURNAMENTS}
						styles={sportsConnectionColorStyles}
						isClearable={true}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						loadOptions={debounce(loadTournamentsOnType, 300)}
						onChange={(selection: any) => onChangeTournament(selection)}
						value={selectedTournament && tournamentToOptions([selectedTournament])}
						formatOptionLabel={(option) =>
							generateCustomOptionWithImage(option.label, option.value, option.data.url_logo, relatedConstants.types.tournament)
						}
					/>
					<h6 className='mt-1' style={{ color: 'gray' }}>
						{t('live_blog_tournament_tip')}
					</h6>
				</FormGroup>
			</Col>
		</Row>
	);
};
