import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { WidgetTypesV2 } from '../../constants/block.types';
import TennisPlayoffModel from '../widgets-v2/tennis-playoff/models/tennis-playoff.model';
import TennisRankingModel from '../widgets-v2/tennis-ranking/models/tennis-ranking.model';
import TennisAthleteProgrammeModel from '../widgets-v2/tennis-athlete-programme/models/tennis-athlete-programme.model';
import TennisTournamentProgrammeModel from '../widgets-v2/tennis-tournament-programme/models/tennis-tournament-programme.model';
import OddsModelV2 from '../widgets-v2/odds/models/odds-widget.model';
import StakesModel from '../widgets-v2/stakes/models/stakes-widget.model';

export default class ValidationV2 {
	public validate = (widgetType: string, data: any, subType: string) => {
		switch (widgetType) {
			case WidgetTypesV2.footballStandings:
				return this.footballStandings(data);
			case WidgetTypesV2.footballLivescore:
				return this.footballLivescore(data);
			case WidgetTypesV2.footballTeamProgramme:
				return this.footballTeamProgramme(data);
			case WidgetTypesV2.footballKnockout:
				return this.footballKnockout(data);
			case WidgetTypesV2.footballPlayer:
				return this.footballPlayer(data);
			case WidgetTypesV2.footballSingleEvent:
				return this.footballSingleEvent(data);
			case WidgetTypesV2.footballTeam:
				return this.footballTeam(data);
			case WidgetTypesV2.footballTeamSquad:
				return this.footballTeamSquad(data);
			case WidgetTypesV2.footballTournamentProgramme:
				return this.footballTournamentProgramme(data);
			case WidgetTypesV2.footballPlayerH2H:
				return this.footballPlayerH2H(data);
			case WidgetTypesV2.footballTeamH2H:
				return this.footballTeamH2H(data);
			case WidgetTypesV2.footballTeamH2HMatch:
				return this.widgetFootballTeamH2hMatch(data);
			case WidgetTypesV2.footballMatchesH2H:
				return this.footballMatchesH2H(data);
			case WidgetTypesV2.footballTeamForm:
				return this.footballTeamForm(data);
			case WidgetTypesV2.footballTopScorers:
				return this.footballTopScorers(data);
			case WidgetTypesV2.footballMostDecoratedPlayers:
				return this.footballMostDecoratedPlayers(data);
			case WidgetTypesV2.footballLineups:
				return this.footballLineups(data);
			case WidgetTypesV2.footballMatchCenter:
				return this.footballMatchCenter(data);
			case WidgetTypesV2.basketballSingleEvent:
				return this.widgetBasketballSingleEvent(data);
			case WidgetTypesV2.basketballLivescore:
				return this.widgetBasketballLivescore(data);
			case WidgetTypesV2.basketballStandings:
				return this.widgetBasketballStandings(data);
			case WidgetTypesV2.basketballTournamentProgramme:
				return this.widgetBasketballTournamentProgramme(data);
			case WidgetTypesV2.basketballTeamProgramme:
				return this.widgetBasketballTeamProgramme(data);
			case WidgetTypesV2.tennisSingleEvent:
				return this.widgetTennisSingleEvent(data);
			case WidgetTypesV2.tennisLivescore:
				return this.widgetTennisLivescore(data);
			case WidgetTypesV2.tennisRanking:
				return this.widgetTennisRanking(data.widgetModel);
			case WidgetTypesV2.tennisPlayoff:
				return this.widgetTennisPlayoff(data.widgetModel);
			case WidgetTypesV2.tennisAthleteProgramme:
				return this.widgetTennisAthleteProgramme(data.widgetModel);
			case WidgetTypesV2.tennisTournamentProgramme:
				return this.widgetTennisTournamentProgramme(data.widgetModel);
			case WidgetTypesV2.footballOdds:
				return this.footballOdds(data.widgetModel);
			case WidgetTypesV2.footballStakes:
				return this.footballStakes(data.widgetModel);
			case WidgetTypesV2.footballSingleRound:
				return this.footballSingleRound(data);

			case WidgetTypesV2.iceHockeyLivescore:
				return this.widgetIceHockeyLivescore(data);
			case WidgetTypesV2.iceHockeySingleEvent:
				return this.widgetIceHockeySingleEvent(data);
			case WidgetTypesV2.iceHockeyStandings:
				return this.widgetIceHockeyStandings(data);

			default:
				return false;
		}
	};

	private bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

	private checkIfBookmakersAttributeIsValid = (widgetModel: any) => {
		return widgetModel.displayOdds && this.bookmakerSelectionEnabled && widgetModel.bookmakers && widgetModel.bookmakers.length > 0;
	};

	private checkIfMainBookmakerAttributeIsValid = (widgetModel: any, odds: any) => {
		return widgetModel && widgetModel.displayOdds && this.bookmakerSelectionEnabled && odds.mainBookmaker && odds.mainBookmaker.id;
	};

	private checkIfDisplayOddsIsChecked = (widgetModel: any) => {
		return widgetModel.displayOdds;
	};

	private footballStandings = (widgetModel: any) => {
		return !!(widgetModel && widgetModel.widgetModel.tournament && widgetModel.widgetModel.tournament.id);
	};

	private footballMatchCenter = (data: any) => {
		const { widgetModel } = data;
		const isValidMatch = !!(widgetModel && widgetModel.match && widgetModel.match.id);

		return isValidMatch;
	};

	private footballLivescore = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.date && widgetModel.date !== '');
	};

	private footballTeamProgramme = (data: any) => {
		const { widgetModel } = data;
		const isValidSeason = !!(widgetModel && widgetModel.season && widgetModel.season.id);
		const isValidTeam = !!(widgetModel && widgetModel.team && widgetModel.team.id);

		return isValidSeason && isValidTeam;
	};

	private footballKnockout = (data: any) => {
		const { widgetModel } = data;
		const isValidTournament = !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
		const isValidSeason = !!(widgetModel && widgetModel.season && widgetModel.season.id);
		const isValidStage = !!(widgetModel && widgetModel.stage && widgetModel.stage.id);

		return isValidTournament && isValidSeason && isValidStage;
	};

	private footballSingleEvent = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.match && widgetModel.match.id);
	};

	private footballPlayer = (data: any) => {
		const { widgetModel } = data;

		const isValidPlayer = !!(widgetModel && widgetModel.player && widgetModel.player.id);
		const isValidTournamentSeason = !!(widgetModel && widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId);

		return isValidPlayer && isValidTournamentSeason;
	};

	private footballTeam = (data: any) => {
		const { widgetModel } = data;

		const isValidTeam = !!(widgetModel && widgetModel.team && widgetModel.team.id);
		const isValidSeason = !!(widgetModel && widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId);

		return isValidTeam && isValidSeason;
	};

	private footballTeamSquad = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.team && widgetModel.team.id);
	};

	private footballTournamentProgramme = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
	};

	private footballPlayerH2H = (data: any) => {
		const { widgetModel } = data;

		return !!(
			widgetModel &&
			widgetModel.player1TournamentSeason &&
			widgetModel.player1TournamentSeason.seasonId &&
			widgetModel.player2TournamentSeason &&
			widgetModel.player2TournamentSeason.seasonId
		);
	};

	private footballTeamH2H = (data: any) => {
		const { widgetModel } = data;

		return !!(
			widgetModel &&
			widgetModel.team1TournamentSeason &&
			widgetModel.team1TournamentSeason.seasonId &&
			widgetModel.team2TournamentSeason &&
			widgetModel.team2TournamentSeason.seasonId
		);
	};

	private footballMatchesH2H = (data: any) => {
		const { widgetModel } = data;
		const isValidLimit = !!(widgetModel && widgetModel.limit && widgetModel.limit !== '' && Number(widgetModel.limit) > 0);

		return !!(widgetModel && widgetModel.team1 && widgetModel.team1.id && widgetModel.team2 && widgetModel.team2.id) && isValidLimit;
	};

	private widgetFootballTeamH2hMatch = (data: any) => {
		const { widgetModel } = data;

		return !!(
			widgetModel &&
			widgetModel.team1TournamentSeason &&
			widgetModel.team1TournamentSeason.seasonId &&
			widgetModel.team2TournamentSeason &&
			widgetModel.team2TournamentSeason.seasonId &&
			widgetModel.match &&
			widgetModel.match.id &&
			widgetModel.match.id > 0
		);
	};

	private footballTeamForm = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.team && widgetModel.team.id);
	};

	private footballTopScorers = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
	};

	private footballMostDecoratedPlayers = (data: any) => {
		const { widgetModel } = data;
		const isValidStartFrom =
			widgetModel && widgetModel.startFrom ? +widgetModel.startFrom > 0 && +widgetModel.startFrom <= widgetModel.players.length : true;
		const isValidTournament = widgetModel && widgetModel.tournament && widgetModel.tournament.id;
		const isValidSeason = widgetModel && widgetModel.season && widgetModel.season.id;
		return isValidTournament && isValidSeason && isValidStartFrom;
	};

	private footballLineups = (data: any) => {
		const { widgetModel } = data;

		const isValidMatch = !!(widgetModel && widgetModel.match && widgetModel.match.id);
		const areColorsSelected =
			widgetModel.homeTeamColor &&
			widgetModel.awayTeamColor &&
			true &&
			true &&
			widgetModel.homeTeamColor.length > 0 &&
			widgetModel.awayTeamColor.length > 0;
		const areColorsDifferent = widgetModel.homeTeamColor !== widgetModel.awayTeamColor;
		return isValidMatch && areColorsSelected && areColorsDifferent;
	};

	private footballSingleRound = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
	};

	private widgetBasketballSingleEvent = (data: any) => {
		return !!(data.gameModel && data.gameModel.game && data.gameModel.game.id);
	};

	private widgetBasketballLivescore = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.date && data.widgetModel.date !== '');
	};

	private widgetBasketballStandings = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.competition && data.widgetModel.competition.id);
	};

	private widgetBasketballTournamentProgramme = (data: any) => {
		return !!(
			data.widgetModel &&
			data.widgetModel.competition &&
			data.widgetModel.competition.id !== '' &&
			Object.keys(data.widgetModel.competition).length !== 0
		);
	};

	private widgetBasketballTeamProgramme = (data: any) => {
		return !!(
			data.widgetModel &&
			data.widgetModel.team &&
			data.widgetModel.team.id !== '' &&
			Object.keys(data.widgetModel.team).length !== 0
		);
	};

	private widgetTennisLivescore = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.date && data.widgetModel.date !== '');
	};

	private widgetTennisSingleEvent = (data: any) => {
		return !!(data.matchModel && data.matchModel.match && data.matchModel.match.id);
	};

	private widgetTennisRanking = (widgetModel: TennisRankingModel) => {
		const isValidLimit = !!(widgetModel && widgetModel.limit && widgetModel.limit !== '' && Number(widgetModel.limit) > 0);
		const isValidOffset = !!(widgetModel && widgetModel.offset && widgetModel.offset !== '');
		const isValidStandingType = !!(widgetModel && widgetModel.availableStandingType && widgetModel.availableStandingType.id);

		return isValidLimit && isValidOffset && isValidStandingType;
	};

	private widgetTennisPlayoff = (widgetModel: TennisPlayoffModel) => {
		return !!(widgetModel && widgetModel.competition && widgetModel.competition.id);
	};

	private widgetTennisAthleteProgramme = (widgetModel: TennisAthleteProgrammeModel) => {
		return !!(widgetModel && widgetModel.athlete && widgetModel.athlete.id);
	};

	private widgetTennisTournamentProgramme = (widgetModel: TennisTournamentProgrammeModel) => {
		return !!(widgetModel && widgetModel.competition && widgetModel.competition.id);
	};

	private footballOdds = (widgetModel: OddsModelV2) => {
		return !!(widgetModel.match && widgetModel.match.id);
	};

	private footballStakes = (widgetModel: StakesModel) => {
		return !!(widgetModel.match && widgetModel.match.id && !isNaN(widgetModel.amount) && widgetModel.amount > 0);
	};

	private widgetIceHockeyLivescore = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.date && data.widgetModel.date !== '');
	};

	private widgetIceHockeySingleEvent = (data: any) => {
		return !!(data.gameModel && data.gameModel.game && data.gameModel.game.id);
	};

	private widgetIceHockeyStandings = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.competition && data.widgetModel.competition.id);
	};
}
