import Project from './project/Project';
import Seo from './seo/Seo';
import Urls from './urls/Urls';
import Permission from './permission/Permission';
import Author from './author/Author';
import Banner from './banner/Banner';
import List from './list/List';
import Category from './category/Category';
import Profile from './profile/Profile';
import ArticleBuilder from './article/ArticleBuilder';
import Article from './article/Article';
import PaginationMeta from './pagination/PaginationMeta';
import Video from './video/Video';
import VideoBuilder from './video/VideoBuilder';
import MainMedia from './mainmedia/MainMedia';
import Image from './image/Image';
import ImageUrls from './image/urls/ImageUrls';
import ImageUrlsUploaded from './image/urls/ImageUrlsUploaded/ImageUrlsUploaded';
import EnumItem from './enum/EnumItem';
import Related from './related/Related';
import { RelatedJson } from './related/RelatedJson';
import { relatedConstants } from '../constants/related.constants';
import _ from 'lodash';
import Gallery from './gallery/Gallery';
import GalleryItem from './gallery/item/GalleryItem';
import BannerBuilder from './banner/BannerBuilder';
import ListBuilder from './list/ListBuilder';
import AuthorBuilder from './author/AuthorBuilder';
import Tag from './tag/Tag';
import TagBuilder from './tag/TagBuilder';
import Statistic from './statistics/statistic/statistic';
import StatisticBuilder from './statistics/statistic/statistic.builder';
import Context from './statistics/context/context';
import ContextBuilder from './statistics/context/context.builder';
import Totals from './statistics/totals/totals';
import TotalsBuilder from './statistics/totals/totals.builder';
import Statistics from './statistics/statistics';
import StatisticsBuilder from './statistics/statistics.builder';
import ListConfiguration from './list/configuration/ListConfiguration';
import ListItem from './list/list-item/ListItem';
import ListItemMeta from './list/list-item/list-item-meta/ListItemMeta';
import Poll from './polls/poll';
import PollBuilder from './polls/poll.builder';
import Post from './feed/post/post';
import Source from './feed/sources/source';
import Feed from './feed/feed';
import FeedBuilder from './feed/feed.builder';
import Feature from './feature/Feature';
import * as statisticsHelpers from './helpers/statistics-helpers';
import BackwardCompatibilityService from '../views/Partials/Blocky/services/backward-compatibility.service';
import ContentAttributes from './content-attributes/content-attributes-model';
import Global from '../views/Partials/Blocky/helpers/global.helpers';
import Social from './social/social';
import { PlayerPositions, TeamType } from './player-manual-data/constants/manual-entries.constants';
import PlayerManualDataModel from './player-manual-data/player-manual-data.model';
import TeamManualDataModel from './team-manual-data/team-manual-data.model';
import { remapEntityFromResponse } from '../views/Resources/translations/helpers/translations.helpers';
import { TranslationEntities } from '../views/Resources/translations/helpers/translations.constants';
import AssetsModel from './team-manual-data/assets/assets';
import CoachManualDataModel from './coach-manual-data/coach-manual-data.model';
import TranslationsModel from './translation-groups/translations/translations-model';
import TranslationGroupModel from './translation-groups/translation-group-model';
import ContentLanguagesModel from './content-languages/content-languages-model';
import LanguageCodeModel from './content-languages/language-code/language-code-model';
import { OrderTypes } from '../views/Partials/Fields/tag-order-type/order-type-select.component';
import Wiki from './wiki/Wiki';
import WikiBuilder from './wiki/WikiBuilder';
import { ContentTypes, DynamicContentProperty } from '../constants/content-types';
import { getDynamicProperties } from './ModelMapper-helper';
import { responseToLiveBlogModel } from '../views/Pages/Live Blog/models/live-blog.mapper';
import { slugifyString } from '../views/Partials/Sidebar/seo-refactored/helpers/slug.helper';
import { responseToVersionModel } from './v2/meta-data-versions/versions.mapper';
import { VersionModel } from './v2/meta-data-versions/versions.model';

export default class ModelMapper {
	private static indentSymbol: string = '- ';

	static remapMetaPagination(response: any) {
		return PaginationMeta.builder()
			.withCount(response.count)
			.withCurrentPage(response.current_page)
			.withPerPage(response.per_page)
			.withTotal(response.total)
			.withTotalPages(response.total_pages)
			.withLimit(response.limit)
			.withOffset(response.offset)
			.build();
	}

	static remapRelatedListToRelatedJsonList(relatedForRequest: Related[]): RelatedJson[] {
		return relatedForRequest
			.filter((related: Related) => related !== undefined && related !== null && !_.isEmpty(related))
			.map((el: Related) => {
				// copying the data object reference -> do not remove this code or the preview will broke the tags
				// check https://media-platform.atlassian.net/browse/SFE-3775
				return { provider: el.provider, type: el.type, data: Object.assign({}, el.data) } as Related;
			})
			.map((related: Related) => {
				return related.provider === relatedConstants.providers.smp ? Related.builder(related).withData(related.data.id).build() : related;
			});
	}

	static extractFootballRelatedFromResponse(response: any): Related[] {
		return response
			.filter(
				(item: any) =>
					item.provider === relatedConstants.providers.football &&
					!item.data.sport &&
					(item.type === relatedConstants.types.player ||
						item.type === relatedConstants.types.team ||
						item.type === relatedConstants.types.venue ||
						item.type === relatedConstants.types.tournament ||
						item.type === relatedConstants.types.coach ||
						item.type === relatedConstants.types.season ||
						item.type === relatedConstants.types.president),
			)
			.map((item: any) => {
				return Related.builder().withData(item.data).withProvider(item.provider).withType(item.type).build();
			});
	}

	static extractRelatedContentFromResponse(response: any): Related[] {
		return response
			.filter(
				(item: any) =>
					item.provider === relatedConstants.providers.smp &&
					(item.type === relatedConstants.types.article ||
						item.type === relatedConstants.types.wiki ||
						item.type === relatedConstants.types.video ||
						item.type === relatedConstants.types.gallery),
			)
			.map((item: any) => {
				return Related.builder().withData(item.data).withProvider(item.provider).withType(item.type).build();
			});
	}

	static extractRelatedTypeFromResponse(response: any, type: string, provider: string): Related[] {
		return response
			.filter((item: any) => item.provider === provider && item.type === type)
			.map((item: any) => {
				return Related.builder().withData(item.data).withProvider(item.provider).withType(item.type).build();
			});
	}

	static extractSportsEntitiesRelatedTypeFromResponse(response: any, type: any, provider: string): Related[] {
		return response
			.filter((item: any) => item.provider === provider && type.includes(item.type))
			.map((item: any) => {
				return Related.builder().withData(item.data).withProvider(item.provider).withType(item.type).build();
			});
	}

	static remapArticlesFromResponse(response: any[]): Article[] {
		return response.map((article: any) => this.remapListingArticleFromResponse(article));
	}

	static remapVideosListingFromResponse(response: any[]): Video[] {
		return response.map((video: any) => this.remapListingVideoFromResponse(video));
	}

	static remapGalleriesFromResponse(response: any[]): Gallery[] {
		if (response) {
			return response.map((gallery: any) => this.remapGalleryFromResponse(gallery));
		}

		return [];
	}

	static remapWikisFromResponse(response: any[]): Wiki[] {
		if (response) {
			return response.map((wiki: any) => this.remapWikiFromResponse(wiki));
		}

		return [];
	}

	static remapGalleriesListingFromResponse(response: any[]): Gallery[] {
		if (response) {
			return response.map((gallery: any) => this.remapListingGalleryFromResponse(gallery));
		}

		return [];
	}

	static remapPollsFromResponse(response: any[]): Poll[] {
		if (response) {
			return response.map((poll: any) => this.remapPollFromResponse(poll));
		}

		return [];
	}

	static remapTeamsFromResponse(response: any[]): TeamManualDataModel[] {
		return response.map((team: any) => this.remapTeamFromResponse(team));
	}

	static remapPlayersFromResponse(response: any[]): PlayerManualDataModel[] {
		return response.map((player: any) => this.remapPlayerFromResponse(player));
	}

	static remapCoachesFromResponse(response: any[]): CoachManualDataModel[] {
		return response.map((coach: any) => this.remapCoachFromResponse(coach));
	}

	static remapSquadPlayersFromResponse(response: any[]): PlayerManualDataModel[] {
		return response.map((squad: any) => this.remapPlayerFromResponse(squad.player));
	}

	static remapListingArticleFromResponse(response: any): Article {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.ARTICLE);

		return new ArticleBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withCustomAuthor(response.custom_author)
			.withLanguage(response.language)
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withCreatedBy(response.created_by)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapArticleFromResponse(response: any, dateNow?: string): Article {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.ARTICLE);
		const trandformedBlocks = response.body ? response.body.map((block: any) => new BackwardCompatibilityService().transform(block)) : [];

		return new ArticleBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withBody(trandformedBlocks)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(dateNow ? dateNow : response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withType(response.type)
			.withComments(response.comments)
			.withCustomAuthor(response.custom_author)
			.withLanguage(response.language)
			.withImage(response.image)
			.withGeneric(response.generic)
			.withContentFooter(response.footer)
			.withUrls(ModelMapper.remapUrlsFromResponse(response.urls))
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withAdditionalCategories(ModelMapper.remapContentCategoriesFromResponse(response.additional_categories))
			.withDistributionRegions(ModelMapper.remapResponseToContentAttributesList(response.published_regions))
			.withDistributionChannels(ModelMapper.remapResponseToContentAttributesList(response.published_channels))
			.withOrigin(ModelMapper.remapResponceToContentAttribute(response.origin))
			.withAuthors(ModelMapper.remapAuthorsFromResponse(response.authors))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withCreatedBy(response.created_by)
			.withViewsCount(response.views)
			.withCommentsCount(response.comments)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withDynamicProperties(dynamicProperties)
			.withAutoTaggingScope(response.entity_scope)
			.withVersions((response && response.versions && response.versions.map((version: VersionModel) => responseToVersionModel(version))) || [])
			.build();
	}

	static remapVideoFromResponse(response: any, dateNow?: string): Video {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.VIDEO);
		const trandformedBlocks = response.body ? response.body.map((block: any) => new BackwardCompatibilityService().transform(block)) : [];
		return new VideoBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withBody(trandformedBlocks)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(dateNow ? dateNow : response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withType(response.type)
			.withComments(response.comments)
			.withCustomAuthor(response.custom_author)
			.withImage(response.image)
			.withGeneric(response.generic)
			.withContentFooter(response.footer)
			.withUrls(ModelMapper.remapUrlsFromResponse(response.urls))
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withDistributionRegions(ModelMapper.remapResponseToContentAttributesList(response.published_regions))
			.withDistributionChannels(ModelMapper.remapResponseToContentAttributesList(response.published_channels))
			.withOrigin(ModelMapper.remapResponceToContentAttribute(response.origin))
			.withAdditionalCategories(ModelMapper.remapContentCategoriesFromResponse(response.additional_categories))
			.withAuthors(ModelMapper.remapAuthorsFromResponse(response.authors))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withCreatedBy(response.created_by)
			.withVideoFiles(response.urls && response.urls.video_files)
			.withViewsCount(response.views)
			.withCommentsCount(response.comments)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withLanguage(response.language)
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapListingVideoFromResponse(response: any): Video {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.VIDEO);

		return new VideoBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withStrapline(response.strapline)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withOrigin(ModelMapper.remapResponceToContentAttribute(response.origin))
			.withCustomAuthor(response.custom_author)
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withCreatedBy(response.created_by)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withLanguage(response.language)
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapGalleryFromResponse(response: any, dateNow?: string): Gallery {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.GALLERY);
		response.comments = response.comments ? response.comments : {};
		const trandformedBlocks = response.body ? response.body.map((block: any) => new BackwardCompatibilityService().transform(block)) : [];

		return Gallery.builder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withBody(trandformedBlocks)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(dateNow ? dateNow : response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withComments(response.comments)
			.withCustomAuthor(response.custom_author)
			.withImage(response.image)
			.withGeneric(response.generic)
			.withContentFooter(response.footer)
			.withUrls(ModelMapper.remapUrlsFromResponse(response.urls))
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withAdditionalCategories(ModelMapper.remapContentCategoriesFromResponse(response.additional_categories))
			.withDistributionRegions(ModelMapper.remapResponseToContentAttributesList(response.published_regions))
			.withDistributionChannels(ModelMapper.remapResponseToContentAttributesList(response.published_channels))
			.withOrigin(ModelMapper.remapResponceToContentAttribute(response.origin))
			.withAuthors(ModelMapper.remapAuthorsFromResponse(response.authors))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withItems(ModelMapper.remapGalleryItemsFromResponse(response.items))
			.withAllowComments(response.comments.allow)
			.withCreatedBy(response.created_by)
			.withType(response.type)
			.withViewsCount(response.views)
			.withCommentsCount(response.comments)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withLanguage(response.language)
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapListingGalleryFromResponse(response: any): Gallery {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.GALLERY);

		return Gallery.builder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withCustomAuthor(response.custom_author)
			.withCategory(ModelMapper.remapCategoryFromResponse(response.category))
			.withCreatedBy(response.created_by)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withLanguage(response.language)
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapGalleryItemsFromResponse(response: any[]): GalleryItem[] {
		if (response) {
			return response.map((item: any) =>
				GalleryItem.builder()
					.withData(item)
					.withId(item.id)
					.withGeneric(item.generic)
					.withAddDescriptionToMedia(`${item.comment}` === `${item.description}`)
					.withType('image')
					.withComment(item.comment)
					.build(),
			);
		}

		return [];
	}

	static remapWikiFromResponse(response: any, dateNow?: string): Wiki {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.WIKI_PAGE);
		const trandformedBlocks = response.body ? response.body.map((block: any) => new BackwardCompatibilityService().transform(block)) : [];

		return new WikiBuilder()
			.withId(response.id)
			.withEntityType(response.entity_type)
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withStrapline(response.strapline)
			.withBody(trandformedBlocks)
			.withPublishedAt(response.published_at)
			.withUpdatedAt(response.updated_at)
			.withCreatedAt(dateNow ? dateNow : response.created_at)
			.withPublishedUntil(response.published_until)
			.withStatus(response.status)
			.withType(response.type)
			.withComments(response.comments)
			.withCustomAuthor(response.custom_author)
			.withLanguage(response.language)
			.withImage(response.image)
			.withGeneric(response.generic)
			.withContentFooter(response.footer)
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withAdditionalCategories(ModelMapper.remapContentCategoriesFromResponse(response.additional_categories))
			.withDistributionRegions(ModelMapper.remapResponseToContentAttributesList(response.published_regions))
			.withDistributionChannels(ModelMapper.remapResponseToContentAttributesList(response.published_channels))
			.withAuthors(ModelMapper.remapAuthorsFromResponse(response.authors))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withCreatedBy(response.created_by)
			.withViewsCount(response.views)
			.withCommentsCount(response.comments)
			.withTranslationGroup(ModelMapper.remapTranslationGroupFromResponse(response.translation_group))
			.withDynamicProperties(dynamicProperties)
			.build();
	}

	static remapAuthorsFromResponse(response: any): Author[] {
		if (response) {
			return response.map((author: any) => {
				return Author.builder()
					.withId(author.id)
					.withName(author.name)
					.withBio(author.bio)
					.withActive(author.active)
					.withIsDefault(author.default)
					.withAvatarImage(author.avatar_url)
					.withGeneric(author.generic)
					.withCreatedAt(author.created_at)
					.withUpdatedAt(author.updated_at)
					.withSeo(ModelMapper.remapSeoFromResponse(author.seo, author.name))
					.build();
			});
		}

		return [];
	}

	static remapAuthorFromResponse(response: any): Author {
		return new AuthorBuilder()
			.withId(response.id)
			.withName(response.name)
			.withBio(response.bio)
			.withBioHtml(response.bio_html)
			.withActive(response.active)
			.withIsDefault(response.default)
			.withAvatarImage(response.avatar_url)
			.withGeneric(response.generic)
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.name))
			.withSocialMediaLinks(response.social_media_links)
			.build();
	}

	static remapBannersFromResponse(response: any): Banner[] {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.BANNER);

		return response.map((banner: any) => {
			return Banner.builder()
				.withId(banner.id)
				.withTitle(banner.title)
				.withCode(banner.code)
				.withDynamicProperties(dynamicProperties)
				.withCreatedAt(banner.created_at)
				.withUpdatedAt(banner.updated_at)
				.withStatus(banner.status)
				.build();
		});
	}

	static remapBannerFromResponse(response: any): Banner {
		const dynamicProperties: DynamicContentProperty[] = getDynamicProperties(response, ContentTypes.BANNER);

		return new BannerBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withCode(response.code)
			.withDynamicProperties(dynamicProperties)
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withStatus(response.status)
			.build();
	}

	static remapTagsFromResponse(response: any): Tag[] {
		return (
			response &&
			response.map((tag: any) => {
				return ModelMapper.remapTagFromResponse(tag);
			})
		);
	}

	static remapTagFromResponse(response: any): Tag {
		return new TagBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withDescription(response.description)
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withUrls(ModelMapper.remapUrlsFromResponse(response.urls))
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withWeight(response.weight)
			.withType(response.type)
			.withOrderType(
				response.order_type
					? EnumItem.builder().withId(response.order_type).withName(OrderTypes[response.order_type]).build()
					: EnumItem.builder().build(),
			)
			.withCreatedBy(response.created_by)
			.build();
	}

	static remapListsFromResponse(response: any): List[] {
		return response.map((list: any) => {
			return List.builder()
				.withId(list.id)
				.withTitle(list.title)
				.withCreatedAt(list.created_at)
				.withUpdatedAt(list.updated_at)
				.withContentType(list.content_type)
				.withStatus(list.status)
				.build();
		});
	}

	static remapListFromResponse(response: any): List {
		let listConfig = {} as ListConfiguration;
		if (response && response.configuration && response.configuration.sports) {
			listConfig = ListConfiguration.builder()
				.withMinItems(response.configuration ? response.configuration.min_items : '')
				.withMaxItems(response.configuration ? response.configuration.max_items : '')
				.withCategory(response.configuration && response.configuration.category ? response.configuration.category : Category.builder().build())
				.withSports(response.configuration && response.configuration.sports ? response.configuration.sports : [])
				.build();
		} else {
			listConfig = ListConfiguration.builder()
				.withMinItems(response.configuration ? response.configuration.min_items : '')
				.withMaxItems(response.configuration ? response.configuration.max_items : '')
				.withCategory(response.configuration && response.configuration.category ? response.configuration.category : Category.builder().build())
				.withSports(response.configuration && response.configuration.football ? response.configuration.football : [])
				.build();
		}

		return new ListBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withSlug(response.slug)
			.withConfiguration(listConfig)
			.withStatus(response.status)
			.withType(response.type)
			.withItems(ModelMapper.remapListItemsFromResponse(response.items))
			.withLockedPositions(response.locked_positions)
			.withContentType(response.content_type)
			.withLanguage(response.language)
			.build();
	}

	static remapListItemsFromResponse(response: any[]): ListItem[] {
		return response.map((item: any) => ModelMapper.remapListItemFromResponse(item));
	}

	static remapListItemFromResponse(response: any): ListItem {
		const scheduledItemsPopulated = { from: response.from || undefined, to: response.to || undefined };
		const metaPopulated = response.meta ? { ...response.meta, ...scheduledItemsPopulated } : scheduledItemsPopulated;

		return ListItem.builder()
			.withData(ModelMapper.remapContentDataInListItem(response.data, response.type))
			.withMeta(ModelMapper.remapListItemMetaFromResponse(metaPopulated))
			.withType(response.type)
			.withWeight(response.weight)
			.build();
	}

	static remapListItemMetaFromResponse(response: any): ListItemMeta {
		return ListItemMeta.builder()
			.withTitle(response.title)
			.withSubtitle(response.subtitle)
			.withImageId(response.image_id)
			.withFromDate(response.from)
			.withToDate(response.to)
			.build();
	}

	static remapContentDataInListItem(data: any, type: string) {
		if (type.toLowerCase() === 'article') {
			return ModelMapper.remapArticleFromResponse(data);
		}

		if (type.toLowerCase() === 'video') {
			return ModelMapper.remapVideoFromResponse(data);
		}

		if (type.toLowerCase() === 'gallery') {
			return ModelMapper.remapGalleryFromResponse(data);
		}

		if (type.toLowerCase() === 'wiki') {
			return ModelMapper.remapWikiFromResponse(data);
		}

		if (type.toLowerCase() === ContentTypes.LIVE_BLOG) {
			return responseToLiveBlogModel(data);
		}

		return data;
	}

	static remapProjectsFromResponse(response: any): Project[] {
		return response.map((project: any) => {
			return Project.builder()
				.withId(project.id)
				.withTitle(project.title)
				.withDomain(project.domain)
				.withLanguages(ModelMapper.remapContentLanguagesFromResponse(project.languages))
				.withLanguage(project.language ? project.language.split('-')[0] : 'en')
				.withColorScheme(project.color_schema)
				.withGoogleAnalyticsId(project.google_analytics_id)
				.withFacebookPixelCode(project.facebook_pixel_code)
				.withArticleVoting(project.article_voting)
				.withDomainImages(project.domain_images)
				.withDomainVideos(project.domain_videos)
				.withCommentsApproval(project.comments_approval)
				.withCommentsRegisteredUsersOnly(project.comments_registered_users_only)
				.withCommentsRating(project.comments_rating)
				.withCommentsThreading(project.comments_threading)
				.withCommentsViewCensored(project.comments_view_censored)
				.withDefaultOddClient(project.default_odd_client)
				.withFootballApiUrl(project.football_api_url)
				.withDefaultTournamentOrder(project.default_tournament_order)
				.withTimezone(project.timezone)
				.withPreviewUrl(project.preview_url)
				.withImageApiUrl(project.image_api_url)
				.build();
		});
	}

	static remapProfileFromResponse(response: any, permissionsResponse: any): Profile {
		let permissions: Permission[] = permissionsResponse.map((permission: any) => {
			return Permission.builder().withId(permission.id).withName(permission.name).withTitle(permission.title).build();
		});

		return Profile.builder()
			.withId(response.id)
			.withEmail(response.email)
			.withIsAuthenticated(true)
			.withName(response.full_name)
			.withOrganization(response.organization)
			.withPhone(response.phone)
			.withLanguage(response.language)
			.withIsActive(response.is_active)
			.withLastLoginDate(response.last_login_date)
			.withPermissions(permissions)
			.build();
	}

	static remapCategoriesFromResponse(response: any): Category[] {
		return ModelMapper.getNormalizedCategories(response).map((category) => {
			return Category.builder()
				.withId(category.id)
				.withTitle(category.title)
				.withDescription(category.description)
				.withActive(category.active)
				.withParentId(category.parent_id)
				.withGeneric(category.generic)
				.withSeo(ModelMapper.remapSeoFromResponse(category.seo, response.title))
				.withUrls(ModelMapper.remapUrlsFromResponse(category.urls))
				.withCreatedAt(category.created_at)
				.withUpdatedAt(category.updated_at)
				.withSubIndent(category.indent)
				.build();
		});
	}

	static remapContentCategoriesFromResponse(response: any): Category[] {
		if (response) {
			return flattenTree(response).map((category: any) => {
				return Category.builder()
					.withId(category.id)
					.withTitle(category.title)
					.withDescription(category.description)
					.withActive(category.active)
					.withParentId(category.parent_id)
					.withGeneric(category.generic)
					.withSeo(ModelMapper.remapSeoFromResponse(category.seo, response.title))
					.withUrls(ModelMapper.remapUrlsFromResponse(category.urls))
					.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
					.withCreatedAt(category.created_at)
					.withUpdatedAt(category.updated_at)
					.withSubIndent(category.indent)
					.build();
			});
		}

		return [];
	}

	static remapTeamFromResponse(response: any): TeamManualDataModel {
		if (response) {
			return TeamManualDataModel.builder()
				.withId(response.id)
				.withName(response.name)
				.withType(
					response.type ? EnumItem.builder().withId(TeamType[response.type]).withName(response.type).build() : EnumItem.builder().build(),
				)
				.withCountry(response.country)
				.withCoach(response.coach)
				.withFounded(response.founded)
				.withVenue(remapEntityFromResponse(response.venue, TranslationEntities.VENUE))
				.withSocial(response.social)
				.withSocial(this.remapTeamSocialFromResponse(response.social))
				.withAssets(this.remapAssets(response.assets))
				.withThreeLetterCode(response.three_letter_code)
				.withShirtColors(response.shirt_colors)
				.withShortName(response.short_name)
				.build();
		}

		return TeamManualDataModel.builder().build();
	}

	static remapPlayerFromResponse(response: any): PlayerManualDataModel {
		if (response) {
			return PlayerManualDataModel.builder()
				.withId(response.id)
				.withName(response.name)
				.withCountry(response.country)
				.withActive(response.active)
				.withBirthCity(response.birth_city)
				.withBirthdate(response.birthdate)
				.withProfile(response.profile)
				.withPosition(
					response.position
						? EnumItem.builder().withId(response.position).withName(PlayerPositions[response.position]).build()
						: EnumItem.builder().build(),
				)
				.withAssets(this.remapAssets(response.assets))
				.withSocial(this.remapPlayerSocialFromResponse(response.social))
				.build();
		}

		return PlayerManualDataModel.builder().build();
	}

	static remapCoachFromResponse(response: any): CoachManualDataModel {
		if (response) {
			return CoachManualDataModel.builder()
				.withId(response.id)
				.withName(response.name)
				.withCountry(response.country)
				.withBirthdate(response.birthdate)
				.withAssets(this.remapAssets(response.assets))
				.withURLs(response.url_logo, response.url_thumb)
				.build();
		}

		return CoachManualDataModel.builder().build();
	}

	static remapAssets(assets: any): AssetsModel {
		if (assets) {
			return AssetsModel.builder()
				.withLogo(assets.logo && assets.logo.url)
				.withHomeKit(assets.home_kit && assets.home_kit.url)
				.withAwayKit(assets.away_kit && assets.away_kit.url)
				.withSquadImage(assets.squad_image && assets.squad_image.url)
				.withImage(assets.image && assets.image.url)
				.withThumbnail(assets.thumb && assets.thumb.url)
				.build();
		}

		return AssetsModel.builder().build();
	}

	static remapTeamSocialFromResponse(social: any) {
		if (social) {
			return Social.builder()
				.withFacebookId(social.facebook_id)
				.withInstagramId(social.instagram_id)
				.withTwitterId(social.twitter_id)
				.withWeb(social.web)
				.withWikipediaId(social.wikipedia_id)
				.build();
		}

		return Social.builder().build();
	}

	static remapPlayerSocialFromResponse(social: any) {
		if (social) {
			return Social.builder()
				.withFacebookId(social.facebook_id)
				.withInstagramId(social.instagram_id)
				.withTwitterId(social.twitter_id)
				.withWeb(social.web)
				.withYoutubeId(social.youtube_channel_id)
				.withWikipediaId(social.wikipedia_id)
				.build();
		}

		return Social.builder().build();
	}

	static remapCategoryFromResponse(response: any): Category {
		return Category.builder()
			.withId(response.id)
			.withTitle(response.title)
			.withDescription(response.description)
			.withActive(response.active)
			.withGeneric(response.generic)
			.withSeo(ModelMapper.remapSeoFromResponse(response.seo, response.title))
			.withUrls(ModelMapper.remapUrlsFromResponse(response.urls))
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withSubIndent(response.indent)
			.build();
	}

	static remapSeoFromResponse(response: any, contentTitle?: string): Seo {
		if (response) {
			return Seo.builder()
				.withTitle(response.title)
				.withDescription(response.description)
				.withSlug(response.slug)
				.withKeywords(response.keywords)
				.withIndex(response.index)
				.withFollow(response.follow)
				.withRedirectType(response.redirect_type)
				.withAutoSlug(response.automatic_slug)
				.withAutoTitle(response.automatic_seo_title)
				.withJsonLD(response.jsonld || '')
				.build();
		}

		return Seo.builder().build();
	}

	static remapUrlsFromResponse(response: any): Urls {
		if (response) {
			return Urls.builder()
				.withExternalUrl(response.external_url)
				.withCanonicalUrl(response.canonical_url)
				.withPublicUrlDesktop(response.public_url_desktop)
				.withPublicUrlMobile(response.public_url_mobile)
				.withPublicUrlAmp(response.public_url_amp)
				.build();
		}

		return Urls.builder().build();
	}

	static remapResponseToMainMediaList(response: any): MainMedia[] {
		if (response) {
			return response.map((mediaResponse: any) => ModelMapper.remapMainMediaFromResponse(mediaResponse));
		}

		return [];
	}

	static remapMainMediaFromResponse(response: any): MainMedia {
		return MainMedia.builder()
			.withResourceId(response.resource_id)
			.withResourceType(response.resource_type)
			.withResourceSubtype(response.resource_subtype)
			.withProvider(response.provider)
			.withDescription(response.description)
			.withData(response.data)
			.build();
	}

	static remapImageListFromResponse(response: any): Image[] {
		return response.map((item: any) => ModelMapper.remapImageFromResponse(item));
	}

	static remapImageListingFromResponse(response: any): Image[] {
		return response.map((item: any) => ModelMapper.remapListingImageFromResponse(item));
	}

	static remapImageFromResponse(response: any): Image {
		return Image.builder()
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withDescription(response.description)
			.withAlt(response.alt)
			.withCaption(response.caption)
			.withGeneric(response.generic)
			.withId(response.id)
			.withOwner(response.origin ? ModelMapper.remapOwnerFromResponse(response.origin) : EnumItem.builder().build())
			.withType(response.type)
			.withUrls(ModelMapper.remapImageUrlsFromResponse(response.urls))
			.withAuthors(response.authors ? ModelMapper.remapAuthorsFromResponse(response.authors) : [])
			.withPath(response.path)
			.build();
	}

	static remapListingImageFromResponse(response: any): Image {
		return Image.builder()
			.withCreatedAt(response.created_at)
			.withUpdatedAt(response.updated_at)
			.withDescription(response.description)
			.withId(response.id)
			.withPath(response.path)
			.withUrls(ModelMapper.remapImageUrlsFromResponse(response.urls))
			.build();
	}

	static remapImageUrlsFromResponse(response: any): ImageUrls {
		return ImageUrls.builder()
			.with1x1(response['1x1'])
			.with2x3(response['2x3'])
			.with3x2(response['3x2'])
			.with3x4(response['3x4'])
			.with4x3(response['4x3'])
			.with9x16(response['9x16'])
			.with16x9(response['16x9'])
			.with23x9(response['23x9'])
			.withCropped(response.cropped)
			.withUploaded(ModelMapper.remapImageUrlsUploadedFromResponse(response.uploaded))
			.build();
	}

	static remapImageUrlsUploadedFromResponse(response: any): ImageUrlsUploaded {
		return ImageUrlsUploaded.builder()
			.withEmbed(response.embed)
			.withGallery(response.gallery)
			.withOriginal(response.original)
			.withThumbnail(response.thumbnail)
			.build();
	}

	static remapOwnerFromResponse(response: any) {
		return EnumItem.builder().withId(response.id).withName(response.name).build();
	}

	static remapFeedSourcesFromResponse(sources: any) {
		if (sources) {
			return sources.map((source: any) => {
				return Source.builder()
					.withId(source.id)
					.withLocation(source.location)
					.withName(source.name)
					.withType(source.type)
					.withSubtype(source.subtype)
					.withThumbnail(source.thumbnail)
					.build();
			});
		} else return [];
	}

	static remapPostFromResponse(item: any, sources: Source[]) {
		const sourceName = sources.filter((source: Source) => source.location === item.source_location)[0].name;

		return Post.builder()
			.withId(item.origin_id)
			.withType(item.type)
			.withSubtype(item.subtype)
			.withEmbed(item.embed)
			.withUrl(item.origin_location)
			.withTimestamp(item.published_at)
			.withSourceLocation(item.source_location)
			.withSourceName(sourceName)
			.withSourceThumbnail(item.source_thumbnail)
			.build();
	}

	static remapFeedsFromResponse(response: any): Feed[] {
		if (response && response.data) {
			return response.data.map((feed: any) => this.remapFeedFromResponse(feed));
		}

		return [];
	}

	static remapFeedFromResponse(response: any): Feed {
		return new FeedBuilder()
			.withId(response.id)
			.withName(response.name)
			.withSource(ModelMapper.remapFeedSourcesFromResponse(response.sources))
			.withActive(response.active)
			.build();
	}

	static remapTemplatesFromResponse(response: any): Feed[] {
		if (response) {
			return response.map((template: any) => this.remapTemplateFromResponse(template));
		}

		return [];
	}

	static remapTemplateFromResponse(response: any): Feed {
		return new FeedBuilder().withId(response.id).withName(response.name).withSource(response.sources).withActive(response.active).build();
	}

	static getNormalizedCategories(response: any) {
		let categories: any[] = [];
		let flatResponse = flattenTree(response);
		flatResponse.forEach((category: any) => {
			categories.push(category);
			categories.push(...ModelMapper.extractSubCategoriesCategories(category.subs));
		});

		categories = categories.map((category) => {
			if (category.hasOwnProperty('subs')) {
				delete category.subs;
			}

			return category;
		});

		return categories;
	}

	static extractSubCategoriesCategories(categories: any[], indentSymbol = this.indentSymbol, indent = 1, extractedCategories: any[] = []) {
		if (categories && categories.length) {
			categories.forEach((category: any) => {
				extractedCategories.push({
					...category,
					indent: `${indentSymbol.repeat(indent)}`,
				});

				if (category.subs && category.subs.length) {
					this.extractSubCategoriesCategories(category.subs, indentSymbol, indent + 1, extractedCategories);
				}
			});
		}

		return extractedCategories;
	}

	static extractAvailableProjects(projects: Project[], currentProject: Project): Project[] {
		return projects.filter((project: Project) => project.id !== currentProject.id);
	}

	static remapPollFromResponse(response: any): Poll {
		return new PollBuilder()
			.withId(response.id)
			.withTitle(response.title)
			.withText(response.text)
			.withCreatedAt(response.created_at)
			.withEntityType(response.entity_type)
			.withGeneric(response.generic)
			.withOptions(response.options)
			.withStatus(response.status)
			.withUpdatedAt(response.updated_at)
			.withComments(response.comments)
			.withMainMedia(ModelMapper.remapResponseToMainMediaList(response.main_media))
			.build();
	}

	static remapResponseToFeatureList(response: any) {
		return response.map((item: any) => Feature.builder().withId(item.feature_id).withEnabled(item.enabled).withData(item.data).build());
	}

	static remapResponceToContentAttribute(attribute: any) {
		if (attribute) {
			return ContentAttributes.builder()
				.withId(attribute.id)
				.withName(attribute.name)
				.withSlug(attribute.slug)
				.withCreatedBy(attribute.created_by)
				.withCreatedAt(attribute.created_at)
				.withUpdatedAt(attribute.updated_at)
				.withDescription(attribute.description)
				.withDefault(attribute.default)
				.build();
		}

		return {};
	}

	static remapResponseToContentAttributesList(response: any) {
		if (response && response.length > 0) {
			return response.map((attribute: any) => this.remapResponceToContentAttribute(attribute));
		} else return [];
	}

	static remapStatisticFromResponse(response: any): Statistic {
		return new StatisticBuilder().withDate(response.date).withCount(response.count).withType(response.type).build();
	}

	static remapStatisticsFromResponse(response: any, resourceType: string): Statistics {
		return new StatisticsBuilder()
			.withContext(this.remapStatisticsContextFromResponse(response.context))
			.withTimeseries(this.remapStatisticsTimeseries(response, resourceType))
			.withTotals(this.remapStatisticsTotalsFromResponse(response.totals))
			.build();
	}

	static remapStatisticsContextFromResponse(context: any): Context {
		return new ContextBuilder().withGroupBy(context.group_by).withStartDate(context.start_date).withEndDate(context.end_date).build();
	}

	static remapStatisticsTotalsFromResponse(totals: any): Totals {
		return new TotalsBuilder()
			.withArticles(!totals.articles || totals.articles.length === 0 ? 0 : totals.articles)
			.withVideos(!totals.videos || totals.videos.length === 0 ? 0 : totals.videos)
			.withGalleries(!totals.galleries || totals.galleries.length === 0 ? 0 : totals.galleries)
			.withImages(!totals.images || totals.images.length === 0 ? 0 : totals.images)
			.build();
	}

	static remapStatisticsTimeseries(data: any, resourceType: string): any {
		const groupedResponse = statisticsHelpers.groupStatisticsByType(data, resourceType);
		const remappedStatistics = {};
		Object.keys(groupedResponse).forEach((key: string): void => {
			const range = statisticsHelpers.timeRange(data.context);
			let resourceData: Statistic[] = [];
			range.map((date: string): void => {
				if (groupedResponse[key].timeseries[date]) {
					resourceData.push(this.remapStatisticFromResponse(groupedResponse[key].timeseries[date]));
				} else {
					resourceData.push(this.remapStatisticFromResponse({ date, count: 0, type: key }));
				}
			});
			remappedStatistics[key] = resourceData;
		});

		return remappedStatistics;
	}

	static remmapListingContentWithContentStatisticsFromResponse = (contentStatitstics: any, content: any, type: any) => {
		const remmapedContent: any[] = [];
		content.length > 0 &&
			contentStatitstics.length > 0 &&
			content.map((item: any) => {
				contentStatitstics.map((statistic: any) => {
					if (statistic.id === item.id) {
						remmapedContent.push(ModelMapper.remmapContentWithContentStatistics(statistic, item, type));
					}
				});
			});

		return contentStatitstics.length > 0 ? remmapedContent : content;
	};

	static remmapContentWithContentStatistics = (statistic: any, content: any, type?: string) => {
		switch (type) {
			case 'article':
				return Article.builder(content).withViewsCount(statistic.data.views).withCommentsCount(statistic.data.comments).build();
			case 'video':
				return Video.builder(content).withViewsCount(statistic.data.views).withCommentsCount(statistic.data.comments).build();
			case 'gallery':
				return Gallery.builder(content).withViewsCount(statistic.data.views).withCommentsCount(statistic.data.comments).build();
			case 'wiki':
				return Wiki.builder(content).withViewsCount(statistic.data.views).withCommentsCount(statistic.data.comments).build();
		}
	};

	static remmapListItemsWithContentStatisticsFromResponse = (contentStatitstics: any, list: List) => {
		const remmapedContent: ListItem[] = [];
		list.items.length > 0 &&
			list.items.map((item: ListItem) => {
				if (item.type.toLowerCase() === ContentTypes.LIVE_BLOG) {
					remmapedContent.push(item);
				}
				contentStatitstics.map((statistic: any) => {
					if (statistic.id === item.data.id) {
						item.data.viewsCount = statistic.data.views;
						item.data.commentsCount = statistic.data.comments;
						remmapedContent.push(item);
					}
				});
			});
		return List.builder(list).withItems(remmapedContent).build();
	};

	static remapTranslationGroupFromResponse(response: any): TranslationGroupModel {
		if (response) {
			return TranslationGroupModel.builder()
				.withId(response.id)
				.withContentType(response.content_type)
				.withMasterContentId(response.master_content_id)
				.withTranslations(ModelMapper.remapTranslationsFromResponse(response.translations))
				.build();
		}

		return TranslationGroupModel.builder().build();
	}

	static remapTranslationsFromResponse(response: any): TranslationsModel[] {
		if (response && response.length > 0) {
			return response.map((item: any) => TranslationsModel.builder().withId(item.id).withLanguage(item.language).build());
		}

		return [];
	}

	static remapContentLanguagesFromResponse(response: any): ContentLanguagesModel {
		if (response) {
			return ContentLanguagesModel.builder()
				.withAll(ModelMapper.remapAllCodesFromResponse(response.all))
				.withDefaultLanguageCode(ModelMapper.remapDefaultLanguageCodeFromResponse(response.default))
				.withMultiple(response.multiple)
				.build();
		}

		return ContentLanguagesModel.builder().build();
	}

	static remapAllCodesFromResponse(response: any): LanguageCodeModel[] {
		if (response && response.length > 0) {
			return response.map((item: any) => LanguageCodeModel.builder().withLanguageCode(item.language_code).build());
		}

		return [];
	}

	static remapDefaultLanguageCodeFromResponse(response: any): LanguageCodeModel {
		if (response) {
			return LanguageCodeModel.builder().withLanguageCode(response.language_code).build();
		}

		return LanguageCodeModel.builder().build();
	}
}

function flattenTree(tree: any) {
	function recurse(nodes: any, path: any): any {
		return _.map(nodes, function (node) {
			let newPath = _.union(path, [node.name]);

			return [
				_.assign(
					{
						pathname: newPath.join(' > '),
						level: path.length,
					},
					_.omit(node, 'children'),
				),
				recurse(node.children, newPath),
			];
		});
	}

	return _.flattenDeep(recurse(tree, []));
}
