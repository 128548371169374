import React from 'react';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { MatchQuizManager } from 'fansunited-widget-match-quiz';
import { useTranslation } from 'react-i18next';
import './fans-united-styles.scss';

export const MatchQuizManagerComponent: React.FunctionComponent = () => {
	const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const [t] = useTranslation();

	const labels = {
		userNotFound: t('fu_userNotFound'),
		clientConfigErrorMessage: t('fu_clientConfigErrorMessage'),
		featureDisabled: t('fu_featureDisabled'),
		noGamesFound: t('fu_noGamesFound'),
		noPermissionMessage: t('fu_noPermissionMessage'),
		createEdition: t('fu_createEdition'),
		tryAgainButton: t('fu_tryAgainButton'),
		name: t('fu_name'),
		status: t('fu_status'),
		creationDate: t('fu_creationDate'),
		acceptUntil: t('fu_acceptUntil'),
		actions: t('fu_actions'),
		upcoming: t('fu_upcoming'),
		inProgress: t('fu_inProgress'),
		finished: t('fu_finished'),
		pending: t('fu_pending'),
		settled: t('fu_settled'),
		live: t('fu_live'),
		open: t('fu_open'),
		canceled: t('fu_canceled'),
		closed: t('fu_closed'),
		edit: t('fu_edit'),
		report: t('fu_report'),
		timeFormat: t('fu_timeFormat'),
		previous: t('fu_previous'),
		next: t('fu_next'),
		namePlaceholder: t('fu_namePlaceholder'),
		description: t('fu_description'),
		descriptionPlaceholder: t('fu_descriptionPlaceholder'),
		matchSelection: t('fu_matchSelection'),
		matchSelectionDescription: t('fu_matchSelectionDescription'),
		matchNumber: t('fu_matchNumber'),
		selectTeamPlaceholder: t('fu_selectTeamPlaceholder'),
		selectTeamNoTeamFound: t('fu_selectTeamNoTeamFound'),
		selectTeamNoMatchesFound: t('fu_selectTeamNoMatchesFound'),
		addFixture: t('fu_addFixture'),
		removeFixture: t('fu_removeFixture'),
		results: t('fu_results'),
		goldenGoal: t('fu_goldenGoal'),
		minFixturesMessage: t('fu_minFixturesMessage'),
		sameChosenMatchMessage: t('fu_sameChosenMatchMessage'),
		atleastThreeCharsMessage: t('fu_atleastThreeCharsMessage'),
		marketSelection: t('fu_marketSelection'),
		chosenMarkets: t('fu_chosenMarkets'),
		otherMarkets: t('fu_otherMarkets'),
		marketSelectionCreateGameDescription: t('fu_marketSelectionCreateGameDescription'),
		marketSelectionEditGameDescription: t('fu_marketSelectionEditGameDescription'),
		markets: t('fu_markets'),
		marketFt1x2: t('fu_marketFt1x2'),
		marketHt1x2: t('fu_marketHt1x2'),
		marketBothTeamsScore: t('fu_marketBothTeamsScore'),
		marketDoubleChance: t('fu_marketDoubleChance'),
		marketHtFt: t('fu_marketHtFt'),
		marketRedCardMatch: t('fu_marketRedCardMatch'),
		marketPenaltyMatch: t('fu_marketPenaltyMatch'),
		marketCornersMatch: t('fu_marketCornersMatch'),
		marketCorrectScore: t('fu_marketCorrectScore'),
		marketPlayerScore: t('fu_marketPlayerScore'),
		marketPlayerYellowCard: t('fu_marketPlayerYellowCard'),
		marketPlayerRedCard: t('fu_marketPlayerRedCard'),
		marketPlayerScoreFirstGoal: t('fu_marketPlayerScoreFirstGoal'),
		marketPlayerScoreTwice: t('fu_marketPlayerScoreTwice'),
		marketPlayerScoreHattrick: t('fu_marketPlayerScoreHattrick'),
		marketOverZeroGoals: t('fu_marketOverZeroGoals'),
		marketOverOneGoals: t('fu_marketOverOneGoals'),
		marketOverTwoGoals: t('fu_marketOverTwoGoals'),
		marketOverThreeGoals: t('fu_marketOverThreeGoals'),
		marketOverFourGoals: t('fu_marketOverFourGoals'),
		marketOverFiveGoals: t('fu_marketOverFiveGoals'),
		marketOverSixGoals: t('fu_marketOverSixGoals'),
		marketOverSixCorners: t('fu_marketOverSixCorners'),
		marketOverSevenCorners: t('fu_marketOverSevenCorners'),
		marketOverEightCorners: t('fu_marketOverEightCorners'),
		marketOverNineCorners: t('fu_marketOverNineCorners'),
		marketOverTenCorners: t('fu_marketOverTenCorners'),
		marketOverElevenCorners: t('fu_marketOverElevenCorners'),
		marketOverTwelveCorners: t('fu_marketOverTwelveCorners'),
		marketOverThirteenCorners: t('fu_marketOverThirteenCorners'),
		gameStatus: t('fu_gameStatus'),
		rules: t('fu_rules'),
		rulesDescription: t('fu_rulesDescription'),
		flags: t('fu_flags'),
		flagsPlaceholder: t('fu_flagsPlaceholder'),
		repeatedFlagMessage: t('fu_repeatedFlagMessage'),
		createGameStatusDescription: t('fu_createGameStatusDescription'),
		editGameStatusDescription: t('fu_editGameStatusDescription'),
		cutoffTime: t('fu_cutoffTime'),
		createCutoffTimeDescription: t('fu_createCutoffTimeDescription'),
		editCutoffTimeDescription: t('fu_editCutoffTimeDescription'),
		editGame: t('fu_editGame'),
		back: t('fu_back'),
		reportFor: t('fu_reportFor'),
		position: t('fu_position'),
		points: t('fu_points'),
		predictions: t('fu_predictions'),
		gameNotSettledMessage: t('fu_gameNotSettledMessage'),
		clear: t('fu_clear'),
		loadingMessage: t('fu_loadingMessage'),
		createGameMessage: t('fu_createGameMessage'),
		editGameMessage: t('fu_editGameMessage'),
		clearCutoffMessage: t('fu_clearCutoffMessage'),
		selectMatchErrorMessage: t('fu_selectMatchErrorMessage'),
		chooseGameCutoffTimeMessage: t('fu_chooseGameCutoffTimeMessage'),
		minimumMarketMessage: t('fu_minimumMarketMessage'),
		createGameErrorMessage: t('fu_createGameErrorMessage'),
		editGameErrorMessage: t('fu_editGameErrorMessage'),
		invalidCutoffTimeMessage: t('fu_invalidCutoffTimeMessage'),
		leaderboardErrorMessage: t('fu_leaderboardErrorMessage'),
		gamePredictionsErrorMessage: t('fu_gamePredictionsErrorMessage'),
	};

	return config &&
		config.configuration &&
		config.configuration.api_key &&
		config.configuration.client_id &&
		config.configuration.language ? (
		<div className='fans-united-container'>
			<MatchQuizManager
				fansUnitedApiKey={config.configuration.api_key}
				fansUnitedLanguage={config.configuration.language}
				fansUnitedClientId={config.configuration.client_id}
				labels={labels}
			/>
		</div>
	) : null;
};
