import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { FootballPlayerH2HJson } from './football-player-h2h.json';
import FootballPlayerH2HBuilder from './football-player-h2h.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballPlayerH2HWidgetModel {
	readonly player1: PlayerModel;
	readonly player2: PlayerModel;
	readonly player1TournamentSeason: EntitySeasonsSelectModel;
	readonly player2TournamentSeason: EntitySeasonsSelectModel;
	readonly stats: string[];
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = false;

	private constructor(
		player1: PlayerModel,
		player2: PlayerModel,
		player1TournamentSeason: EntitySeasonsSelectModel,
		player2TournamentSeason: EntitySeasonsSelectModel,
		stats: string[],
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean,
	) {
		this.player1 = player1;
		this.player2 = player2;
		this.player1TournamentSeason = player1TournamentSeason;
		this.player2TournamentSeason = player2TournamentSeason;
		this.stats = stats;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): FootballPlayerH2HJson {
		return {
			player1: this.player1,
			player2: this.player2,
			player1TournamentSeason: this.player1TournamentSeason,
			player2TournamentSeason: this.player2TournamentSeason,
			stats: this.stats,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: FootballPlayerH2HJson): FootballPlayerH2HWidgetModel {
		return new FootballPlayerH2HWidgetModel(
			json.player1,
			json.player2,
			json.player1TournamentSeason,
			json.player2TournamentSeason,
			json.stats,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(model?: FootballPlayerH2HWidgetModel): FootballPlayerH2HBuilder {
		return new FootballPlayerH2HBuilder(model);
	}
}
