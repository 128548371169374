import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/ArticleCreateProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { StrapLine } from '../../Partials/Fields/strap-line/StrapLineComponent';
import { Summary } from '../../Partials/Fields/summary/SummaryComponent';
import Article from '../../../models/article/Article';
import { articleDraftClear, clearTempArticle, updateTempArticle } from '../../../store/action-creators/ArticleTempActionCreator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	ARTICLE_CREATE_RESOURCES_SUCCESS,
	ARTICLE_ENTITY_CREATE,
	ARTICLE_ENTITY_CREATE_FAILED,
	ARTICLE_ENTITY_CREATE_SUCCESS,
	returnObjectForArticleEntityCreate,
} from '../../../store/action-creators/ArticleActionCreator';
import Project from '../../../models/project/Project';
import Related from '../../../models/related/Related';
import MainMedia from '../../../models/mainmedia/MainMedia';
import { clearArticleMatchTempRelated, updateArticleMatchTempRelated } from '../../../store/action-creators/RelatedMatchTempActionCreator';
import { updateArticleTagTempRelated } from '../../../store/action-creators/RelatedTagsTempActionCreator';
import { updateArticleSportsTempRelated } from '../../../store/action-creators/RelatedSportsTempActionCreator';
import { updateArticleContentTempRelated } from '../../../store/action-creators/RelatedContentTempActionCreator';
import HttpService from '../../../services/rest/HttpService';
import { previewFormPost } from './Helpers/ArticlePreviewHelper';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentMode, ContentTypes } from '../../../constants/content-types';
import { actionService, featuresService, multiLingualService, wordCountService } from '../../../App';
import SeoModel from '../../../models/seo/Seo';
import Blocky from '../../Partials/Blocky/subcomponents/blocky.component';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { Subscription } from 'rxjs';
import List from '../../../models/list/List';
import {
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../store/action-creators/ImageActionCreator';
import ModelMapper from '../../../models/ModelMapper';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import WordCountComponent from '../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { toast } from 'react-toastify';
import { MandatoryFieldsService } from '../../../services/mandatory-fields-service/mandatory-fields.service';
import Flag from 'react-world-flags';
import { initializeBlockyUndoRedo, resetUndoRedoBlocky, setAutoTaggingScope } from '../../../store/action-creators/BlockyActionCreator';
import BlockModel from '../../Partials/Blocky/models/block.model';
import EditorToolbar from '../../Partials/EditorToolbar/EditorToolbar';
import {
	createListenerForContentCreateRedirect,
	createStartingBlocky,
	createStartingEntity,
	populateExistingEntity,
} from '../../../services/content-models-service/ContentModelService';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarURLs from '../../Partials/Sidebar/urls-refactored/urls.component';
import SidebarContentFooter from '../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../global-helpers/global-features.helpers';
import SidebarRelatedContent from '../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarList from '../../Partials/Sidebar/list-refactored/list-wrapper';
import SidebarMainMedia from '../../Partials/Sidebar/media-refactored/media-wrapper';
import { clearSuggestedEntities } from '../../../store/action-creators/suggested-entities';
import _ from 'lodash';
import { ArticleAutoTaggingScope } from '../../../models/article/ArticleJson';
import { getMetadataVersionsList } from '../helpers/resources.helper';
import CustomTabs from '../../Partials/BaseComponents/CustomTabsNavigation/custom-tabs-navigation';
import { VersionModel } from '../../../models/v2/meta-data-versions/versions.model';

class ArticleCreate extends React.Component<Properties, State> {
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();
	private actionServiceSubject = new Subscription();

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleDeleteModal: false,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: false,
			openSidebarSettingsModalFlag: false,
			metadataVersions: [],
			activeTab: 'default',
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.initArticleCreateSuccessListener();
		this.displayClearTempArticleModal();
		appendBeforeUnloadResetTempSidebar(ContentTypes.ARTICLE);

		this.props.article.id === 'temp-copy-id' && this.props.updateTempArticle(Article.builder(this.props.article).withId('temp-id').build());

		let article = populateExistingEntity(ContentTypes.ARTICLE, this.props.article) as Article;
		if (article && (!article.body || article.body.length === 0)) {
			article = populateExistingEntity(ContentTypes.ARTICLE, this.props.article, true) as Article;
		}

		this.props.updateTempArticle({ ...article, properties: Article.extractDynamicPropertiesAsObjectWithValues() });
		try {
			this.props.article.body && this.props.article.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.article.body]);
		} catch {
			this.props.initializeBlockyUndoRedo(createStartingBlocky());
		}

		this.props.setAutoTaggingScope(article.autoTaggingScope || {});

		const versions = this.props.article.versions || [];
		const metadataVersions = getMetadataVersionsList().map((version) => {
			const matchedVersion = versions.find((v) => v.id === version.id);

			return {
				id: version.id,
				name: version.name,
				title: matchedVersion ? matchedVersion.title : '',
				strapline: matchedVersion ? matchedVersion.strapline : '',
				description: matchedVersion ? matchedVersion.description : '',
				enabled: matchedVersion ? matchedVersion.enabled : false,
			};
		});

		this.setState({ metadataVersions });
	}

	componentWillUnmount() {
		this.actionServiceSubject.unsubscribe();
		this.props.resetUndoRedoBlocky();
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (prevProps.article.versions !== this.props.article.versions) {
			const versions = this.props.article.versions || [];
			const metadataVersions = getMetadataVersionsList().map((version) => {
				const matchedVersion = versions.find((v) => v.id === version.id);

				return {
					id: version.id,
					name: version.name,
					title: matchedVersion ? matchedVersion.title : '',
					strapline: matchedVersion ? matchedVersion.strapline : '',
					description: matchedVersion ? matchedVersion.description : '',
					enabled: matchedVersion ? matchedVersion.enabled : false,
				};
			});

			// Update the state with new metadataVersions
			this.setState({ metadataVersions });
		}

		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}

		if (this.props.project !== prevProps.project) {
			if (multiLingualService.checkIfProjectIsMultiLingual(this.props.project.languages)) {
				const article = populateExistingEntity(ContentTypes.ARTICLE, this.props.article);
				article && this.props.updateTempArticle(article);
			}
		}

		if (!_.isEqual(prevProps.autoTaggingScope, this.props.autoTaggingScope)) {
			const article = Article.builder(this.props.article).withAutoTaggingScope(this.props.autoTaggingScope).build();

			this.props.updateTempArticle(article);
		}
	}

	setActiveTab = (tab: string) => {
		this.setState({ activeTab: tab });
	};

	handleToggleVersion = (tabId: string, enabled: boolean) => {
		this.setState((prevState) => {
			const updatedVersions = prevState.metadataVersions.map((version) => (version.id === tabId ? { ...version, enabled: enabled } : version));
			const updatedArticle = Article.builder(this.props.article).withVersions(updatedVersions).build();
			this.props.updateTempArticle(updatedArticle);

			return {
				metadataVersions: updatedVersions,
			};
		});
	};

	handleCopyFromDefault = (tabId: string) => {
		const { article } = this.props;
		const defaultVersion = {
			title: article.title || '',
			strapline: article.strapline || '',
			description: article.subtitle || '',
		};

		this.setState((prevState) => {
			const updatedVersions = prevState.metadataVersions.map((version) =>
				version.id === tabId
					? { ...version, title: defaultVersion.title, strapline: defaultVersion.strapline, description: defaultVersion.description }
					: version,
			);

			const updatedArticle = Article.builder(this.props.article).withVersions(updatedVersions).build();
			this.props.updateTempArticle(updatedArticle);

			return {
				metadataVersions: updatedVersions,
			};
		});
	};

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.article.body);
		this.setState(totalWordCharCount);
	}

	// TODO Move this logic to a store variable in a given reducer ( in this case article-temp.reducer)
	displayClearTempArticleModal() {
		if (
			(!Article.builder(this.props.article).isEmpty() ||
				(this.props.relatedContent && this.props.relatedContent.length > 0) ||
				(this.props.relatedTags && this.props.relatedTags.length > 0) ||
				(this.props.relatedMatches && Object.entries(this.props.relatedMatches).length > 0) ||
				(this.props.relatedSports && this.props.relatedSports.length > 0)) &&
			this.props.article.id !== 'temp-copy-id'
		) {
			this.toggleModal(true);
		}
	}

	initPageTitle() {
		document.title = this.props.t('article_create');
	}

	initArticleCreateSuccessListener() {
		createListenerForContentCreateRedirect(this.props.history, ARTICLE_CREATE_RESOURCES_SUCCESS, 'articles');
	}

	updateMetaDataTabValues(field: 'title' | 'strapline' | 'description', value: string) {
		const { activeTab, metadataVersions } = this.state;

		const updatedMetadataVersions = metadataVersions.map((version) => (version.id === activeTab ? { ...version, [field]: value } : version));

		const filteredVersions = updatedMetadataVersions.filter((version) => {
			return version.title || version.strapline || version.description;
		});

		const versionsForArticle = filteredVersions.map((version) => {
			const versionModel = new VersionModel();
			versionModel.id = version.id;
			versionModel.title = version.title || '';
			versionModel.strapline = version.strapline || '';
			versionModel.description = version.description || '';
			versionModel.enabled = version.enabled;
			return versionModel;
		});

		const article = Article.builder(this.props.article).withVersions(versionsForArticle).build();
		this.props.updateTempArticle(article);
		this.setState({ metadataVersions: updatedMetadataVersions });
	}

	onTitleChange(title: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const seo = SeoModel.builder(this.props.article.seo).withAutoUpdateSeo(title).build();
				const article = Article.builder(this.props.article).withTitle(title).withSeo(seo).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('title', title);
			}
		} else {
			const seo = SeoModel.builder(this.props.article.seo).withAutoUpdateSeo(title).build();
			const article = Article.builder(this.props.article).withTitle(title).withSeo(seo).build();
			this.props.updateTempArticle(article);
		}
	}

	onStrapLineChange(strapline: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const article = Article.builder(this.props.article).withStrapline(strapline).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('strapline', strapline);
			}
		} else {
			const article = Article.builder(this.props.article).withStrapline(strapline).build();
			this.props.updateTempArticle(article);
		}
	}

	onSummaryChange(summary: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const article = Article.builder(this.props.article).withSubtitle(summary).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('description', summary);
			}
		} else {
			const article = Article.builder(this.props.article).withSubtitle(summary).build();
			this.props.updateTempArticle(article);
		}
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let article = Article.builder(this.props.article).withMainMedia(mainMedia).build();
		this.props.updateTempArticle(article);
	}

	onBodyChange = (blocks: any) => {
		const article = Article.builder(this.props.article).withBody(blocks).build();
		this.props.updateTempArticle(article);
	};

	toggleModal(display: boolean) {
		this.setState({
			...this.state,
			toggleDeleteModal: display,
		});
	}

	postArticlePreview() {
		const { project, article } = this.props;
		const relatedList = this.mergeRelatedFromProps();
		const relatedPost = ModelMapper.remapRelatedListToRelatedJsonList(relatedList.slice());
		const articleRequest = Article.builder(article).toRequestJson();
		const headers = { Project: project.domain };

		HttpService.post('/articles/preview', articleRequest, headers)
			.then((response: any) => {
				previewFormPost(response.data.data, project.previewUrl, relatedPost);
			})
			.catch((error: any) => actionService.emitError(error));
	}

	mergeRelatedFromProps() {
		let { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;
		relatedContent = relatedContent ? relatedContent : [];
		relatedMatches = relatedMatches ? relatedMatches : [];
		relatedSports = relatedSports ? relatedSports : [];
		relatedTags = relatedTags ? relatedTags : [];

		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onSubmit() {
		const { article } = this.props;
		const relatedList = this.mergeRelatedFromProps();

		if (article.mainMedia && article.mainMedia[0] && article.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(article.mainMedia, this.props.project);
		}

		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			if (this.contentValidation.validateByContentType(this.props.article, ContentTypes.ARTICLE)) {
				this.checkForWatermarkChecked(relatedList);
				this.updateMandatoryFieldsFilled(true);
			} else {
				this.updateMandatoryFieldsFilled(false);
			}
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.article.mainMedia &&
			this.props.article.mainMedia[0] &&
			this.props.article.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.article.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.postArticle(this.props.article, JSON.parse(JSON.stringify(relatedList)), this.props.project, this.props.list);
			try {
				this.props.article.body && this.props.article.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.article.body]);
			} catch {
				this.props.initializeBlockyUndoRedo(createStartingBlocky());
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	displayPreviewButton(size: string) {
		const { t } = this.props;
		const { previewUrl } = this.props.project;

		if (previewUrl && previewUrl.length > 0) {
			return (
				<Button
					size={size}
					color='info'
					className={`font-weight-bold text-white ${size !== 'sm' && 'text-uppercase'}`}
					onClick={() => this.postArticlePreview()}
				>
					<i className='fa fa-floppy-o'></i> {t('preview')}
				</Button>
			);
		}
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.ARTICLE);
		this.setState(newState);
	};

	render() {
		const { t, project, article } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag, metadataVersions, activeTab } = this.state;

		const defaultContent = {
			id: 'default',
			title: article.title,
			strapline: article.strapline,
			description: article.subtitle,
			onTitleChange: this.onTitleChange.bind(this),
			onStrapLineChange: this.onStrapLineChange.bind(this),
			onSummaryChange: this.onSummaryChange.bind(this),
			totalWordCount: this.state.totalWordCount,
			totalCharCount: this.state.totalCharCount,
			StrapLine: StrapLine,
			Title: Title,
			WordCountComponent: WordCountComponent,
			Summary: Summary,
		};

		const tabs = metadataVersions.map((version) => ({
			id: version.id,
			name: version.name,
			content: {
				id: version.id,
				title: version.title || '',
				strapline: version.strapline || '',
				description: version.description || '',
				onTitleChange: this.onTitleChange.bind(this),
				onStrapLineChange: this.onStrapLineChange.bind(this),
				onSummaryChange: this.onSummaryChange.bind(this),
				totalWordCount: this.state.totalWordCount,
				totalCharCount: this.state.totalCharCount,
				StrapLine: StrapLine,
				Title: Title,
				WordCountComponent: WordCountComponent,
				Summary: Summary,
				enabled: version.enabled,
			},
		}));

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
					onChange={(mainMediaList) => this.onMainMediaChange(mainMediaList)}
					value={article.mainMedia}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			urls: <SidebarURLs t={t} />,
			seo: <SidebarSEO t={t} />,
			list: <SidebarList />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [ARTICLE_ENTITY_CREATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [ARTICLE_ENTITY_CREATE_SUCCESS, ARTICLE_ENTITY_CREATE_FAILED, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILED];

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card modified-card-container'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && (
										<div className='mr-2 d-flex'>
											<Flag
												code={article && article.language === 'en' ? 'gb' : article && article.language}
												width='20'
												fallback={<i className='fa fa-flag ml-2' />}
											/>
										</div>
									)}
									<span className={'mr-auto mb-0'}>{t('new_article')}</span>
									<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
										<Button size='sm' color='primary' id='article-create-save-top' className={'ml-auto mr-2'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_article')}
										</Button>
									</BlockableButtonWrapper>
									{this.displayPreviewButton('sm')}
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA) &&
										metadataVersions &&
										metadataVersions.length > 0 ? (
											<CustomTabs
												tabs={tabs}
												defaultContent={defaultContent}
												StrapLine={StrapLine}
												Title={Title}
												WordCountComponent={WordCountComponent}
												Summary={Summary}
												setActiveTab={this.setActiveTab.bind(this)}
												activeTab={activeTab}
												onToggleVersion={this.handleToggleVersion}
												onCopyFromDefault={this.handleCopyFromDefault}
											/>
										) : (
											<div>
												<Row>
													<Col xs='12'>
														<Label htmlFor='title'>{t('title')}</Label>
														<Row>
															<Col>
																<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={this.props.article.strapline} />
															</Col>
															<Col>
																<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
															</Col>
														</Row>
														<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.props.article.title} dataQaProperty={'default-title'} />
													</Col>
												</Row>
												<Row>
													<Col xs='12'>
														<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={this.props.article.subtitle} />
													</Col>
												</Row>
											</div>
										)}
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<Col xs='12'>
										<FormGroup>
											<Label htmlFor='summary'>{t('body')}</Label>
											<Blocky
												t={t}
												contentType={ContentTypes.ARTICLE}
												contentLanguage={article.language}
												entityType={
													multiLingualService.checkIfProjectIsMultiLingual(project.languages) && article.translationGroup
														? article.translationGroup.contentType
														: ContentTypes.ARTICLE
												}
												blocks={article.body ? article.body : []}
												onChange={this.onBodyChange}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<Col>
										<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
											<Button
												color={'primary'}
												id='article-create-save-bottom'
												className={'text-uppercase font-weight-bold mr-2'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_article')}
											</Button>
										</BlockableButtonWrapper>
										{this.displayPreviewButton('')}
									</Col>
									<Col>
										<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
									</Col>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.ARTICLE)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.ARTICLE}
							sidebarComponentsMap={sidebarComponentsMap}
							contentMode={ContentMode.CREATE}
							t={t}
						/>
					</Col>
				</Row>
				<Modal isOpen={this.state.toggleDeleteModal} className={'modal-info'}>
					<ModalHeader>{t('draft_detected_title')}</ModalHeader>
					<ModalBody>
						{t('draft_detected_body')}{' '}
						{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && article.translationGroup && !article.translationGroup.id ? (
							<div>
								{t('draft_lang')}
								<strong>{t(article.language)}</strong>.
							</div>
						) : (
							''
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							className={'mr-auto'}
							onClick={() => {
								this.props.clearTempArticle();
								this.props.clearSuggestedEntities();

								if (this.props.project.languages.defaultLanguageCode.languageCode) {
									const article = createStartingEntity(ContentTypes.ARTICLE) as Article;

									this.props.setAutoTaggingScope({});

									article && this.props.updateTempArticle(article);
									article && this.props.initializeBlockyUndoRedo([...article.body]);
								}

								this.props.draftDeleted();
								this.toggleModal(false);
							}}
						>
							{t('draft_detected_delete')}
						</Button>{' '}
						<Button
							color='info'
							onClick={() => {
								this.toggleModal(false);
							}}
							className={'text-light'}
						>
							{t('draft_detected_continue')}
						</Button>
					</ModalFooter>
				</Modal>
				<EditorToolbar
					contentType={ContentTypes.ARTICLE}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
					}}
					onPreview={this.postArticlePreview.bind(this)}
					onCopyToProject={null}
					onAuditLog={null}
					t={t}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.ARTICLE, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.ARTICLE, defaultTempSidebarFromApiResponse[ContentTypes.ARTICLE].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		article: state.tempArticle.article,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.articleRelated,
		relatedSports: state.tempSportsRelated.articleRelated,
		relatedTags: state.tempTagsRelated.articleRelated,
		relatedMatches: state.tempMatchRelated.articleRelated,
		list: state.list.contentCreateList,
		wordCount: state.blocky.wordCount,
		charCount: state.blocky.charCount,
		autoTaggingScope: state.blocky.autoTaggingScope,
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.ARTICLE);
	return {
		updateTempArticle: (article: Article) => dispatch(updateTempArticle(article)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		clearTempArticle: () => dispatch(clearTempArticle()),
		postArticle: (article: Article, related: Related[], project: Project, list: null | List) =>
			dispatch(returnObjectForArticleEntityCreate(article, related, project, list)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateArticleTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateArticleSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateArticleMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateArticleContentTempRelated(related)),
		clearMatchTempRelated: () => dispatch(clearArticleMatchTempRelated()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
		setAutoTaggingScope: (scope: ArticleAutoTaggingScope) => dispatch(setAutoTaggingScope(scope)),
		draftDeleted: () => dispatch(articleDraftClear()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ArticleCreate) as React.ComponentType;
