import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import FootballLineupsModel from '../models/football-lineups.model';
import { FootballLineupsViewComponent } from './football-lineups-view.component';
import FootballLineupsEditComponent from './football-lineups-edit.component';
import { BlockyV2Properties } from '../../../utils/helper';

const FootballLineupsWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	if (view === ViewTypes.edit) {
		return <FootballLineupsEditComponent block={block} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballLineupsViewComponent
				preview={block.data.preview && block.data.preview.widgetModel ? block.data.preview.widgetModel : FootballLineupsModel.builder().build()}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballLineupsWidget);
