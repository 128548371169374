import React from 'react';
import { Col, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import moment from 'moment';
import FootballMatchCenterModel from '../models/football-match-center.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { useTranslation } from 'react-i18next';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';

type Properties = {
	matchPreview: FootballMatchCenterModel;
	oddsPreview: OddsModelV2;
};

export const FootballMatchCenterViewComponent: React.FunctionComponent<Properties> = ({ matchPreview, oddsPreview }) => {
	const { t } = useTranslation();
	if (matchPreview.match && matchPreview.match.id) {
		const homeTeamName = matchPreview.match.homeTeam.name ? matchPreview.match.homeTeam.name : '';
		const awayTeamName = matchPreview.match.awayTeam.name ? matchPreview.match.awayTeam.name : '';

		return (
			<div data-qa='football-match-center-view'>
				<Row className={'p-4 text-center'}>
					<Col className='text-center'>
						<>
							<Row className='mb-1'>
								<Col>
									<strong>{moment(matchPreview.match.startTime ? matchPreview.match.startTime : '').format('MMM Do YYYY, HH:mm')}</strong>
								</Col>
							</Row>
							<Row className='mb-1 align-items-center'>
								<Col className='text-right'>
									<strong className='p-1'>{homeTeamName}</strong>
									<img
										height='40'
										alt={matchPreview.match.homeTeam ? matchPreview.match.homeTeam.name : ''}
										src={matchPreview.match.homeTeam.urlLogo ? matchPreview.match.homeTeam.urlLogo : assetsPlaceholder.team}
									/>
								</Col>
								<strong>:</strong>
								<Col className='text-left'>
									<img
										height='40'
										alt={matchPreview.match.awayTeam ? matchPreview.match.awayTeam.name : ''}
										src={matchPreview.match.awayTeam.urlLogo ? matchPreview.match.awayTeam.urlLogo : assetsPlaceholder.team}
									/>
									<strong className='p-1'>{awayTeamName}</strong>
								</Col>
							</Row>
						</>
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={matchPreview.displayOdds ? 'text-success' : 'text-danger'}>
										{matchPreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						{matchPreview.displayOdds && oddsPreview.bookmakers && oddsPreview.bookmakers.length > 0 && (
							<Row>
								<Col className='text-left'>
									{t('show')}:
									{oddsPreview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
								</Col>
							</Row>
						)}
						{matchPreview.displayOdds && oddsPreview.market && oddsPreview.market.name && oddsPreview.market.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(oddsPreview.market.name)}</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(matchPreview.refreshTime)}
			</div>
		);
	}

	return null;
};
