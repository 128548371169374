import React, { FunctionComponent, ChangeEvent, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { slugifyString } from '../../helpers/slug.helper';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';

type Properties = {
	t: any;
	seoSlug: string;
	seoTitle: string;
	isDisabled: boolean;
	onSeoChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const SeoSlugInput: FunctionComponent<Properties> = ({ t, seoSlug, seoTitle, isDisabled, onSeoChange }) => {
	const [slugValue, setSlugValue] = useState(seoSlug);

	const onSeoSlugChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSlugValue(event.target.value);
	};

	const onBlurChange = () => {
		onSeoChange(slugifyString(slugValue), ReduxURLsProps.SLUG);
	};

	useEffect(() => {
		isDisabled && onSeoChange(slugifyString(seoTitle), ReduxURLsProps.SLUG);
	}, [isDisabled, seoTitle]);

	useEffect(() => {
		setSlugValue(seoSlug);
	}, [seoSlug]);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.SLUG}>{t('slug')}</Label>
					<Input
						id={DATA_QA_ATTRIBUTES.SLUG}
						disabled={isDisabled}
						type='text'
						className='form-control'
						placeholder={t('slug')}
						value={slugValue}
						onChange={onSeoSlugChange}
						onBlur={onBlurChange}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoTitle:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.title) || '',
		seoSlug: (state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.slug) || '',
	};
}

export default connect(mapStateToProps)(SeoSlugInput);
