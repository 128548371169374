import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import FootballMatchCenterModel from '../models/football-match-center.model';
import { FootballMatchCenterViewComponent } from './football-match-center-view.component';
import FootballMatchCenterEditComponent from './football-match-center-edit.component';
import { BlockyV2Properties } from '../../../utils/helper';
import OddsWidgetModel from '../../../../blocks/widgets/odds/models/odds-widget.model';

const FootballMatchCenterWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;
	if (view === ViewTypes.edit) {
		return <FootballMatchCenterEditComponent t={t} block={block} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballMatchCenterViewComponent
				matchPreview={block.data.preview && block.data.preview.match ? block.data.preview.match : FootballMatchCenterModel.builder().build()}
				oddsPreview={block.data.preview && block.data.preview.odds ? block.data.preview.odds : OddsWidgetModel.builder().build()}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballMatchCenterWidget);
