import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import SearchDateDesign from '../../../../Partials/design-components/search-date/search-date';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../Partials/design-components/select/select';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import MultiSportCompetitionListSelect from '../../../../Partials/design-components/select/multisport-competition-list-select';

type Props = {
	selectConfigureData: (date: Date | null, competitionList: SelectOption) => void;
	date: Date;
	selectedCompetitionList: SelectOption;
};

const ConfigureDataSection: FunctionComponent<Props> = ({ selectConfigureData, date, selectedCompetitionList }) => {
	const { t } = useTranslation();
	const featureConfigData: Record<string, any> = featuresService.checkFeatureDataField(FeatureTypes.MULTISPORT_WIDGET_CONFIGURATION);
	const competitionListOptionsFromFeature: string[] = featureConfigData.competitionList || [];
	const mappedCompetitionListData: SelectOption[] = Array.isArray(competitionListOptionsFromFeature)
		? competitionListOptionsFromFeature.map((item: Record<string, any>) => {
				return { name: item.label, value: item.code };
		  })
		: [];

	return (
		<Row id={DATA_QA.CONFIGURE_DATE_WRAPPER}>
			<Col xs={12} className='d-flex flex-column align-items-start mb-4'>
				<h3>{t('configure_for_date')}</h3>
				<span>{t('configure_for_date_sub')}</span>
			</Col>
			<Row id={DATA_QA.CONFIGURE_COMPETITION_LIST_WRAPPER}>
				<Col style={{ flexGrow: 0 }}>
					<MultiSportCompetitionListSelect
						invokeFunc={selectConfigureData}
						fieldId='competition-list-select'
						labelText={t('configuration_name')}
						placeholderText={t('configuration_name_placeholder')}
						options={mappedCompetitionListData}
						selectedValue={selectedCompetitionList.value}
						date={date}
					/>
				</Col>
				<Col style={{ flexGrow: 0 }}>
					<SearchDateDesign
						invokeFunc={selectConfigureData}
						fieldId='search-config-date'
						date={date}
						labelText={t('date')}
						className={'custom-search-date-wrapper-with-competition-list'}
						selectedCompetitionList={selectedCompetitionList}
					/>
				</Col>
			</Row>
		</Row>
	);
};

export default ConfigureDataSection;
