import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/ArticleEditProperties';
import SeoModel from '../../../models/seo/Seo';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { StrapLine } from '../../Partials/Fields/strap-line/StrapLineComponent';
import { Summary } from '../../Partials/Fields/summary/SummaryComponent';
import Article from '../../../models/article/Article';
import {
	ARTICLE_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	updateTempEditArticle,
} from '../../../store/action-creators/ArticleTempActionCreator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Related from '../../../models/related/Related';
import MainMedia from '../../../models/mainmedia/MainMedia';
import {
	clearArticleMatchTempRelated,
	updateArticleEditMatchTempRelated,
} from '../../../store/action-creators/RelatedMatchTempActionCreator';
import { clearArticleTagTempRelated, updateEditArticleTagTempRelated } from '../../../store/action-creators/RelatedTagsTempActionCreator';
import {
	clearArticleSportsTempRelated,
	updateArticleEditSportsTempRelated,
} from '../../../store/action-creators/RelatedSportsTempActionCreator';
import {
	clearArticleContentTempRelated,
	updateArticleEditContentTempRelated,
} from '../../../store/action-creators/RelatedContentTempActionCreator';
import Project from '../../../models/project/Project';
import {
	ARTICLE_ENTITY_RECEIVED,
	ARTICLE_ENTITY_REQUEST,
	ARTICLE_ENTITY_UPDATE,
	ARTICLE_ENTITY_UPDATE_FAILED,
	ARTICLE_ENTITY_UPDATE_SUCCESS,
	returnObjectForArticleLEntityRequest,
	returnObjectForArticleLEntityUpdate,
} from '../../../store/action-creators/ArticleActionCreator';
import { allowRefresh, preventRefresh } from '../../../global/DomFunctions';
import HttpService from '../../../services/rest/HttpService';
import { previewFormPost } from './Helpers/ArticlePreviewHelper';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentTypes } from '../../../constants/content-types';
import { actionService, auditLogService, featuresService, multiLingualService, wordCountService } from '../../../App';
import Blocky from '../../Partials/Blocky/subcomponents/blocky.component';
import { Subscription } from 'rxjs';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import {
	IMAGE_MEDIA_ADD,
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../store/action-creators/ImageActionCreator';
import {
	initializeBlockyUndoRedo,
	resetAutolinking,
	resetUndoRedoBlocky,
	setAutoTaggingScope,
} from '../../../store/action-creators/BlockyActionCreator';
import ModelMapper from '../../../models/ModelMapper';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import WordCountComponent from '../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { CopyToProjectsModal, DISPLAY_MODAL } from '../../Partials/Modals/CopyToProjects/CopyToProjectsModal';
import { MandatoryFieldsService } from '../../../services/mandatory-fields-service/mandatory-fields.service';
import { TranslationGroupsContainer } from '../../Partials/Sidebar/translation-groups/translation-groups-container.component';
import Flag from 'react-world-flags';
import { AuditLogContainer, DISPLAY_AUDIT } from '../audit-log/audit-log.container';
import OneSignalModal from '../../Partials/Modals/OneSignal/OneSignalModal';
import BlockModel from '../../Partials/Blocky/models/block.model';
import EditorToolbar from '../../Partials/EditorToolbar/EditorToolbar';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarURLs from '../../Partials/Sidebar/urls-refactored/urls.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../global-helpers/global-features.helpers';
import SidebarContentFooter from '../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import SidebarRelatedContent from '../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarList from '../../Partials/Sidebar/list-refactored/list-wrapper';
import SidebarMainMedia from '../../Partials/Sidebar/media-refactored/media-wrapper';
import ArticleLockOverlay from '../../Partials/BaseComponents/ArticleLockOverlay/ArticleLockOverlay';
import SentryService from '../../../services/sentry/sentry';
import _ from 'lodash';
import { ArticleAutoTaggingScope } from '../../../models/article/ArticleJson';
import { getMetadataVersionsList } from '../helpers/resources.helper';
import { VersionModel } from '../../../models/v2/meta-data-versions/versions.model';
import CustomTabs from '../../Partials/BaseComponents/CustomTabsNavigation/custom-tabs-navigation';

class ArticleEdit extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();
	private isArticleLockEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ARTICLE_LOCK);

	constructor(props: any) {
		super(props);
		this.state = {
			isContentLoading: true,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: !!(this.props.article.generic && this.props.article.generic.mainMediaWatermark),
			openSidebarSettingsModalFlag: false,
			hasLockOverlay: false,
			metadataVersions: [],
			activeTab: 'default',
		};
		this.registerActionEventListener();
	}

	componentWillUnmount() {
		this.props.relatedSports && this.props.clearSportsTempRelated();
		this.props.relatedTags && this.props.clearTagTempRelated();
		this.props.relatedMatches && this.props.clearMatchTempRelated();
		this.props.relatedContent && this.props.clearContentTempRelated();
		this.actionServiceSubject.unsubscribe();
		this.props.resetUndoRedoBlocky();
		allowRefresh();

		SentryService.removeContext('Article Edit');
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.article.body);
		this.setState(totalWordCharCount);
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === ARTICLE_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === ARTICLE_ENTITY_RECEIVED) {
				setTimeout(() => {
					this.toggleContentLoadingState(false);
				}, 100);
			}

			if (action === ARTICLE_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING) {
				this.onSubmit();
			}
		});
	}

	componentDidMount(): void {
		this.props.updateMatchTempRelated([]);
		this.props.updateSportsTempRelated([]);
		this.props.resetAutolinking();
		this.initPageTitle();
		this.props.getArticle(this.props.match.params.id, this.props.project);

		if (!this.isArticleLockEnabled) {
			preventRefresh();
		}

		appendBeforeUnloadResetTempSidebar(ContentTypes.ARTICLE);
		try {
			this.props.article.body && this.props.article.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.article.body]);
		} catch {
			this.props.initializeBlockyUndoRedo([]);
		}

		this.props.setAutoTaggingScope(this.props.article.autoTaggingScope || {});

		SentryService.setContext('Article Edit', { id: this.props.match.params.id });

		const metadataVersions = getMetadataVersionsList().map((version) => {
			const matchedVersion = this.props.article.versions && this.props.article.versions.find((v) => v.id === version.id);
			return {
				id: version.id,
				name: version.name,
				title: matchedVersion ? matchedVersion.title : '',
				strapline: matchedVersion ? matchedVersion.strapline : '',
				description: matchedVersion ? matchedVersion.description : '',
				enabled: matchedVersion ? matchedVersion.enabled : false,
			};
		});

		this.setState({ metadataVersions });
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.props.article.generic != prevProps.article.generic) {
			this.setState({ addWatermarkToImageMedia: !!(this.props.article.generic && this.props.article.generic.mainMediaWatermark) });
		}
		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}

		if (this.props.match.params.id !== prevProps.match.params.id) {
			window.scrollTo(0, 0);
			this.toggleContentLoadingState(true);
			this.props.resetAutolinking();
			this.props.getArticle(this.props.match.params.id, this.props.project);
		}

		if (!_.isEqual(prevProps.article.id, this.props.article.id)) {
			this.props.setAutoTaggingScope(this.props.article.autoTaggingScope || {});
		}

		if (!_.isEqual(prevProps.autoTaggingScope, this.props.autoTaggingScope)) {
			const article = Article.builder(this.props.article).withAutoTaggingScope(this.props.autoTaggingScope).build();

			this.props.updateTempArticle(article);
		}

		if (prevProps.article.versions !== this.props.article.versions) {
			const metadataVersions = getMetadataVersionsList().map((version) => {
				const matchedVersion = this.props.article.versions.find((v) => v.id === version.id);
				return {
					id: version.id,
					name: version.name,
					title: matchedVersion ? matchedVersion.title : '',
					strapline: matchedVersion ? matchedVersion.strapline : '',
					description: matchedVersion ? matchedVersion.description : '',
					enabled: matchedVersion ? matchedVersion.enabled : false,
				};
			});

			this.setState({ metadataVersions });
		}
	}

	setActiveTab = (tab: string) => {
		this.setState({ activeTab: tab });
	};

	handleToggleVersion = (tabId: string, enabled: boolean) => {
		this.setState((prevState) => {
			const updatedVersions = prevState.metadataVersions.map((version) => (version.id === tabId ? { ...version, enabled: enabled } : version));
			const updatedArticle = Article.builder(this.props.article).withVersions(updatedVersions).build();
			this.props.updateTempArticle(updatedArticle);

			return {
				metadataVersions: updatedVersions,
			};
		});
	};

	handleCopyFromDefault = (tabId: string) => {
		const { article } = this.props;
		const defaultVersion = {
			title: article.title || '',
			strapline: article.strapline || '',
			description: article.subtitle || '',
		};

		this.setState((prevState) => {
			const updatedVersions = prevState.metadataVersions.map((version) =>
				version.id === tabId
					? { ...version, title: defaultVersion.title, strapline: defaultVersion.strapline, description: defaultVersion.description }
					: version,
			);

			const updatedArticle = Article.builder(this.props.article).withVersions(updatedVersions).build();
			this.props.updateTempArticle(updatedArticle);

			return {
				metadataVersions: updatedVersions,
			};
		});
	};

	updateMetaDataTabValues(field: 'title' | 'strapline' | 'description', value: string) {
		const { activeTab, metadataVersions } = this.state;

		const updatedMetadataVersions = metadataVersions.map((version) => (version.id === activeTab ? { ...version, [field]: value } : version));

		const filteredVersions = updatedMetadataVersions.filter((version) => {
			return version.title || version.strapline || version.description;
		});

		const versionsForArticle = filteredVersions.map((version) => {
			const versionModel = new VersionModel();
			versionModel.id = version.id;
			versionModel.title = version.title || '';
			versionModel.strapline = version.strapline || '';
			versionModel.description = version.description || '';
			versionModel.enabled = version.enabled;
			return versionModel;
		});

		const article = Article.builder(this.props.article).withVersions(versionsForArticle).build();
		this.props.updateTempArticle(article);
		this.setState({ metadataVersions: updatedMetadataVersions });
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	initPageTitle() {
		document.title = this.props.t('article_edit');
	}

	onTitleChange(title: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const seo = SeoModel.builder(this.props.article.seo).withAutoUpdateSeo(title).build();
				const article = Article.builder(this.props.article).withTitle(title).withSeo(seo).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('title', title);
			}
		} else {
			const seo = SeoModel.builder(this.props.article.seo).withAutoUpdateSeo(title).build();
			const article = Article.builder(this.props.article).withTitle(title).withSeo(seo).build();
			this.props.updateTempArticle(article);
		}
	}

	onStrapLineChange(strapline: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const article = Article.builder(this.props.article).withStrapline(strapline).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('strapline', strapline);
			}
		} else {
			const article = Article.builder(this.props.article).withStrapline(strapline).build();
			this.props.updateTempArticle(article);
		}
	}

	onSummaryChange(summary: string) {
		const { activeTab, metadataVersions } = this.state;

		if (metadataVersions.length > 0 && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
			if (activeTab === 'default') {
				const article = Article.builder(this.props.article).withSubtitle(summary).build();
				this.props.updateTempArticle(article);
			} else {
				this.updateMetaDataTabValues('description', summary);
			}
		} else {
			const article = Article.builder(this.props.article).withSubtitle(summary).build();
			this.props.updateTempArticle(article);
		}
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let article = Article.builder(this.props.article).withMainMedia(mainMedia).build();
		this.props.updateTempArticle(article);
	}

	onSubmit() {
		const relatedList = this.mergeRelatedFromProps();
		if (this.props.article.mainMedia && this.props.article.mainMedia[0] && this.props.article.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(this.props.article.mainMedia, this.props.project);
		}
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			this.contentValidation.validateByContentType(this.props.article, ContentTypes.ARTICLE)
				? this.checkForWatermarkChecked(relatedList)
				: this.updateMandatoryFieldsFilled(false);
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.article.mainMedia &&
			this.props.article.mainMedia[0] &&
			this.props.article.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.article.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.updateArticle(this.props.article, JSON.parse(JSON.stringify(relatedList)), this.props.project);
			try {
				this.props.article.body && this.props.article.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.article.body]);
			} catch {
				this.props.initializeBlockyUndoRedo([]);
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	mergeRelatedFromProps() {
		const { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;

		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onBodyChange = (blocks: any) => {
		const article = Article.builder(this.props.article).withBody(blocks).build();
		this.props.updateTempArticle(article);
	};

	postArticlePreview() {
		const { project, article } = this.props;
		const relatedList = this.mergeRelatedFromProps();
		const relatedPost = ModelMapper.remapRelatedListToRelatedJsonList(relatedList.slice());
		const articleRequest = Article.builder(article).toRequestJson();
		const headers = { Project: project.domain };

		HttpService.post('/articles/preview', articleRequest, headers)
			.then((response: any) => {
				previewFormPost(response.data.data, project.previewUrl, relatedPost);
			})
			.catch((error: any) => actionService.emitError(error));
	}

	copyToProject() {
		actionService.emitUiAction({
			type: DISPLAY_MODAL,
			data: {
				contentTitle: this.props.article.title,
				contentId: this.props.article.id,
				contentType: ContentTypes.ARTICLE,
			},
		});
	}

	displayAuditLog = () => {
		actionService.emitUiAction({
			type: DISPLAY_AUDIT,
			data: {
				profileId: this.props.profile.id,
				contentId: this.props.article.id,
				contentTitle: this.props.article.title,
				contentType: ContentTypes.ARTICLE,
			},
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.ARTICLE);
		this.setState(newState);
	};

	displayPreviewButton(size: string) {
		const { t } = this.props;
		const { previewUrl } = this.props.project;

		if (previewUrl && previewUrl.length > 0) {
			return (
				<Button
					size={size}
					color='info'
					className={`font-weight-bold text-nowrap text-white ${size !== 'sm' && 'text-uppercase'}`}
					onClick={() => this.postArticlePreview()}
				>
					<i className='fa fa-floppy-o'></i> {t('preview')}
				</Button>
			);
		}
	}

	render() {
		const { t, article, project } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag, hasLockOverlay, metadataVersions, activeTab } = this.state;

		const defaultContent = {
			id: 'default',
			title: article.title,
			strapline: article.strapline,
			description: article.subtitle,
			onTitleChange: this.onTitleChange.bind(this),
			onStrapLineChange: this.onStrapLineChange.bind(this),
			onSummaryChange: this.onSummaryChange.bind(this),
			totalWordCount: this.state.totalWordCount,
			totalCharCount: this.state.totalCharCount,
			StrapLine: StrapLine,
			Title: Title,
			WordCountComponent: WordCountComponent,
			Summary: Summary,
		};

		const tabs = metadataVersions.map((version) => ({
			id: version.id,
			name: version.name,
			content: {
				id: version.id,
				title: version.title || '',
				strapline: version.strapline || '',
				description: version.description || '',
				onTitleChange: this.onTitleChange.bind(this),
				onStrapLineChange: this.onStrapLineChange.bind(this),
				onSummaryChange: this.onSummaryChange.bind(this),
				totalWordCount: this.state.totalWordCount,
				totalCharCount: this.state.totalCharCount,
				StrapLine: StrapLine,
				Title: Title,
				WordCountComponent: WordCountComponent,
				Summary: Summary,
				enabled: version.enabled,
			},
		}));

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			translations: (
				<TranslationGroupsContainer
					t={t}
					currentContentTranslations={article.translationGroup}
					contentType={'article'}
					content={article}
					analytics={this.props.analytics}
				/>
			),
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
					onChange={(mainMediaList) => this.onMainMediaChange(mainMediaList)}
					value={article.mainMedia}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			urls: <SidebarURLs t={t} />,
			seo: <SidebarSEO t={t} />,
			list: <SidebarList />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [ARTICLE_ENTITY_UPDATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [
			ARTICLE_ENTITY_UPDATE_SUCCESS,
			ARTICLE_ENTITY_UPDATE_FAILED,
			IMAGE_UPLOAD_SUCCESS,
			IMAGE_UPLOAD_FAILED,
			IMAGE_MEDIA_ADD,
		];

		return (
			<>
				{this.isArticleLockEnabled && (
					<ArticleLockOverlay
						history={this.props.history}
						refreshArticle={() => {
							this.props.getArticle(this.props.match.params.id, this.props.project);
						}}
						onChange={(applyOverlay) => {
							this.setState({ ...this.state, hasLockOverlay: applyOverlay });
						}}
					/>
				)}
				<div
					className={`article-edit-wrapper animated fadeIn ${this.props.reduxLoadingState && 'loading-overlay'} ${
						hasLockOverlay ? 'with-lock-overlay' : ''
					}`}
				>
					<Row>
						<Col col='8' lg='8' md='12' sm='12' xs='12'>
							<div className='card modified-card-container'>
								<div className='card-header'>
									<div className='row d-flex align-items-center'>
										{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && Object.entries(article).length > 0 && article.language && (
											<div className='col mr-2 text-truncate d-flex'>
												<Flag code={article.language === 'en' ? 'gb' : article.language} width='20' fallback={<i className='fa fa-flag ml-2' />} />
												<label className='mb-0 text-truncate ml-2'>
													{t('edit')}: {article ? article.title : ''}
												</label>
											</div>
										)}
										<div className='py-1 col d-flex'>
											<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
												<Button
													size='sm'
													color='primary'
													id='article-edit-save-top'
													className='ml-auto mr-2 text-nowrap'
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_article')}
												</Button>
											</BlockableButtonWrapper>
											{this.displayPreviewButton('sm')}
											<Button size='sm' color='success' className='ml-2 text-nowrap' onClick={this.copyToProject.bind(this)}>
												<i className={'fa fa-copy text-white'} />
												{t('copy_to_project')}
											</Button>
											{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ONESIGNAL_INTEGRATION) && (
												<OneSignalModal contentType={ContentTypes.ARTICLE} t={t} content={article} />
											)}
											{auditLogService.isFeatureEnabled(featuresService, this.props.profile.id) && (
												<Button size='sm' color='secondary' className='ml-2 text-nowrap' onClick={this.displayAuditLog}>
													<i className={'fa fa-clock-rotate-left text-white'} />
												</Button>
											)}
										</div>
									</div>
								</div>
								<AuditLogContainer t={this.props.t} />

								<div className='card-body'>
									<div>
										{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA) &&
										metadataVersions &&
										metadataVersions.length > 0 ? (
											<CustomTabs
												tabs={tabs}
												defaultContent={defaultContent}
												StrapLine={StrapLine}
												Title={Title}
												WordCountComponent={WordCountComponent}
												Summary={Summary}
												setActiveTab={this.setActiveTab.bind(this)}
												activeTab={activeTab}
												onToggleVersion={this.handleToggleVersion}
												onCopyFromDefault={this.handleCopyFromDefault}
											/>
										) : (
											<div>
												<Row>
													<Col xs='12'>
														<Label htmlFor='title'>{t('title')}</Label>
														<Row>
															<Col>
																<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={this.props.article.strapline} />
															</Col>
															<Col>
																<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
															</Col>
														</Row>
														<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.props.article.title} dataQaProperty={'default-title'} />
													</Col>
												</Row>
												<Row>
													<Col xs='12'>
														<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={this.props.article.subtitle} />
													</Col>
												</Row>
											</div>
										)}
										<Row className={'mb-3'}>
											<Col xs='12'>
												<FormGroup>
													<Label htmlFor='summary'>{t('body')}</Label>
													<Blocky
														t={t}
														contentType={ContentTypes.ARTICLE}
														contentLanguage={article.language}
														entityType={
															multiLingualService.checkIfProjectIsMultiLingual(project.languages) &&
															article.translationGroup &&
															article.translationGroup.contentType
														}
														blocks={article.body ? article.body : []}
														onChange={this.onBodyChange}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
													<Button
														color={'primary'}
														id='article-edit-save-bottom'
														className={'text-uppercase font-weight-bold mr-2'}
														onClick={this.onSubmit.bind(this)}
													>
														<i className='fa fa-floppy-o'></i> {t('save_article')}
													</Button>
												</BlockableButtonWrapper>
												{this.displayPreviewButton('')}
											</Col>
											<Col>
												<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Col>
						{!this.state.isContentLoading && (
							<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
								<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
								<SidebarCollapsibleElements
									isSidebarInEdit={isSidebarInEdit}
									onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.ARTICLE)}
									onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
									contentType={ContentTypes.ARTICLE}
									sidebarComponentsMap={sidebarComponentsMap}
									t={t}
									currentProject={project}
								/>
							</Col>
						)}
					</Row>
					<CopyToProjectsModal t={this.props.t} />
					<EditorToolbar
						contentType={ContentTypes.ARTICLE}
						onChange={this.onBodyChange}
						save={{
							invokeFunction: this.onSubmit.bind(this),
							blockOnActions: saveBlockOnActions,
							releaseOnActions: saveReleaseOnActions,
							blockUntilCorrectContentIsLoaded: !article.id,
						}}
						onPreview={this.postArticlePreview.bind(this)}
						onCopyToProject={this.copyToProject.bind(this)}
						onAuditLog={this.displayAuditLog}
						content={this.props.article}
						t={t}
						showNotifyUsers
					/>
					<GlobalModal
						isOpen={openSidebarSettingsModalFlag}
						t={this.props.t}
						headerContent='save_sidebar_settings'
						bodyContent='unsaved_changes'
						submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.ARTICLE, t)}
						beforeCloseFunction={() =>
							overwriteTempSidebarByContentType(ContentTypes.ARTICLE, defaultTempSidebarFromApiResponse[ContentTypes.ARTICLE].value)
						}
					/>
				</div>
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		article: state.tempArticle.articleEdit,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.articleEditRelated,
		relatedSports: state.tempSportsRelated.articleEditRelated,
		relatedTags: state.tempTagsRelated.articleEditRelated,
		relatedMatches: state.tempMatchRelated.articleEditRelated,
		reduxLoadingState: state.ui.contentLoadingState[ContentTypes.ARTICLE],
		autoTaggingScope: state.blocky.autoTaggingScope,
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.ARTICLE);
	return {
		updateArticle: (article: Article, related: Related[], project: Project) =>
			dispatch(returnObjectForArticleLEntityUpdate(article, related, project)),
		updateTempArticle: (article: Article) => dispatch(updateTempEditArticle(article)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateEditArticleTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateArticleEditSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateArticleEditMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateArticleEditContentTempRelated(related)),
		getArticle: (id: string, project: Project) => dispatch(returnObjectForArticleLEntityRequest(id, project)),
		clearMatchTempRelated: () => dispatch(clearArticleMatchTempRelated()),
		clearTagTempRelated: () => dispatch(clearArticleTagTempRelated()),
		clearContentTempRelated: () => dispatch(clearArticleContentTempRelated()),
		clearSportsTempRelated: () => dispatch(clearArticleSportsTempRelated()),
		resetAutolinking: () => dispatch(resetAutolinking()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
		setAutoTaggingScope: (scope: ArticleAutoTaggingScope) => dispatch(setAutoTaggingScope(scope)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(ArticleEdit) as React.ComponentType;
