import i18next from 'i18next';
import React from 'react';
import { LiveBlogTypes } from '../../helpers/utility.helper';
import { Stepper } from './stepper.helper';

type Properties = {
	activeStep: any;
	type: string;
	redirect: (pane: any, step: any) => void;
};

export const StepperComponent: React.FunctionComponent<Properties> = ({ type, activeStep, redirect }) => {
	const matchSteps = [i18next.t('event_details'), i18next.t('sport_configuration'), i18next.t('additional_settings')];
	const standardSteps = matchSteps; // For the time being, 'Sport configuration' is also visible in the standard (news) live blog

	return (
		<div>
			<Stepper type={type} redirect={redirect} steps={type === LiveBlogTypes.MATCH ? matchSteps : standardSteps} activeStep={activeStep} />
		</div>
	);
};
