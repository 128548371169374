import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockModel from '../../../models/block.model';
import SportOddsWidgetModel from '../../../blocks/widgets/sports-odds/models/sport-odds-widget.model';
import BlockValidation from '../../../helpers/block-validation.helper';
import { featuresService } from '../../../../../../App';
import GameModel from '../../../../../../models/v2/game/game.model';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import GamesContainer from '../../../partials/games/games-container';
import SportOddsWidgetEdit from '../../../../../../views/Partials/Blocky/blocks/widgets/sports-odds/components/sport-odds-widget-edit.component';
import TennisSingleEventModel from './models/tennis-single-event.model';
import { SportTypes } from '../../../../../../constants/sport.types';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import SportsEntitySelect from '../../../partials/sports-team-select/sports-entity-select.component';
import { ContentTypes } from '../../../../../../constants/content-types';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../partials/refresh-time-input/refresh-time-wrapper';
import { EventStatuses } from '../../../../../../constants/event.types';
import { DisplayOddsCheckbox } from '../odds/components/display-odds-checkbox.component';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: TennisSingleEventModel;
	isValid: boolean;
	oddsModel: SportOddsWidgetModel;
};

export default class TennisSingleEventWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetTennisSingleEvent,
				props.block.data.preview && props.block.data.preview.match,
				props.contentData,
			),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? SportOddsWidgetModel.builder(props.block.data.preview.odds).build()
					: SportOddsWidgetModel.builder().withBookmakers(null).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds &&
			this.state.widgetModel.players.length < 1
		) {
			const emptyOddsModel = SportOddsWidgetModel.builder().withBookmakers(null).build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const data = { matchModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = SportOddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { match: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TennisSingleEventModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	updateWidgetOddsModelState = (model: SportOddsWidgetModel) => {
		this.setState({ ...this.state, oddsModel: model });
	};

	onPlayerChange = (players: PlayerModel[]) => {
		this.updateWidgetModelState(
			TennisSingleEventModel.builder(this.state.widgetModel)
				.withPlayers(players)
				.withMatch({} as GameModel)
				.withDisplayOdds(false)
				.build(),
		);
	};

	onMatchChange = (game: any) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();
		const displayOdds = game && game.statusType !== EventStatuses.FINISHED;
		const widgetModel = TennisSingleEventModel.builder(this.state.widgetModel)
			.withMatch(game)
			.withDisplayOdds(displayOdds && isAutoCheckEnabled)
			.build();
		const oddsModel = SportOddsWidgetModel.builder().withBookmakers(null).build();

		this.setState({ ...this.state, isValid: true, widgetModel, oddsModel });
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(TennisSingleEventModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onOddsWidgetChange = (oddsModel: SportOddsWidgetModel) => {
		this.setState({ ...this.state, oddsModel });
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, TennisSingleEventModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { t, block, contentData, contentLanguage } = this.props;
		const { widgetModel, oddsModel, isValid } = this.state;

		return (
			<>
				<div data-qa='tennis-single-event-edit'>
					<Row>
						<Col>
							<FormGroup>
								<SportsEntitySelect
									inputId='player-select'
									isMulti={true}
									t={t}
									value={widgetModel.players ? widgetModel.players : []}
									onSelect={(player: PlayerModel[]) => this.onPlayerChange(player)}
									isValid={isValid}
									sport={SportTypes.TENNIS}
									entityType={[ContentTypes.PLAYER, ContentTypes.TEAM]}
									isRequired
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<GamesContainer
									t={t}
									entityIds={
										widgetModel && widgetModel.players && widgetModel.players.length > 0
											? [widgetModel.players.filter((player: any) => player).map((player: any) => player.id.replace(':', ','))]
											: []
									}
									isMulti={false}
									maxNotStartedGames={20}
									maxFinishedGames={20}
									maxInterruptedGames={20}
									teamNames={
										widgetModel && widgetModel.players && widgetModel.players.length > 0
											? widgetModel.players.filter((player: any) => player).map((player: PlayerModel) => player.name)
											: ['']
									}
									selectedGameId={widgetModel && widgetModel.match ? widgetModel.match.id : ''}
									onSelect={this.onMatchChange}
									sport={SportTypes.TENNIS}
								/>
							</FormGroup>
						</Col>
					</Row>
					<hr />
					{widgetModel && widgetModel.match && widgetModel.match.statusType !== EventStatuses.FINISHED && (
						<DisplayOddsCheckbox
							blockId={this.props.block.id}
							onDisplayOddsChange={this.onDisplayOddsChange}
							isChecked={widgetModel.displayOdds}
							sport={SportTypes.TENNIS}
						/>
					)}
					{widgetModel && widgetModel.match && widgetModel.match.statusType !== EventStatuses.FINISHED && widgetModel.displayOdds && (
						<SportOddsWidgetEdit
							contentData={contentData}
							block={block}
							t={t}
							game={widgetModel && widgetModel.match ? widgetModel.match : oddsModel.game}
							onOddsWidgetChange={this.onOddsWidgetChange}
							displayOddsLabel={true}
							isValid={isValid}
							contentLanguage={contentLanguage}
							currentOdds={oddsModel}
							sport={SportTypes.TENNIS}
						/>
					)}
					<hr />
					{!isValid && widgetModel.match && !widgetModel.match.id && (
						<div className='mb-3'>
							<ErrorHandler t={t} errorMessage='no_matches_selected' />
						</div>
					)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
