import * as React from 'react';
import { useEffect, useState } from 'react';
import FansUnitedGameModel from '../../blocks/widgets/fans-united/models/fans-united-game.model';
import { fansUnitedService } from '../../../../../App';
import { FansUnitedContent } from './fu-managers-content';
import FansUnitedLeaderboardTemplatesModel from '../../blocks/widgets/fans-united/models/fans-united-leaderboard-templates.model';
import { FansUnitedWidgetsTypes } from '../../constants/block.types';
import FansUnitedClassicQuizModel from '../../blocks/widgets/fans-united/models/fans-united-classic-quiz.model';
import FansUnitedEitherOrModel from '../../blocks/widgets/fans-united/models/fans-united-either-or.model';
import FansUnitedPollModel from '../../blocks/widgets/fans-united/models/fans-united-poll.model';

type Properties = {
	maxItems: number;
	selectedId: string;
	onSelect: any;
	type?: string;
	componentType: string;
};

export const FansUnitedContentContainer: React.FunctionComponent<Properties> = ({
	maxItems,
	selectedId,
	onSelect,
	type,
	componentType,
}) => {
	const [games, setGames] = useState<FansUnitedGameModel[]>([]);
	const [templates, setTemplates] = useState<FansUnitedLeaderboardTemplatesModel[]>([]);
	const [quizzes, setQuizzes] = useState<FansUnitedClassicQuizModel[]>([]);
	const [eitherOrGames, setEitherOrGames] = useState<FansUnitedEitherOrModel[]>([]);
	const [polls, setPolls] = useState<FansUnitedPollModel[]>([]);
	const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

	useEffect(() => {
		switch (componentType) {
			case FansUnitedWidgetsTypes.leaderboard:
				loadTemplates(templates, selectedId);
				break;
			case FansUnitedWidgetsTypes.classicQuiz:
				loadQuizzes(quizzes, selectedId);
				break;
			case FansUnitedWidgetsTypes.eitherOr:
				loadEitherOrGames(eitherOrGames, selectedId);
				break;
			case FansUnitedWidgetsTypes.polls:
				loadPolls(polls, selectedId);
				break;
			default:
				loadGames(games, selectedId);
				break;
		}
	}, []);

	useEffect(() => {
		let openGames = fansUnitedService.markFuGameModelAsSelected(games, selectedId);
		setGames(openGames);

		let fuTemplates = fansUnitedService.markFuTemplateModelAsSelected(templates, selectedId);
		setTemplates(fuTemplates);

		let fuQuizzes = fansUnitedService.markFuClassicQuizModelAsSelected(quizzes, selectedId);
		setQuizzes(fuQuizzes);

		let fuEitherOrGames = fansUnitedService.markFuEitherOrGamesModelAsSelected(eitherOrGames, selectedId);
		setEitherOrGames(fuEitherOrGames);

		let fuPolls = fansUnitedService.markFuPollsModelAsSelected(polls, selectedId);
		setPolls(fuPolls);
	}, [selectedId]);

	const loadGames = (games: FansUnitedGameModel[], selectedGameId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getFansUnitedGames(type ? type : 'TOP_X', maxItems)
			.then((games) => {
				const events = fansUnitedService.markFuGameModelAsSelected(games, selectedGameId);
				setGames(events);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const loadTemplates = (games: FansUnitedLeaderboardTemplatesModel[], selectedId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getLeaderboards(maxItems)
			.then((templates) => {
				const template = fansUnitedService.markFuTemplateModelAsSelected(templates, selectedId);
				setTemplates(template);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const loadQuizzes = (quizzes: FansUnitedClassicQuizModel[], selectedId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getClassicQuizzes(maxItems)
			.then((quizzes) => {
				const quiz = fansUnitedService.markFuClassicQuizModelAsSelected(quizzes, selectedId);
				setQuizzes(quiz);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const loadEitherOrGames = (games: FansUnitedEitherOrModel[], selectedId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getEitherOrGames(maxItems)
			.then((games) => {
				const eitherOrGame = fansUnitedService.markFuEitherOrGamesModelAsSelected(games, selectedId);
				setEitherOrGames(eitherOrGame);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const loadPolls = (polls: FansUnitedPollModel[], selectedId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getPolls(maxItems)
			.then((polls) => {
				const poll = fansUnitedService.markFuPollsModelAsSelected(polls, selectedId);
				setPolls(poll);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`} data-qa='widget-fans-united-content-container'>
			<FansUnitedContent
				templates={templates}
				componentType={componentType}
				quizzes={quizzes}
				eitherOrGames={eitherOrGames}
				onContentSelect={onSelect}
				polls={polls}
				games={games}
			/>
		</div>
	);
};
