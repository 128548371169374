import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../constants/general.constants';
import BlockUpdateController from '../../../blocks/block-update-controller.component';
import { SportTypes } from '../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import TennisSingleEventWidgetEdit from './tennis-ranking-edit.component';
import { TennisRankingWidgetView } from './tennis-ranking-view.component';
import TennisRankingModel from './models/tennis-ranking.model';
import { BlockyV2Properties, shouldNormalViewBlocky, shouldPreviewBlocky } from '../../utils/helper';

const TennisRankingWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <TennisSingleEventWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.TENNIS} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<TennisRankingWidgetView
				t={props.t}
				rankingPreview={
					props.block.data.preview && props.block.data.preview.ranking ? props.block.data.preview.ranking : TennisRankingModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(TennisRankingWidget);
