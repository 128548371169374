import BlockModel from '../models/block.model';
import { WidgetTypesV2 } from '../constants/block.types';
import { ViewTypes } from '../constants/general.constants';
import React from 'react';
import FootballSingleEventWidget from '../v2/widgets-v2/football-single-event/components/football-single-event.component';
import FootballLivescoreWidget from '../v2/widgets-v2/football-livescore/components/football-livescore.component';
import FootballStandingsWidget from '../v2/widgets-v2/football-standings/components/football-standings.component';
import FootballTeamProgrammeComponent from '../v2/widgets-v2/football-team-programme/components/football-team-programme.component';
import FootballKnockoutWidget from '../v2/widgets-v2/football-knockout-scheme/components/football-knockout-scheme.component';
import FootballPlayerWidget from '../v2/widgets-v2/football-player/components/football-player.component';
import FootballTeamWidget from '../v2/widgets-v2/football-team/components/football-team.component';
import FootballTeamSquadWidget from '../v2/widgets-v2/football-team-squad/components/football-team-squad.component';
import FootballTournamentProgrammeWidget from '../v2/widgets-v2/football-tournament-programme/components/football-tournament-programme.component';
import FootballPlayerH2HWidget from '../v2/widgets-v2/football-player-h2h/components/football-player-h2h.component';
import FootballTeamH2hComponent from '../v2/widgets-v2/football-team-h2h/components/football-team-h2h.component';
import FootballTeamForm from '../v2/widgets-v2/football-team-form/components/football-team-form.component';
import FootballTopScorers from '../v2/widgets-v2/football-top-scorers/components/football-top-scorers.component';
import FootballMostDecoratedPlayers from '../v2/widgets-v2/football-most-decorated-players/components/football-most-decorated-players.component';
import BasketballSingleEventWidget from '../blocks/widgets/basketball-single-event/components/basketball-single-event.component';
import BasketballLivescoreWidget from '../blocks/widgets/livescore/components/basketball-livescore-block.component';
import BasketballStandingsWidget from '../blocks/widgets/basketball-standings/components/basketball-standings.component';
import TennisSingleEventWidget from '../v2/widgets-v2/tennis-single-event/tennis-single-event.component';
import TennisLivescoreWidget from '../v2/widgets-v2/tennis-livescore/tennis-livescore.component';
import TennisRankingWidget from '../v2/widgets-v2/tennis-ranking/tennis-ranking.component';
import TennisPlayoffWidget from '../v2/widgets-v2/tennis-playoff/tennis-playoff.component';
import BasketballTournamentProgrammeWidget from '../v2/widgets-v2/basketball-tournament-programme/components/basketball-tournament-programme.component';
import BasketballTeamProgrammeWidget from '../v2/widgets-v2/basketball-team-programme/components/basketball-team-programme.component';
import FootballMatchesH2HWidget from '../v2/widgets-v2/football-matches-h2h/components/football-matches-h2h.component';
import TennisAthleteProgrammeWidget from '../v2/widgets-v2/tennis-athlete-programme/tennis-athlete-programme.component';
import TennisTournamentProgrammeWidget from '../v2/widgets-v2/tennis-tournament-programme/tennis-tournament-programme.component';
import FootballLineupsWidget from '../v2/widgets-v2/football-lineups/components/football-lineups.component';
import FootballOdds from '../v2/widgets-v2/odds/components/v2/widgets-component/odds.component';
import FootballStakes from '../v2/widgets-v2/stakes/components/stakes.component';
import FootballTeamH2hMatch from '../v2/widgets-v2/football-team-h2h/team-h2h-match/football-team-h2h-match.component';
import IceHockeyLivescoreWidget from '../v2/widgets-v2/ice-hockey-livescore/components/ice-hockey-livescore.component';
import IceHockeySingleEventWidget from '../v2/widgets-v2/ice-hockey-single-event/components/ice-hockey-single-event.component';
import FootballSingleRoundWidget from '../v2/widgets-v2/football-round/components/football-single-round.component';
import IceHockeyStandingsComponent from '../v2/widgets-v2/ice-hockey-standings/components/ice-hockey-standings.component';
import FootballMatchCenterWidget from '../v2/widgets-v2/football-match-center/components/fooball-match-center.component';

export default class WidgetBlocksV2Generator {
	public createBlock = (block: BlockModel, t: any, contentData: any, contentLanguage: string, view: ViewTypes = ViewTypes.normal) => {
		const componentProps = { view, block, t, contentData, contentLanguage };

		switch (block.data.widget_type) {
			case WidgetTypesV2.footballSingleEvent: {
				return <FootballSingleEventWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballLivescore: {
				return <FootballLivescoreWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballStandings: {
				return <FootballStandingsWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTeamProgramme: {
				return <FootballTeamProgrammeComponent {...componentProps} />;
			}

			case WidgetTypesV2.footballKnockout: {
				return <FootballKnockoutWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballPlayer: {
				return <FootballPlayerWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTeam: {
				return <FootballTeamWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTeamSquad: {
				return <FootballTeamSquadWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTournamentProgramme: {
				return <FootballTournamentProgrammeWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballPlayerH2H: {
				return <FootballPlayerH2HWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTeamH2H: {
				return <FootballTeamH2hComponent {...componentProps} />;
			}

			case WidgetTypesV2.footballTeamH2HMatch: {
				return <FootballTeamH2hMatch {...componentProps} />;
			}

			case WidgetTypesV2.footballMatchesH2H: {
				return <FootballMatchesH2HWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballTeamForm: {
				return <FootballTeamForm {...componentProps} />;
			}

			case WidgetTypesV2.footballTopScorers: {
				return <FootballTopScorers {...componentProps} />;
			}

			case WidgetTypesV2.footballMostDecoratedPlayers: {
				return <FootballMostDecoratedPlayers {...componentProps} />;
			}

			case WidgetTypesV2.footballLineups: {
				return <FootballLineupsWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballMatchCenter: {
				return <FootballMatchCenterWidget {...componentProps} />;
			}

			case WidgetTypesV2.basketballSingleEvent: {
				return <BasketballSingleEventWidget {...componentProps} />;
			}

			case WidgetTypesV2.basketballLivescore: {
				return <BasketballLivescoreWidget {...componentProps} />;
			}

			case WidgetTypesV2.basketballStandings: {
				return <BasketballStandingsWidget {...componentProps} />;
			}

			case WidgetTypesV2.basketballTournamentProgramme: {
				return <BasketballTournamentProgrammeWidget {...componentProps} />;
			}

			case WidgetTypesV2.basketballTeamProgramme: {
				return <BasketballTeamProgrammeWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisSingleEvent: {
				return <TennisSingleEventWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisLivescore: {
				return <TennisLivescoreWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisRanking: {
				return <TennisRankingWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisPlayoff: {
				return <TennisPlayoffWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisAthleteProgramme: {
				return <TennisAthleteProgrammeWidget {...componentProps} />;
			}

			case WidgetTypesV2.tennisTournamentProgramme: {
				return <TennisTournamentProgrammeWidget {...componentProps} />;
			}

			case WidgetTypesV2.footballOdds: {
				return <FootballOdds {...componentProps} />;
			}

			case WidgetTypesV2.footballStakes:
				return <FootballStakes {...componentProps} />;

			case WidgetTypesV2.footballSingleRound:
				return <FootballSingleRoundWidget {...componentProps} />;

			case WidgetTypesV2.iceHockeyLivescore:
				return <IceHockeyLivescoreWidget {...componentProps} />;

			case WidgetTypesV2.iceHockeySingleEvent:
				return <IceHockeySingleEventWidget {...componentProps} />;

			case WidgetTypesV2.iceHockeyStandings:
				return <IceHockeyStandingsComponent {...componentProps} />;
			default:
				return null;
		}
	};
}
