import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';

export enum MatchTypes {
	BOTH = 'both',
	HOME = 'home',
	AWAY = 'away',
}

export const WidgetTeamTabs = (t: any): SelectMenuOptionType[] => {
	return [
		{ value: 'info', label: t('info'), data: 'info' },
		{ value: 'stats', label: t('stats'), data: 'stats' },
		{ value: 'matches', label: t('matches'), data: 'matches' },
		{ value: 'predictions', label: t('predictions'), data: 'predictions' },
	];
};

export const WidgetPlayerTabs = (t: any): SelectMenuOptionType[] => {
	return [
		{ value: 'info', label: t('info'), data: 'info' },
		{ value: 'stats', label: t('stats'), data: 'stats' },
		{ value: 'matches', label: t('matches'), data: 'matches' },
		{ value: 'predictions', label: t('predictions'), data: 'predictions' },
	];
};

export const WidgetTeamDefaultTabsWithMatches = (t: any) => {
	return [
		{ value: 'info', label: t('info'), default: true },
		{ value: 'stats', label: t('stats'), default: false },
		{ value: 'matches', label: t('matches'), default: false },
	];
};

export const WidgetTeamDefaultTabs = (t: any) => {
	return [
		{ value: 'info', label: t('info'), default: true },
		{ value: 'stats', label: t('stats'), default: false },
	];
};

export const WidgetTeamSquadDefaultHeader = (t: any) => {
	return [
		{ value: 'grid', label: t('grid'), default: false },
		{ value: 'list', label: t('list'), default: true },
	];
};

export const WidgetTeamProgrammeMatchTypeOptions = (t: any) => {
	return [
		{ value: 'home', label: t('home'), default: false },
		{ value: 'away', label: t('away'), default: false },
		{ value: 'both', label: t('both'), default: true },
	];
};

export const matchCenterActiveTabOptions = (t: any) => {
	return [
		{ label: t('overview'), value: 'overview', default: true },
		{ label: t('details'), value: 'details', default: false },
		{ label: t('squads'), value: 'squads', default: false },
		{ label: t('stats'), value: 'stats', default: false },
		{ label: t('standings'), value: 'standings', default: false },
		{ label: t('odds'), value: 'odds', default: false },
		{ label: t('h2h'), value: 'h2h', default: false },
	];
};

export const teamProfileInfoTabOptions: string[] = ['founded', 'venue', 'socials'];

export const teamProfileStatsTabOptions: string[] = ['win', 'draw', 'played', 'goalsConceded', 'goalsScored', 'points', 'rank', 'defeats'];

export const playerProfileInfoTabOptions: string[] = ['country', 'position', 'dateOfBirth', 'height', 'weight'];

export const playerProfileStatsTabOptions: string[] = [
	'played',
	'goals',
	'assists',
	'fouls_committed',
	'shots',
	'shots_on_target',
	'red_cards',
	'yellow_cards',
];

export const playerH2HStatsOptions: string[] = [
	'played',
	'minutes',
	'assists',
	'red_cards',
	'yellow_cards',
	'clean_sheets',
	'fouls_committed',
	'goals',
	'goals_substitute',
	'minutes_substitute',
	'offsides',
	'own_goal',
	'penalties_committed',
	'penalties_missed',
	'penalties_received',
	'penalties_saved',
	'penalty_goals',
	'shots',
	'shots_on_target',
	'started',
	'substitute_in',
	'substitute_out',
];

export const teamH2HStatsOptions: string[] = ['played', 'goalsScored', 'win', 'draw', 'goalsConceded', 'defeats', 'points', 'rank'];

export const teamMatchStatsOptions: string[] = [
	'corners',
	'counterAttacks',
	'crosses',
	'foulsCommitted',
	'goalKicks',
	'goals',
	'offside',
	'possession',
	'redCards',
	'yellowCards',
	'shotsBlocked',
	'shotsOff',
	'shotsOn',
	'substitutions',
	'throwIn',
	'treatments',
];

export const topScorersStatsOptions: string[] = [
	'played',
	'red_cards',
	'assists',
	'minutes',
	'missed_penalties',
	'penalties',
	'yellow_cards',
	'scored_first',
];

export const teamSquadStatsOptions: string[] = [
	'age',
	'matches_played',
	'minutes',
	'minutes_substitute',
	'goals',
	'started',
	'assists',
	'yellow_cards',
	'red_cards',
	'conceded',
	'cleansheets',
];
