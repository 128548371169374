import { ViewTypes } from '../../constants/general.constants';
import BlockModel from '../../models/block.model';

export type BlockyV2Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

export const shouldPreviewBlocky = (view: ViewTypes, block: BlockModel, previewId: string) => {
	return view === ViewTypes.preview && previewId === block.id;
};

export const shouldNormalViewBlocky = (view: ViewTypes, block: BlockModel, previewId: string) => {
	const isOldPreview = view === ViewTypes.preview && previewId !== block.id;
	return view === ViewTypes.normal || isOldPreview;
};
