import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamH2hMatchJson } from './football-team-h2h-match.json';
import FootballTeamH2hMatchWidgetModel from './football-team-match-stats.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamH2hMatchBuilder {
	readonly json: FootballTeamH2hMatchJson;

	constructor(teamH2H?: FootballTeamH2hMatchWidgetModel | FootballTeamH2hMatchJson) {
		if (teamH2H && teamH2H instanceof FootballTeamH2hMatchWidgetModel) {
			this.json = teamH2H.toJson();
		} else if (teamH2H) {
			this.json = teamH2H;
		} else {
			this.json = {} as FootballTeamH2hMatchJson;
		}
	}

	withTeamOne(team1: TeamModel): FootballTeamH2hMatchBuilder {
		this.json.team1 = team1;

		return this;
	}

	withTeamTwo(team2: TeamModel): FootballTeamH2hMatchBuilder {
		this.json.team2 = team2;

		return this;
	}

	withTeamOneSeason(team1TournamentSeason: EntitySeasonsSelectModel): FootballTeamH2hMatchBuilder {
		this.json.team1TournamentSeason = team1TournamentSeason;

		return this;
	}

	withTeamTwoSeason(team2TournamentSeason: EntitySeasonsSelectModel): FootballTeamH2hMatchBuilder {
		this.json.team2TournamentSeason = team2TournamentSeason;

		return this;
	}

	withStats(stats: string[]): FootballTeamH2hMatchBuilder {
		this.json.stats = stats;

		return this;
	}

	withMatch(match: MatchModel): FootballTeamH2hMatchBuilder {
		this.json.match = match;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballTeamH2hMatchBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballTeamH2hMatchBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

  withDisplayHeader(displayHeader: boolean): FootballTeamH2hMatchBuilder {
    this.json.displayHeader = displayHeader;

    return this;
  }

	build(): FootballTeamH2hMatchWidgetModel {
		return FootballTeamH2hMatchWidgetModel.fromJson(this.json);
	}
}
