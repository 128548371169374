import { FootballMatchCenterJson } from './football-match-center.json';
import FootballMatchCenterModel from './football-match-center.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballMatchCenterBuilder {
	readonly json: FootballMatchCenterJson;

	constructor(footballMatchCenter?: FootballMatchCenterModel | FootballMatchCenterJson) {
		if (footballMatchCenter && footballMatchCenter instanceof FootballMatchCenterModel) {
			this.json = footballMatchCenter.toJson();
		} else if (footballMatchCenter) {
			this.json = footballMatchCenter;
		} else {
			this.json = {} as FootballMatchCenterJson;
		}
	}

	withMatch(match: MatchModel): FootballMatchCenterBuilder {
		this.json.match = match;

		return this;
	}

	withTeams(teams: TeamModel[]): FootballMatchCenterBuilder {
		this.json.teams = teams;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballMatchCenterBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): FootballMatchCenterBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballMatchCenterBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballMatchCenterModel {
		return FootballMatchCenterModel.fromJson(this.json);
	}
}
