import React from 'react';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { useTranslation } from 'react-i18next';
import { PollManager } from 'fansunited-widget-poll';
import './fans-united-styles.scss';

export const PollManagerComponent: React.FunctionComponent = () => {
	const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const [t] = useTranslation();

	return config &&
		config.configuration &&
		config.configuration.api_key &&
		config.configuration.client_id &&
		config.configuration.language ? (
		<div className='fans-united-container'>
			<PollManager
				fansUnitedApiKey={config.configuration.api_key}
				fansUnitedLanguage={config.configuration.language}
				fansUnitedClientId={config.configuration.client_id}
			/>
		</div>
	) : null;
};
