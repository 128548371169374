import { StandingsWidgetV2Json } from './standings-widget-v2.json';
import StandingsWidgetV2Model from './standings-widget-v2.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';

export default class StandingsWidgetV2Builder {
	readonly json: StandingsWidgetV2Json;

	constructor(standings?: StandingsWidgetV2Model | StandingsWidgetV2Json) {
		if (standings && standings instanceof StandingsWidgetV2Model) {
			this.json = standings.toJson();
		} else if (standings) {
			this.json = standings;
		} else {
			this.json = {} as StandingsWidgetV2Json;
		}
	}

	withTournament(tournament: TournamentModel): StandingsWidgetV2Builder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): StandingsWidgetV2Builder {
		this.json.season = season;

		return this;
	}

	withStage(stage: StageModel): StandingsWidgetV2Builder {
		this.json.stage = stage;

		return this;
	}

	withGroup(group: any): StandingsWidgetV2Builder {
		this.json.group = group;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): StandingsWidgetV2Builder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withHighlightTeams(teams: TeamModel[]): StandingsWidgetV2Builder {
		this.json.highlightTeams = teams;

		return this;
	}

	withOffset(offset: string): StandingsWidgetV2Builder {
		this.json.offset = offset;

		return this;
	}

	withLimit(limit: string): StandingsWidgetV2Builder {
		this.json.limit = limit;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): StandingsWidgetV2Builder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): StandingsWidgetV2Model {
		return StandingsWidgetV2Model.fromJson(this.json);
	}
}
