import React, { useEffect } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import ErrorHandler from '../error-handler/error-handler';
import { useTranslation } from 'react-i18next';
import TournamentModel from '../../../../../../models/v2/Tournament/Entity/TournamentModel';

type Properties = {
	id: string;
	tournament: TournamentModel;
	isRequired?: boolean;
	isValid: boolean;
	roundDropdown: boolean;
	onRoundDropdownChange: (value: boolean) => void;
};

type OptionType = {
	label: string;
	value: boolean;
};

const RoundDropdownSelect: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const { id, isRequired, isValid, tournament, roundDropdown, onRoundDropdownChange } = props;

	useEffect(() => {
		if (tournament && tournament.id && typeof roundDropdown !== 'boolean') {
			onRoundDropdownChange(true);
		}
	}, [tournament]);

	const onChange = (option: OptionType | null) => {
		if (option) {
			onRoundDropdownChange(option.value);
		}
	};

	const options: OptionType[] = [
		{
			label: t('round_dropdown_select_options.option_1'),
			value: true,
		},
		{
			label: t('round_dropdown_select_options.option_2'),
			value: false,
		},
	];

	const value = options.find(({ value }) => value === roundDropdown);

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('round_dropdown_select')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					options={options}
					value={value}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					placeholder={t('select')}
					onChange={(selectedOption) => onChange(selectedOption as OptionType | null)}
				/>
				{isRequired && (
					<ErrorHandler t={t} arePropsValid={isValid} isValueSelected={roundDropdown !== undefined} errorMessage='field_is_required' />
				)}
			</Col>
		</Row>
	);
};

export default RoundDropdownSelect as React.ComponentType<Properties>;
