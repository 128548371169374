import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { FootballPlayerH2hViewComponent } from './football-player-h2h-view.component';
import FootballPlayerH2HEditComponent from './football-player-h2h-edit.component';
import FootballPlayerH2HWidgetModel from '../models/football-player-h2h.model';
import { BlockyV2Properties } from '../../../utils/helper';

const FootballPlayerH2HWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <FootballPlayerH2HEditComponent block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballPlayerH2hViewComponent
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: FootballPlayerH2HWidgetModel.builder().build()
				}
				t={t}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballPlayerH2HWidget);
