import React, { FunctionComponent, useEffect, useState } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { extractMainContentTypeBasedOnUrl, isTempIdOrEmpty } from '../../../../../../global-helpers/global.helpers';
import { getListDetailsAndPopulateWithStatistics, getPresentInData } from '../../helpers/list-http.helper';
import { LIST_ENTITY_RECEIVED, updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import Loader from 'react-spinners/BeatLoader';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../../App';

type Properties = {
	t: any;
	contentModelId: string;
	projectDomain: string;
	updateListInRedux: (list: ListModel) => void;
};

const ListPresentIn: FunctionComponent<Properties> = ({ t, contentModelId, projectDomain, updateListInRedux }) => {
	let listEntitySubscription: Subscription = {} as Subscription;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [listPresentIn, setListPresentIn] = useState<ListModel[]>([]);

	const fetchPresentInResources = () => {
		// there are problems with elastic search, so we need to wait a bit before fetching the present in data
		// check issue: SFE-4826 for further information
		const timer = setTimeout(() => {
			getPresentInData(contentModelId, projectDomain, setIsLoading, setListPresentIn);
		}, 850);

		return () => clearTimeout(timer);
	};

	useEffect(() => {
		listEntitySubscription = actionService.onActionReceived().subscribe((action: string) => {
			action === LIST_ENTITY_RECEIVED && fetchPresentInResources();
		});

		return () => listEntitySubscription.unsubscribe();
	}, []);

	useEffect(fetchPresentInResources, [contentModelId]);

	const onSelectPresentedList = async (selectedListId: string) => {
		const remappedListData = await getListDetailsAndPopulateWithStatistics(selectedListId, projectDomain);
		updateListInRedux(remappedListData);
	};

	if (isTempIdOrEmpty(contentModelId) || listPresentIn.length === 0) {
		return null;
	}

	return (
		<>
			<div>
				{t(extractMainContentTypeBasedOnUrl())} {t('is_present_in')}:
			</div>
			<div
				className='w-100 position-relative d-flex flex-direction-row align-items-center flex-wrap mb-1'
				id={DATA_QA_ATTRIBUTES.LIST_PRESENT_IN_CONTAINER}
			>
				{listPresentIn.map((list: ListModel) => (
					<div
						key={list.id}
						onClick={() => onSelectPresentedList(list.id)}
						style={{ maxWidth: '100%' }}
						className={`bg-light cursor-pointer mr-1 mb-1 p-1 text-truncate rounded ${isLoading ? 'opacity-03' : ''}`}
					>
						{list.title}
					</div>
				))}
				{isLoading && (
					<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '50%' }}>
						<Loader size={5} />
					</div>
				)}
			</div>
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
		projectDomain: state.project.currentProject.domain || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPresentIn);
