import Category from '../../../../models/category/Category';
import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import { UrlsComponentModel } from '../../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import Seo from '../../../../models/seo/Seo';
import Urls from '../../../../models/urls/Urls';
import Global from '../../../Partials/Blocky/helpers/global.helpers';

export function categoryToSeoComponentModel(category: Category): SeoComponentModel {
	return {
		keywords: category.seo && category.seo.keywords ? category.seo.keywords : [],
		following: category.seo ? category.seo.follow : true,
		indexing: category.seo ? category.seo.index : true,
		title: category.seo && category.seo.title ? Global.stripEmoji(category.seo.title) : '',
		description: category.seo && category.seo.description ? category.seo.description : '',
		slug: category.seo && category.seo.slug ? Global.stripEmoji(category.seo.slug) : '',
		contentTitle: category && category.title ? Global.stripEmoji(category.title) : '',
		autoSlug: category && category.seo && category.seo.autoSlug !== undefined ? category.seo.autoSlug : true,
		autoTitle: category && category.seo && category.seo.autoTitle !== undefined ? category.seo.autoTitle : true,
	};
}

export function categoryToUrlsComponentModelForCreate(category: Category): UrlsComponentModel {
	return {
		externalUrl: category.urls && category.urls.externalUrl ? category.urls.externalUrl : '',
		canonicalUrl: category.urls && category.urls.canonicalUrl ? category.urls.canonicalUrl : '',
		redirectType: category.seo && category.seo.redirectType ? category.seo.redirectType : '',
	};
}

export function categoryToUrlsComponentModelForEdit(category: Category): UrlsComponentModel {
	if (category) {
		return {
			externalUrl: category.urls && category.urls.externalUrl ? category.urls.externalUrl : '',
			canonicalUrl: category.urls && category.urls.canonicalUrl ? category.urls.canonicalUrl : '',
			redirectType: category.seo && category.seo.redirectType ? category.seo.redirectType : '',
		};
	}

	return {} as UrlsComponentModel;
}

export function categoryToSeoComponentModelForCreate(category: Category): SeoComponentModel {
	return {
		keywords: category.seo && category.seo.keywords ? category.seo.keywords : [],
		following: category.seo ? category.seo.follow : true,
		indexing: category.seo ? category.seo.index : true,
		title: category.seo && category.seo.title ? Global.stripEmoji(category.seo.title) : '',
		description: category.seo && category.seo.description ? category.seo.description : '',
		slug: category.seo && category.seo.slug ? Global.stripEmoji(category.seo.slug) : '',
		contentTitle: category && category.title ? Global.stripEmoji(category.title) : '',
		autoSlug: category && category.seo ? category.seo.autoSlug : false,
		autoTitle: category && category.seo ? category.seo.autoTitle : false,
	};
}

export function seoComponentModelToCategory(seoModel: SeoComponentModel, category: Category): Category {
	const seo: Seo = Seo.builder(category.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.build();

	return Category.builder(category).withSeo(seo).build();
}

export function urlsComponentModelToCategory(urlsModel: UrlsComponentModel, category: Category): Category {
	const urls: Urls = Urls.builder(category.urls).withCanonicalUrl(urlsModel.canonicalUrl).withExternalUrl(urlsModel.externalUrl).build();
	const seo: Seo = Seo.builder(category.seo).withRedirectType(urlsModel.redirectType).build();

	return Category.builder(category).withSeo(seo).withUrls(urls).build();
}
