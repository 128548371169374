import { Col, Row } from 'reactstrap';
import i18next from 'i18next';
import React from 'react';

export const generateDisplayHeaderOptionViewText = (value: boolean): JSX.Element | null => {
	return (
		<Row className='pb-2 pl-4 pr-4 mt-2' data-qa='display-header-view-row'>
			<Col className='text-center'>
				<Row>
					<Col className='text-left'>
						<strong>{i18next.t('header_display')}: </strong>
						<strong>
							<span className={value ? 'text-success' : 'text-danger'}>{value ? ` ${i18next.t('yes')}` : ` ${i18next.t('no')}`}</span>
						</strong>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
