import React, { FunctionComponent, useEffect, useRef } from 'react';
import { loadWidgetV2Html } from '../../../v2/widgets-v2/helpers/helpers';
import './monorepo-preview.css';

export type Properties = {
	blockContent: string;
	config?: any;
	sport: string;
};

export const MonorepoWidgetPreview: FunctionComponent<Properties> = ({ blockContent, config, sport }) => {
	const gameId = config && config.options && config.options.gameId ? config.options.gameId : '';
	const myElementRef = useRef(null);

	useEffect(() => {
		return function cleanup() {
			clearEventRequest(gameId);
		};
	});

	useEffect(() => {
		if (blockContent && myElementRef && sport) {
			const element = myElementRef.current;
			element && loadWidgetV2Html(blockContent, sport, element);
		}
	}, [blockContent, sport, myElementRef]);

	return <div className='monorepo-block-preview-content' ref={myElementRef} />;
};

const clearEventRequest = (gameId: string) => {
	window.dispatchEvent(
		new CustomEvent('unregister_event', {
			detail: {
				gameId,
			},
		}),
	);

	if (window.removeEventsRegister) {
		window.removeEventsRegister();
		window.removeSingleRequestEventListener();
	}
};
