import { ContentTypes } from '../../constants/content-types';

export const TOGGLE_MODAL = '[ACTION] TOGGLE_MODAL';
export const TOGGLE_LOADING_STATE = '[ACTION] TOGGLE_LOADING_STATE';
export const SEARCH_IS_APPLIED = '[ACTION] SEARCH_IS_APPLIED';
export const TOGGLE_SHOW_LIST_IMAGES = '[ACTION] TOGGLE_SHOW_LIST_IMAGES';
export const TOGGLE_CONTENT_LOADING_STATE = '[ACTION] TOGGLE_CONTENT_LOADING_STATE';
export const WIDGET_V2_PREVIEW_ID_UPDATE = '[ACTION] WIDGET_V2_PREVIEW_ID_UPDATE';

export const toggleModal = (value: boolean) => ({
	type: TOGGLE_MODAL,
	payload: value,
});

export const toggleLoadingState = (bool: boolean) => ({
	type: TOGGLE_LOADING_STATE,
	payload: bool,
});

export const toggleContentTypeLoadingState = (contentType: ContentTypes, value: boolean) => ({
	type: TOGGLE_CONTENT_LOADING_STATE,
	payload: { contentType, value },
});

export const searchIsApplied = () => ({
	type: SEARCH_IS_APPLIED,
});

export const toggleShowListImages = (profileId: string) => ({
	type: TOGGLE_SHOW_LIST_IMAGES,
	payload: profileId,
});

export function updateWidgetV2PreviewId(blockId: string) {
	return {
		type: WIDGET_V2_PREVIEW_ID_UPDATE,
		payload: blockId,
	};
}
