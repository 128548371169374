import React from 'react';
import './custom-tabs-navigation.styles.scss';

interface CustomCheckboxProps {
	checked: boolean;
	onChange: (checked: boolean) => void;
	label: string;
	disabled: boolean;
	dataQaProperty: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, label, disabled, dataQaProperty }) => {
	const onCheck = () => {
		if (!disabled) {
			onChange(!checked);
		}
	};

	return (
		<div
			className={`custom-checkbox-container ${disabled ? 'disabled' : ''}`}
			style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
			data-qa={dataQaProperty}
		>
			<div onClick={onCheck} className={`checkbox ${checked ? 'checked' : ''}`}>
				{checked && <span className='checkmark'>&#10003;</span>}
			</div>
			<span className={`${disabled ? 'disabled-label' : 'checkbox-label'}`}>{label}</span>
		</div>
	);
};

export default CustomCheckbox;
