import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../global-helpers/global.helpers';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../services/content-models-service/ContentModelService';
import { store } from '../../../../../store/store';
import { MainMediaActionDispatchFunction, imageResourceSubType, imageResourceType } from '../constants/general';
import { ReduxMainMediaProps } from '../constants/redux-main-media.attributes';
import { isValidResourceType } from './external-main-media';

const generatePrevStoreDataWithoutMainMedia = (): MainMediaModel[] => {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const prevStoreData: MainMediaModel[] = store.getState()[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [];

	const prevStoreDataWithoutMainMedia: MainMediaModel[] = prevStoreData.filter(
		(el: MainMediaModel) => el.resourceSubtype !== imageResourceSubType,
	);
	return prevStoreDataWithoutMainMedia;
};

const generatePrevStoreDataWithoutCurrentResourceType = (resourceType: string | null, resourceSubtype: string | null): MainMediaModel[] => {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const prevStoreData: MainMediaModel[] = store.getState()[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [];
	const prevStoreDataWithoutCurrentResourceType: MainMediaModel[] = prevStoreData.filter(
		(el: MainMediaModel) => !(el.resourceSubtype === resourceSubtype && el.resourceType === resourceType),
	);
	return prevStoreDataWithoutCurrentResourceType;
};

const generateUpdateMainMediaDispatchAction = (): MainMediaActionDispatchFunction | null => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const contentModeFromUrl = extractContentModeBasedOnUrl();
	const basicFunctionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	if (basicFunctionForDispatch) {
		const populatedMainMediaFunctionForDispatch = (mainMediaArray: MainMediaModel[]) =>
			basicFunctionForDispatch({ [ReduxMainMediaProps.MAIN_MEDIA]: mainMediaArray }, contentModeFromUrl);

		return (mainMediaArray: MainMediaModel[]) => store.dispatch(populatedMainMediaFunctionForDispatch(mainMediaArray));
	}

	return null;
};

export const onMainImageMediaUpdate = (newMainMedia: MainMediaModel) => {
	const isMainImage =
		newMainMedia.resourceId &&
		newMainMedia.resourceSubtype === imageResourceSubType &&
		newMainMedia.resourceType === imageResourceType &&
		newMainMedia.data;

	if (isMainImage) {
		const prevStoreDataWithoutMainMedia = generatePrevStoreDataWithoutMainMedia();
		const dispatchFunctionForUpdatingTempState = generateUpdateMainMediaDispatchAction();
		dispatchFunctionForUpdatingTempState && dispatchFunctionForUpdatingTempState([...prevStoreDataWithoutMainMedia, { ...newMainMedia }]);
	}
};

export const onExternalResourceUpdate = (newResource: MainMediaModel) => {
	const isValidResource = newResource.data && isValidResourceType(newResource);
	if (isValidResource) {
		const { resourceType, resourceSubtype } = newResource;
		const prevStoreDataWithoutCurrentResourceType = generatePrevStoreDataWithoutCurrentResourceType(resourceType, resourceSubtype);
		const dispatchFunctionForUpdatingTempState = generateUpdateMainMediaDispatchAction();
		if (dispatchFunctionForUpdatingTempState) {
			const newDataForRedux = [...prevStoreDataWithoutCurrentResourceType, { ...newResource }];
			dispatchFunctionForUpdatingTempState(newDataForRedux);
		}
	}
};
