import React from 'react';
import AppSidebarNav from '@coreui/react/lib/SidebarNav';
import { auditLogService, customBlocksService, featuresService } from '../../../App';
import { store } from '../../../store/store';
import { FeatureTypes } from '../../../services/feature-service/features.enum';

export const NavBarWrapper = ({ navConfig, options, router }) => {
	navConfig = shouldExcludeNavigationWithFeature(navConfig, options.t);

	return <AppSidebarNav navConfig={navConfig} {...options} router={router} />;
};

const shouldExcludeNavigationWithFeature = (navConfig, t) => {
	navConfig.items = navConfig.items.filter((item) => {
		if (item.customTitle === 'content_analytics') {
			return (
				featuresService.adminIsAuthorizedForContentAnalytics() && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_ANALYTICS)
			);
		}

		if (item.customTitle === 'live-blogs') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIVE_BLOG);
		}

		if (item.customTitle === 'wiki-pages') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIKI_PAGES);
		}

		if (item.customTitle === 'news-tracker') {
			return featuresService.areFeaturesEnabled([FeatureTypes.NEWS_FEED]);
		}

		if (item.customTitle === 'videos') {
			if (item.children) {
				item.children = item.children.filter((children) => {
					if (children.customTitle === 'jw_videos') {
						return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.JW_VIDEO);
					}

					return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
				});
			}

			return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
		}

		if (item.customTitle === 'customization') {
			return customBlocksService.isFeatureEnabled(featuresService);
		}

		if (item.customTitle === 'custom_blocks') {
			return customBlocksService.isFeatureEnabled(featuresService);
		}

		if (item.customTitle === 'audit_log') {
			const user = store.getState().profile.profile;
			return auditLogService.isFeatureEnabled(featuresService, user.id);
		}

		if (item.customTitle === 'widgets') {
			return featuresService.areFeaturesEnabled([FeatureTypes.WIDGET_EMBED, FeatureTypes.FOOTBALL]);
		}

		if (item.customTitle === 'dugout') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DUGOUT_INTEGRATION);
		}

		if (item.customTitle === FeatureTypes.ON_NETWORK_INTEGRATION) {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ON_NETWORK_INTEGRATION);
		}

		if (item.customTitle === 'playing-surface') {
			return (
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.PLAYING_SURFACE_INTEGRATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL])
			);
		}

		if (item.customTitle === 'multisport-widget') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTISPORT_WIDGET_CONFIGURATION);
		}

		if (item.customTitle === 'custom-entities') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CUSTOM_ENTITIES);
		}

		if (item.customTitle === 'imago') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
		}

		if (item.customTitle === 'sport') {
			const isTranslationFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FOOTBALL_TRANSLATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);
			const isTeamFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);
			const isPlayerFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);

			return isTranslationFeature || isTeamFeature || isPlayerFeature;
		}

		if (item.customTitle === 'images') {
			if (item.children) {
				item.children = item.children.filter((children) => {
					if (children.customTitle === 'playing-surface') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.PLAYING_SURFACE_INTEGRATION) &&
							featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL])
						);
					} else if (children.customTitle === 'imago') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
					} else if (children.customTitle === FeatureTypes.GETTY_IMAGES) {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.GETTY_IMAGES);
					}

					return true;
				});
			}

			return true;
		}

		if (item.customTitle === 'fans-united') {
			const fansUnitedFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
			const isFeatureEnable = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED);
			if (item.children) {
				item.children = item.children.filter((children) => {
					if (children.customTitle === 'topxmanager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('top_x') &&
							fansUnitedFeatureConfig.managers.top_x
						);
					} else if (children.customTitle === 'match-quiz-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('match_quiz') &&
							fansUnitedFeatureConfig.managers.match_quiz
						);
					} else if (children.customTitle === 'leaderboard-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('leaderboard') &&
							fansUnitedFeatureConfig.managers.leaderboard
						);
					} else if (children.customTitle === 'classic-quiz-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('classic_quiz') &&
							fansUnitedFeatureConfig.managers.classic_quiz
						);
					} else if (children.customTitle === 'discussions-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('discussions') &&
							fansUnitedFeatureConfig.managers.discussions
						);
					} else if (children.customTitle === 'either-or-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('either_or') &&
							fansUnitedFeatureConfig.managers.either_or
						);
					} else if (children.customTitle === 'polls-manager') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
							fansUnitedFeatureConfig.hasOwnProperty('managers') &&
							fansUnitedFeatureConfig.managers.hasOwnProperty('polls') &&
							fansUnitedFeatureConfig.managers.polls
						);
					}
				});
			}
			return isFeatureEnable;
		}

		if (item.customTitle === 'football') {
			if (item.children) {
				item.children = item.children.filter((children) => {
					if (children.customTitle === 'translations') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FOOTBALL_TRANSLATION) &&
							featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL])
						);
					}

					if (children.customTitle === 'tournament_groups') {
						return (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT_GROUPS) &&
							featuresService.adminIsAuthorizedForTournamentGroupsOrder()
						);
					}

					if (children.customTitle === 'teams') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM);
					}

					if (children.customTitle === 'tournaments') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT);
					}

					if (children.customTitle === 'players') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER);
					}

					if (children.customTitle === 'matches') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MATCH_LINEUPS);
					}

					if (children.customTitle === 'coaches') {
						return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_COACH);
					}

					return true;
				});
			}

			const isTranslationFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FOOTBALL_TRANSLATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);
			const isTeamFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);
			const isPlayerFeature =
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]);

			return isTranslationFeature || isTeamFeature || isPlayerFeature;
		}

		if (item.customTitle === 'widgets-page') {
			return featuresService.areFeaturesEnabled([FeatureTypes.WIDGET_EMBED, FeatureTypes.FOOTBALL]);
		}

		return true;
	});

	return navConfig;
};
