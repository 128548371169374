import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import './summary.style.scss';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { displayCharacterLimit, setInputBoxBorder } from './summary-validation.helper';

type SummaryProps = {
	t: any;
	onChange: (text: string) => any;
	showValidation: boolean;
	value?: string;
	customCss?: boolean;
	disabled?: boolean;
	timeoutValue?: number;
	dataQaProperty?: string;
	versionId?: string;
};

export const Summary: React.FunctionComponent<SummaryProps> = ({
	onChange,
	t,
	showValidation,
	value,
	customCss,
	disabled,
	timeoutValue,
	dataQaProperty,
	versionId,
}) => {
	const showForCorrectVersion = versionId === dataQaProperty;
	const showErrorValidationMessage = !showValidation && showForCorrectVersion ? showValidation : true;
	const isDefaultTitle = dataQaProperty ? dataQaProperty === 'default-summary' : true;

	return (
		<FormGroup data-qa={dataQaProperty || 'default-summary'}>
			<Label className={customCss ? 'custom-label' : ''} htmlFor='summary'>
				{t('summary')}
			</Label>
			<DebounceInput
				element='textarea'
				name='textarea-input'
				id={dataQaProperty || 'default-summary'}
				rows='2'
				className={`form-control-lg form-control fa-border ${showValidation && isDefaultTitle && setInputBoxBorder(value)} ${
					showValidation && isDefaultTitle && 'summary'
				}`}
				onChange={(event: any) => onChange(event.target.value)}
				value={value ? value : ''}
				placeholder={t('summary_placeholder')}
				disabled={!!disabled}
				debounceTimeout={timeoutValue}
			/>
			{showErrorValidationMessage && isDefaultTitle && <ErrorAsync t={t} errorType='subtitle' />}
			{showValidation && (
				<div className={`flex-row float-right font-weight-bold`}>
					<Label className={value && value.length > 500 && isDefaultTitle ? 'limit-exceeded' : ''}>{displayCharacterLimit(value)}</Label>
				</div>
			)}
		</FormGroup>
	);
};
