import TeamModel from '../../../../partials/team-select/models/team.model';
import { FansUnitedWidgetJson } from './fans-united-widget.json';
import FansUnitedWidgetBuilder from './fans-united-widget.builder';
import MatchModel from '../../../../partials/match-select/models/match.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import FansUnitedGameModel from './fans-united-game.model';
import FansUnitedLeaderboardTemplatesModel from './fans-united-leaderboard-templates.model';
import FansUnitedClassicQuizModel from './fans-united-classic-quiz.model';
import FansUnitedEitherOrModel from './fans-united-either-or.model';
import FansUnitedPollModel from './fans-united-poll.model';

/**
 * Match property is used for events from the football api, game property is used for events from the FU predictions api
 */
export default class FansUnitedWidgetModel {
	readonly match: MatchModel;
	readonly game: FansUnitedGameModel;
	readonly template: FansUnitedLeaderboardTemplatesModel;
	readonly classicQuiz: FansUnitedClassicQuizModel;
	readonly eitherOrGame: FansUnitedEitherOrModel;
	readonly poll: FansUnitedPollModel;
	readonly teams: any[];
	readonly player: PlayerModel;
	readonly teamAgainst: TeamModel;
	readonly market: any = '';
	readonly displayOdds: boolean = true;

	private constructor(
		match: MatchModel,
		game: FansUnitedGameModel,
		template: FansUnitedLeaderboardTemplatesModel,
		classicQuiz: FansUnitedClassicQuizModel,
		eitherOrGame: FansUnitedEitherOrModel,
		poll: FansUnitedPollModel,
		teams: TeamModel[],
		player: PlayerModel,
		teamAgainst: TeamModel,
		market: boolean,
		displayOdds: boolean,
	) {
		this.match = match;
		this.game = game;
		this.template = template;
		this.classicQuiz = classicQuiz;
		this.eitherOrGame = eitherOrGame;
		this.poll = poll;
		this.teams = teams;
		this.player = player;
		this.teamAgainst = teamAgainst;
		this.market = market;
		this.displayOdds = displayOdds;
	}

	toJson(): FansUnitedWidgetJson {
		return {
			match: this.match,
			game: this.game,
			template: this.template,
			classicQuiz: this.classicQuiz,
			eitherOrGame: this.eitherOrGame,
			poll: this.poll,
			teams: this.teams,
			player: this.player,
			teamAgainst: this.teamAgainst,
			market: this.market,
			displayOdds: this.displayOdds,
		};
	}

	static fromJson(json: FansUnitedWidgetJson): FansUnitedWidgetModel {
		return new FansUnitedWidgetModel(
			json.match,
			json.game,
			json.template,
			json.classicQuiz,
			json.eitherOrGame,
			json.poll,
			json.teams,
			json.player,
			json.teamAgainst,
			json.market,
			json.displayOdds,
		);
	}

	static builder(fansUnitedWidget?: FansUnitedWidgetModel): FansUnitedWidgetBuilder {
		return new FansUnitedWidgetBuilder(fansUnitedWidget);
	}
}
