import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import FootballMatchesH2hModel from '../models/football-matches-h2h.model';
import LimitInput from '../../../../partials/limit-input/limit-input.component';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import {
	MATCHES_H2H_HEADER_DEFAULT_OPTIONS,
	onSelectionChangeFromMainComponent,
} from '../../../partials/header-default-option-input/helper';
import HeaderDefaultOptionWrapper from '../../../partials/header-default-option-input/header-default-option-wrapper';
import HeaderDisplayInput from '../../../partials/header-display-input/header-display-input.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballMatchesH2hModel;
	isValid: boolean;
};

export default class FootballMatchesH2HEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballMatchesH2H,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballMatchesH2hModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeam1Change = (team: TeamModel) => {
		this.updateWidgetModelState(FootballMatchesH2hModel.builder(this.state.widgetModel).withTeamOne(team).build());
	};

	onTeam2Change = (team: TeamModel) => {
		this.updateWidgetModelState(FootballMatchesH2hModel.builder(this.state.widgetModel).withTeamTwo(team).build());
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(FootballMatchesH2hModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballMatchesH2hModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(FootballMatchesH2hModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build());
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(
			selectedHeaderDefaultOption,
			FootballMatchesH2hModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		return (
			<>
				<div data-qa='football-matches-h2h-block-edit'>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team1 ? widgetModel.team1 : {}}
									onTeamSelect={(team: TeamModel) => this.onTeam1Change(team)}
									t={t}
									isClearable
									isRequired={true}
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-1`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team2 ? widgetModel.team2 : {}}
									onTeamSelect={(team: TeamModel) => this.onTeam2Change(team)}
									t={t}
									isClearable={true}
									isRequired
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-2`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<hr />
          <Row className='mb-2'>
						<Col>
							<FormGroup>
								<LimitInput onLimitChange={this.onLimitChange} value={this.state.widgetModel.limit} isValid={isValid} isRequired />
							</FormGroup>
						</Col>
					</Row>
          <Row className='mb-2'>
            <Col>
              <HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
            </Col>
          </Row>
					<hr />
          <HeaderDefaultOptionWrapper
            options={MATCHES_H2H_HEADER_DEFAULT_OPTIONS}
            value={this.state.widgetModel.headerDefaultOption}
            onOptionChange={this.onHeaderDefaultOptionChange}
          />
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
