import { Bulgarian } from 'flatpickr/dist/l10n/bg';

export const langs = {
	'bg-BG': Bulgarian,
	'en-US': 'en',
};

export const dateFormat = 'Y.m.d, H:i';

export const dateFormatWithoutHours = 'Y.m.d'
