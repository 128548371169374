import { StandingsWidgetV2Json } from './standings-widget-v2.json';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import GroupModel from '../../../../../../../models/v2/Group/entity/group.model';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StandingsWidgetV2Builder from './standings-widget-v2.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';

export default class StandingsWidgetV2Model {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly group: GroupModel[];
	readonly refreshTime: RefreshTimeValuesType;
	readonly highlightTeams: TeamModel[];
	readonly offset: string;
	readonly limit: string;
	readonly displayHeader: boolean = false;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		group: GroupModel[],
		refreshTime: RefreshTimeValuesType,
		highlightTeams: TeamModel[],
		offset: string,
		limit: string,
		displayHeader: boolean,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.group = group;
		this.refreshTime = refreshTime;
    this.highlightTeams = highlightTeams;
    this.offset = offset;
    this.limit = limit;
		this.displayHeader = displayHeader;

	}

	toJson(): StandingsWidgetV2Json {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			group: this.group,
			refreshTime: this.refreshTime,
			highlightTeams: this.highlightTeams,
			offset: this.offset,
			limit: this.limit,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: StandingsWidgetV2Json): StandingsWidgetV2Model {
		return new StandingsWidgetV2Model(json.tournament, json.season, json.stage, json.group, json.refreshTime, 	json.highlightTeams,
      json.offset,
      json.limit, json.displayHeader);
	}

	static builder(standingsWidget?: StandingsWidgetV2Model): StandingsWidgetV2Builder {
		return new StandingsWidgetV2Builder(standingsWidget);
	}
}
