import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { FootballMatchCenterJson } from './football-match-center.json';
import FootballMatchCenterBuilder from './football-match-center.builder';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballMatchCenterModel {
	readonly match: MatchModel;
	readonly teams: TeamModel[];
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		match: MatchModel,
		teams: TeamModel[],
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.match = match;
		this.teams = teams;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballMatchCenterJson {
		return {
			match: this.match,
			teams: this.teams,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballMatchCenterJson): FootballMatchCenterModel {
		return new FootballMatchCenterModel(json.match, json.teams, json.displayOdds, json.bookmakers, json.refreshTime);
	}

	static builder(footballMatchCenterWidget?: FootballMatchCenterModel): FootballMatchCenterBuilder {
		return new FootballMatchCenterBuilder(footballMatchCenterWidget);
	}
}
