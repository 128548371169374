import React from 'react';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { useTranslation } from 'react-i18next';
import { EitherOrManager } from 'fansunited-widget-either-or';
import './fans-united-styles.scss';

export const EitherOrManagerComponent: React.FunctionComponent = () => {
	const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const [t] = useTranslation();

	const labels = {
		userNotFound: t('fu_userNotFound'),
		noGamesFound: t('fu_noGamesFoundEitherOr'),
		signOut: t('fu_signOut'),
		timeFormat: t('fu_timeFormat'),
		getEitherOrsErrorMessage: t('fu_getEitherOrsErrorMessage'),
		title: t('title'),
		status: t('status'),
		creationDate: t('fu_creationDate'),
		actions: t('actions'),
		create: t('fu_create'),
		edit: t('fu_edit'),
		save: t('fu_save'),
		preview: t('fu_previewEitherOr'),
		close: t('close'),
		titlePlaceholder: t('fu_titlePlaceholderEitherOr'),
		description: t('description'),
		descriptionPlaceholder: t('fu_descriptionPlaceholderEitherOr'),
		images: t('fu_images'),
		rules: t('fu_rules'),
		rulesPlaceholder: t('fu_rulesPlaceholderEitherOr'),
		adContent: t('fu_adContent'),
		adContentPlaceholder: t('fu_adContentPlaceholderEitherOr'),
		optionImages: t('fu_optionImages'),
		main: t('fu_main'),
		cover: t('fu_cover'),
		mobile: t('fu_mobile'),
		imagePlaceholder: t('fu_imagePlaceholder'),
		active: t('fu_active'),
		inactive: t('fu_inactive'),
		statusDescription: t('fu_statusDescription'),
		winningCondition: t('fu_winningCondition'),
		winningConditionDescription: t('fu_winningConditionDescription'),
		more: t('fu_more'),
		less: t('fu_less'),
		time: t('fu_time'),
		timePlaceholder: t('fu_timePlaceholder'),
		lives: t('fu_lives'),
		livesPlaceholder: t('fu_livesPlaceholder'),
		points: t('fu_points'),
		pointsDescription: t('fu_pointsDescriptionEitherOr'),
		pointNumber: t('fu_pointNumber'),
		pointsCorrectSteps: t('fu_pointsCorrectSteps'),
		pointsCorrectStepsPlaceholder: t('fu_pointsCorrectStepsPlaceholder'),
		pointsScore: t('fu_pointsScore'),
		pointsScorePlaceholder: t('fu_pointsScorePlaceholderEitherOr'),
		optionsDescription: t('fu_optionsDescriptionEitherOr'),
		optionsValue: t('fu_optionsValue'),
		optionsValuePlaceholder: t('fu_optionsValuePlaceholder'),
		repeatedValueMessage: t('fu_repeatedValueMessage'),
		flags: t('fu_flags'),
		flagsPlaceholder: t('fu_flagsPlaceholder'),
		labels: t('fu_labels'),
		customFields: t('fu_customFields'),
		dynamicFieldsDescription: t('fu_dynamicFieldsDescriptionEitherOr'),
		keyDynamicFields: t('fu_keyDynamicFields'),
		valueDynamicFields: t('fu_valueDynamicFields'),
		options: t('fu_options'),
		optionNumber: t('fu_optionNumber'),
		context: t('fu_context'),
		content: t('fu_content'),
		tags: t('fu_tags'),
		entity: t('fu_entity'),
		campaign: t('fu_campaign'),
		tagNumber: t('fu_tagNumber'),
		id: t('fu_id'),
		idContentPlaceholder: t('fu_idContentPlaceholder'),
		idCampaignPlaceholder: t('fu_idCampaignPlaceholder'),
		label: t('fu_label'),
		labelContentPlaceholder: t('fu_labelContentPlaceholder'),
		labelCampaignPlaceholder: t('fu_labelCampaignPlaceholder'),
		type: t('fu_type'),
		typeDescription: t('fu_typeDescriptionEitherOr'),
		noType: t('fu_noType'),
		deleteLabel: t('fu_deleteLabel'),
		cancelLabel: t('fu_cancelLabel'),
		confirmationQuestionLabel: t('fu_confirmationQuestionLabel'),
		typeContentPlaceholder: t('fu_typeContentPlaceholder'),
		previewEitherOr: t('fu_previewEitherOr'),
		getFootballEntitiesErrorMessage: t('fu_getFootballEntitiesErrorMessage'),
		createEitherOrMessage: t('fu_createEitherOrMessage'),
		createEitherOrErrorMessage: t('fu_createEitherOrErrorMessage'),
		titleRequiredFieldValidationMessage: t('fu_titleRequiredFieldValidationMessage'),
		livesFieldValidationMessage: t('fu_livesFieldValidationMessage'),
		timeFieldValidationMessage: t('fu_timeFieldValidationMessage'),
		pointsFieldValidationMessage: t('fu_pointsFieldValidationMessage'),
		optionsFieldValidationMessage: t('fu_optionsFieldValidationMessage'),
		tagsInvalidFieldValidationMessage: t('fu_tagsInvalidFieldValidationMessage'),
		getEitherOrErrorMessage: t('fu_getEitherOrErrorMessage'),
		deleteEitherOrMessage: t('fu_deleteEitherOrMessage'),
		deleteEitherOrErrorMessage: t('fu_deleteEitherOrErrorMessage'),
		updateEitherOrMessage: t('fu_updateEitherOrMessage'),
		updateEitherOrErrorMessage: t('fu_updateEitherOrErrorMessage'),
		uploadingImageMessage: t('fu_uploadingImageMessage'),
		generateSignedInUrlErrorMessage: t('fu_generateSignedInUrlErrorMessage'),
		uploadingImageErrorMessage: t('fu_uploadingImageErrorMessage'),
		encryptingImageErrorMessage: t('fu_encryptingImageErrorMessage'),
		clear: t('fu_clear'),
		searchSportal365ImagesErrorMessage: t('fu_searchSportal365ImagesErrorMessage'),
		searchImage: t('fu_searchImage'),
		clearFromToFilterSportal365ImagesMessage: t('fu_clearFromToFilterSportal365ImagesMessage'),
		hideFilters: t('fu_hideFilters'),
		showFilters: t('fu_showFilters'),
		dateFromTo: t('fu_dateFromTo'),
		dateFromToPlaceholder: t('fu_dateFromToPlaceholder'),
	};

	return config &&
		config.configuration &&
		config.configuration.api_key &&
		config.configuration.client_id &&
		config.configuration.language ? (
		<div className='fans-united-container'>
			<EitherOrManager
				fansUnitedApiKey={config.configuration.api_key}
				fansUnitedLanguage={config.configuration.language}
				fansUnitedClientId={config.configuration.client_id}
				labels={labels}
			/>
		</div>
	) : null;
};
