import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import { BasketballStandingsJson } from './basketball-standings.json';
import BasketballStandingsBuilder from './basketball-standings.builder';
import { RefreshTimeValuesType } from '../../../../v2/partials/refresh-time-input/helper';

export default class BasketballStandingsModel {
	readonly competition: CompetitionModel;
	readonly season: BasketballSeasonModel;
	readonly stage: BasketballStageModel;
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = false;

	private constructor(
		competition: CompetitionModel,
		season: BasketballSeasonModel,
		stage: BasketballStageModel,
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean,
	) {
		this.competition = competition;
		this.season = season;
		this.stage = stage;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): BasketballStandingsJson {
		return {
			competition: this.competition,
			season: this.season,
			stage: this.stage,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: BasketballStandingsJson): BasketballStandingsModel {
		return new BasketballStandingsModel(
			json.competition,
			json.season,
			json.stage,
			json.headerDefaultOption,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(standingsWidget?: BasketballStandingsModel): BasketballStandingsBuilder {
		return new BasketballStandingsBuilder(standingsWidget);
	}
}
