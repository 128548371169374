import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballMatchesH2hBuilder from '../models/football-matches-h2h.builder';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import { generateDisplayHeaderOptionViewText } from '../../../partials/header-display-input/helper';
import { generateHeaderDefaultOptionViewText } from '../../../partials/header-default-option-input/helper';

type Properties = {
	preview: FootballMatchesH2hBuilder | any;
	t: any;
};

export const FootballMatchesH2hViewComponent: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview.team1 && preview.team1.id && preview.team2 && preview.team2.id) {
		return (
			<>
				<Row data-qa='football-matches-h2h-view' className={'pb-4 pl-4 pr-4'}>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team1 && preview.team1.urlLogo && preview.team1.urlLogo}
							alt={preview.team1.name}
						/>
						<h4>{preview.team1.name}</h4>
					</Col>
					<Col className='mr-3 d-flex flex-column justify-content-center align-items-center'>
						<span>{t('vs')}</span>
					</Col>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team2 && preview.team2.urlLogo && preview.team2.urlLogo}
							alt={preview.team2.name}
						/>
						<h4>{preview.team2.name}</h4>
					</Col>
				</Row>
				<Row className={'pb-4 pl-4 pr-4'}>
					{preview.limit && preview.limit.length > 0 && (
						<Col>
							<strong>{t('limit')}:</strong>
							<span className='pl-1'>{preview.limit}</span>
						</Col>
					)}
				</Row>
				{generateDisplayHeaderOptionViewText(preview.displayHeader)}
				{generateHeaderDefaultOptionViewText(preview.headerDefaultOption)}
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
