import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import Seo from '../../../../models/seo/Seo';
import Global from '../../../Partials/Blocky/helpers/global.helpers';
import Author from '../../../../models/author/Author';
import AuthorSocialModel from '../../../../models/v2/author/social.model';

export function seoComponentModelToAuthor(seoModel: SeoComponentModel, author: Author): Author {
	const seo: Seo = Seo.builder(author.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.withJsonLD(seoModel.jsonld)
		.build();

	return Author.builder(author).withSeo(seo).build();
}

export function authorToSeoComponentModelForCreate(author: Author): SeoComponentModel {
	return {
		keywords: author.seo && author.seo.keywords ? author.seo.keywords : [],
		following: author.seo ? author.seo.follow : true,
		indexing: author.seo ? author.seo.index : true,
		title: author.seo && author.seo.title ? Global.stripEmoji(author.seo.title) : '',
		description: author.seo && author.seo.description ? author.seo.description : '',
		slug: author.seo && author.seo.slug ? Global.stripEmoji(author.seo.slug) : '',
		contentTitle: author && author.name ? Global.stripEmoji(author.name) : '',
		autoSlug: author && author.seo ? author.seo.autoSlug : false,
		autoTitle: author && author.seo ? author.seo.autoTitle : false,
		jsonld: author && author.seo && author.seo.jsonld ? author.seo.jsonld : '',
	};
}

export function authorToSeoComponentModelForEdit(author: Author): SeoComponentModel {
	if (author) {
		return {
			keywords: author.seo && author.seo.keywords ? author.seo.keywords : [],
			following: author.seo ? author.seo.follow : true,
			indexing: author.seo ? author.seo.index : true,
			title: author.seo && author.seo.title ? Global.stripEmoji(author.seo.title) : '',
			description: author.seo && author.seo.description ? author.seo.description : '',
			slug: author.seo && author.seo.slug ? Global.stripEmoji(author.seo.slug) : '',
			contentTitle: author.name ? Global.stripEmoji(author.name) : '',
			autoSlug: author && author.seo && author.seo.autoSlug !== undefined ? author.seo.autoSlug : true,
			autoTitle: author && author.seo && author.seo.autoTitle !== undefined ? author.seo.autoTitle : true,
			jsonld: author.seo && author.seo.jsonld ? author.seo.jsonld : '',
		};
	}

	return {} as SeoComponentModel;
}

export const populateAuthorSocials = (
	authorSocials: AuthorSocialModel[],
	defaultAuthorSocials: AuthorSocialModel[],
): AuthorSocialModel[] => {
	const mergedSocials: AuthorSocialModel[] = [];

	defaultAuthorSocials.forEach((defaultSocial) => {
		const authorSocial = authorSocials.find((social) => social.slug === defaultSocial.slug);
		authorSocial ? mergedSocials.push({ ...authorSocial }) : mergedSocials.push({ ...defaultSocial });
	});

	return mergedSocials;
};

export const socialLinksMerge = (
	isCreateScreen: boolean,
	defaultSocialsRedux: AuthorSocialModel[],
	socialLinksRedux: AuthorSocialModel[],
) => {
	return isCreateScreen ? defaultSocialsRedux : populateAuthorSocials(socialLinksRedux, defaultSocialsRedux);
};
