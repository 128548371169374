import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import './search-date.scss';
import { formatGeneralDateAsString } from '../../../../global-helpers/global-dates.helper';
import { SelectOption } from '../select/select';

type Props = {
	invokeFunc: (selectedDate: Date | null, competitionList: SelectOption) => void;
	fieldId: string;
	date: Date | null;
	labelText?: string;
	className?: string;
	selectedCompetitionList: SelectOption;
};

const selectNearDate = (date: Date | null, nextDayFlag: boolean) => {
	if (!date) {
		return null;
	}

	const dateValue = new Date(date);
	const value = nextDayFlag ? 1 : -1;
	dateValue.setDate(dateValue.getDate() + value);
	return dateValue;
};

export const SearchDateDesign: FunctionComponent<Props> = ({
	invokeFunc,
	fieldId,
	labelText,
	date,
	className,
	selectedCompetitionList,
}) => {
	const [dateValue, setDateValue] = useState<Date | null>(date);

	const changeDate = (selectedDate: Date | null) => {
		setDateValue(selectedDate);
		invokeFunc(selectedDate, selectedCompetitionList);
	};

	const changeDateFromInput = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedDate = event.currentTarget.valueAsDate;
		changeDate(selectedDate);
	};

	const selectNearDay = (nextDayFlag: boolean) => {
		if (dateValue) {
			const nearDay = selectNearDate(dateValue, nextDayFlag);
			changeDate(nearDay);
		}
	};

	return (
		<div className={className}>
			<label htmlFor={fieldId}> {labelText} </label>
			<div className='custom-search-date-input-wrapper'>
				<div className='custom-search-date-input-left-arrow' onClick={() => selectNearDay(false)}>
					<i className='fas fa-chevron-left'></i>
				</div>
				<input
					type='date'
					onChange={changeDateFromInput}
					id={fieldId}
					className='custom-search-date-input'
					value={dateValue ? formatGeneralDateAsString(dateValue) : undefined}
				/>
				<div className='custom-search-date-input-right-arrow' onClick={() => selectNearDay(true)}>
					<i className='fas fa-chevron-right'></i>
				</div>
			</div>
		</div>
	);
};

export default SearchDateDesign;
