import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import FansUnitedEitherOrModel from './fans-united-either-or.model';
import FansUnitedPollModel from './fans-united-poll.model';

export default class FansUnitedPollsMapper {
	public responseToModel = (response: Response): FansUnitedPollModel => {
		const serializer = new TypedJSON(FansUnitedPollModel);
		const remmapedPoll = serializer.parse(response) as FansUnitedPollModel;
		remmapedPoll['isSelected'] = false;

		return remmapedPoll;
	};
	public modelToRequestBody = (model: FansUnitedPollModel) => {
		if (model) {
			return {
				id: model.id,
				title: model.title,
				description: model.description,
				type: model.type,
				status: model.status,
				images: model.images,
				labels: model.labels,
				options: model.options,
				flags: model.flags,
				total_votes: model.total_votes,
				custom_fields: model.custom_fields,
				branding: model.branding,
				embed_code: model.embed_code,
				created_at: model.created_at,
				updated_at: model.updated_at,
			};
		}
		return new FansUnitedPollModel();
	};
}
