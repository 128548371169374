import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import MainImage from './subcomponents/main-media/main-image';
import ImageListing from './subcomponents/toggle-images/image-listing/image-listing';
import ImagoImageListing from './subcomponents/toggle-images/imago-image-listing/imago-image-listing';
import UploadMainImage from './subcomponents/buttons/upload-image';
import EditMainImage from './subcomponents/buttons/edit-image';
import CropMainImage from './subcomponents/buttons/crop-image';
import ExternalMainMediaContainer from './subcomponents/external-main-media/container';
import MainImageErrors from './subcomponents/errors/errors';
import GettyImageListing from '../../../v2/getty/components/main-media/getty-image-listing';
import { extractMainContentTypeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import { checkIfFeatureEnabledAndCorrectContentType } from './subcomponents/additional-main-media-images/helpers/additional-main-media-images.helper';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import { MainMediaMetadataVersionsAdditionalImageSelect } from './subcomponents/additional-main-media-images/sub-components/main-media-metadata-versions-additional-images.component';

type Properties = {
	t: any;
	areMandatoryFieldsFilled?: boolean;
	updateMandatoryFieldsFilled: (value: boolean) => void;
	onChange?: (mainMedia: MainMedia[]) => void;
	value?: MainMedia[];
};

const SidebarMainMedia: FunctionComponent<Properties> = ({ t, areMandatoryFieldsFilled, updateMandatoryFieldsFilled, onChange, value }) => {
	return (
		<>
			<MainImage t={t} />
			<UploadMainImage t={t} />
			<EditMainImage t={t} />
			<CropMainImage t={t} />
			<MainImageErrors t={t} areMandatoryFieldsFilled={areMandatoryFieldsFilled} updateMandatoryFieldsFilled={updateMandatoryFieldsFilled} />
			<ImageListing t={t} />
			<ImagoImageListing t={t} />
			<GettyImageListing />
			<Row>
				<Col className='text-center my-2'>
					<hr />
				</Col>
			</Row>
			<ExternalMainMediaContainer t={t} />
			{checkIfFeatureEnabledAndCorrectContentType(extractMainContentTypeBasedOnUrl() || '') && (
				<div>
					<hr className='pad2x pad2y mt-4' />
					<MainMediaMetadataVersionsAdditionalImageSelect mainMedia={value ? value : []} onChange={onChange} />
				</div>
			)}
		</>
	);
};

export default SidebarMainMedia;
