import BlockConfigModel from '../../../models/block-config.model/block-config.model';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import BlockModel from '../../../models/block.model';
import { store } from '../../../../../../store/store';
import { WidgetsV2Ids } from '../../../constants/block.types';
import GroupModel from '../../../../../../models/v2/Group/entity/group.model';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import moment from 'moment';
import {
	addValueIfNotEmpty,
	constructBasketballEventWidgetContent,
	constructBasketballTournamentWidgetContent,
	constructIceHockeyEventWidgetContent,
	constructIceHockeyStandingsWidgetContent,
	constructIceHockeyWidgetContent,
	constructTennisPlayoffWidgetContent,
	constructTennisProgrammeWidgetContent,
	constructTennisSingleEventContent,
	constructTennisTournamentWidget,
	getFormattedContentData,
	sportsConfigFromProject,
} from './block-utility.helper';
import { SportTypes } from '../../../../../../constants/sport.types';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import { WIDGET_ATTRIBUTE_NAMES } from './component-attributes.constants';
import { extractColor } from '../../../blocks/widgets/match/components/helpers/color-picker.helper';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import { MARKET_TYPES } from '../../../constants/odds.constants';
import SportBookmakerModel from '../../../../../../models/sport-odds/sport-bookmaker.model';
import RoundModel from '../../../../../../models/v2/round/entity/round-model';
import BasketballRoundModel from '../../../../../../models/v2/round/entity/basketball-round.model';
import { MatchTypes } from '../football-team/constants/football-team.constants';
import RankingPlayerModel from '../../../../../../models/v2/ranking-player/ranking-player.model';
import { isValueEmpty } from '../../../../../../global-helpers/global.helpers';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';

export const checkIfElementSelected = (element: string) => {
	return element && element.length > 0;
};

export const checkIfCorrectWidgetType = (widgetId: string, correctWidgetIds: string) => {
	return widgetId === correctWidgetIds;
};

const setFansUnitedEnabled = (block: BlockConfigModel, widgetId: string) => {
	if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		block.options.fansUnitedEnabled
	) {
		return constructDataAttribute(block, block.options.fansUnitedEnabled, WIDGET_ATTRIBUTE_NAMES.FANS_UNITED_ENABLED);
	} else if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		!block.options.fansUnitedEnabled
	) {
		return ` fans-united-enabled='${false}'`;
	} else {
		return '';
	}
};

const setFansUnitedExpanded = (block: BlockConfigModel, widgetId: string) => {
	if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		block.options.fansUnitedEnabled &&
		block.options.fansUnitedExpanded
	) {
		return constructDataAttribute(block, block.options.fansUnitedExpanded, WIDGET_ATTRIBUTE_NAMES.FANS_UNITED_EXPANDED);
	} else if (
		widgetId &&
		(checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballTeam) ||
			checkIfCorrectWidgetType(widgetId, WidgetsV2Ids.widgetFootballPlayer)) &&
		(!block.options.fansUnitedEnabled || !block.options.fansUnitedExpanded)
	) {
		return ` fans-united-expanded='${false}'`;
	} else {
		return ``;
	}
};

const footballWidgetIdsWithOddsOptions = [
	WidgetsV2Ids.widgetFootballSingleEvent,
	WidgetsV2Ids.widgetFootballLivescore,
	WidgetsV2Ids.widgetFootballPlayer,
	WidgetsV2Ids.widgetFootballTeam,
	WidgetsV2Ids.widgetFootballTournamentProgramme,
	WidgetsV2Ids.widgetFootballOdds,
	WidgetsV2Ids.widgetFootballTeamProgramme,
	WidgetsV2Ids.widgetFootballStakes,
	WidgetsV2Ids.widgetFootballSingleRound,
	WidgetsV2Ids.widgetFootballTeamH2HMatch,
	WidgetsV2Ids.widgetFootballMatchCenter,
];

const basketballWidgetIdsWithOddsOptions = [
	WidgetsV2Ids.widgetBasketballSingleEvent,
	WidgetsV2Ids.widgetBasketballLivescore,
	WidgetsV2Ids.widgetBasketballTournamentProgramme,
	WidgetsV2Ids.widgetBasketballTeamProgramme,
];

const tennisWidgetIdsWithOddsOptions = [
	WidgetsV2Ids.widgetTennisSingleEvent,
	WidgetsV2Ids.widgetTennisLivescore,
	WidgetsV2Ids.widgetTennisAthleteProgramme,
	WidgetsV2Ids.widgetTennisTournamentProgramme,
];

const iceHockeyIdsWithOddsOptions = [WidgetsV2Ids.widgetIceHockeyLivescore, WidgetsV2Ids.widgetIceHockeySingleEvent];

export const widgetIdsWithOddsOptions = [
	...footballWidgetIdsWithOddsOptions,
	...basketballWidgetIdsWithOddsOptions,
	...tennisWidgetIdsWithOddsOptions,
	...iceHockeyIdsWithOddsOptions,
];

const footballWidgetIdsWithFansUnitedOptions = [WidgetsV2Ids.widgetFootballSingleEvent];

export const buildOddsDataAttributes = (config: BlockConfigModel, marketsSelectionFlag: boolean = false): Record<string, string> => {
	const widgetId = config.widgetId;
	if (widgetIdsWithOddsOptions.includes(widgetId as WidgetsV2Ids)) {
		let oddsDisplay =
			config.widgetId !== WidgetsV2Ids.widgetFootballOdds
				? constructDataAttribute(config, config.options.displayOdds, WIDGET_ATTRIBUTE_NAMES.ODDS_DISPLAY)
				: '';
		let oddsBettingId = config.options.displayOdds ? buildOddProviderIds(config, config.widgetId) : '';
		let oddsMarket =
			marketsSelectionFlag && config.options.displayOdds
				? constructDataAttribute(config, config.options.market, WIDGET_ATTRIBUTE_NAMES.MARKET)
				: '';
		let marketValueType =
			config.options.displayOdds && config.options.marketValueType
				? constructDataAttribute(config, config.options.marketValueType.value, WIDGET_ATTRIBUTE_NAMES.ODDS_MARKET_VALUE_TYPE)
				: '';

		// Edge case for Team H2H because one configuration has odds and the other doesn't have
		if (!config.options.matchId && widgetId === WidgetsV2Ids.widgetFootballTeamH2H) {
			return { oddsDisplay: '', oddsBettingId: '', oddsMarket: '' };
		}

		// Edge case for odds widget where display odds is not applied
		if (widgetId === WidgetsV2Ids.widgetFootballOdds) {
			oddsBettingId = buildOddProviderIds(config, config.widgetId);
			oddsMarket =
				marketsSelectionFlag && config.options.market
					? constructDataAttribute(config, config.options.market.value, WIDGET_ATTRIBUTE_NAMES.MARKET)
					: '';
			marketValueType = constructDataAttribute(config, config.options.marketValueType.value, WIDGET_ATTRIBUTE_NAMES.ODDS_MARKET_VALUE_TYPE);
		}

		return { oddsDisplay, oddsBettingId, oddsMarket, marketValueType };
	}

	return { oddsDisplay: '', oddsBettingId: '', oddsMarket: '', marketValueType: '' };
};

export const buildFansUnitedDataAttributes = (config: BlockConfigModel): Record<string, string> => {
	const widgetId = config.widgetId;
	const fansUnitedConfig = featuresService && featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	if (footballWidgetIdsWithFansUnitedOptions.includes(widgetId as WidgetsV2Ids)) {
		const idSchema =
			config.options.fansUnitedEnabled &&
			fansUnitedConfig.configuration &&
			fansUnitedConfig.configuration.id_schema &&
			constructDataAttribute(config, fansUnitedConfig.configuration.id_schema, WIDGET_ATTRIBUTE_NAMES.ID_SCHEMA);

		const apiKey =
			config.options.fansUnitedEnabled &&
			fansUnitedConfig.configuration &&
			fansUnitedConfig.configuration.api_key &&
			constructDataAttribute(config, fansUnitedConfig.configuration.api_key, WIDGET_ATTRIBUTE_NAMES.API_KEY);

		const environment =
			config.options.fansUnitedEnabled &&
			fansUnitedConfig.configuration &&
			fansUnitedConfig.configuration.environment &&
			constructDataAttribute(config, fansUnitedConfig.configuration.environment, WIDGET_ATTRIBUTE_NAMES.ENVIRONMENT);
		const clientId =
			config.options.fansUnitedEnabled &&
			fansUnitedConfig.configuration &&
			fansUnitedConfig.configuration.client_id &&
			constructDataAttribute(config, fansUnitedConfig.configuration.client_id, WIDGET_ATTRIBUTE_NAMES.CLIENT_ID);
		const fansUnitedEnabled = config.options.fansUnitedEnabled
			? constructDataAttribute(config, config.options.fansUnitedEnabled, WIDGET_ATTRIBUTE_NAMES.FANS_UNITED_ENABLED)
			: ` fans-united-enabled='${config.options.fansUnitedEnabled}'`;
		const fansUnitedExpanded =
			config.options.fansUnitedEnabled && config.options.fansUnitedExpanded
				? constructDataAttribute(config, config.options.fansUnitedExpanded, WIDGET_ATTRIBUTE_NAMES.FANS_UNITED_EXPANDED)
				: ` fans-united-expanded='${config.options.fansUnitedExpanded}'`;

		return { idSchema, apiKey, environment, clientId, fansUnitedEnabled, fansUnitedExpanded };
	}

	return { idSchema: '', apiKey: '', environment: '', clientId: '', fansUnitedEnabled: '', fansUnitedExpanded: '' };
};

const buildOddProviderIds = (config: BlockConfigModel, widgetId: string) => {
	if (widgetId === WidgetsV2Ids.widgetFootballLineups && config.options.oddProviderIds.length > 0) {
		return ` ${WIDGET_ATTRIBUTE_NAMES.BETTING_ID}'[${config.options.oddProviderIds}]'`;
	} else {
		return constructDataAttribute(config, config.options.oddProviderIds, WIDGET_ATTRIBUTE_NAMES.BETTING_ID);
	}
};

const tabsOptionsToJson = (tabs: string[]): string => {
	const dataObj = tabs.reduce((acc: Record<string, string>, tab: string) => ((acc[tab] = tab.toLocaleUpperCase()), acc), {});
	return JSON.stringify(dataObj);
};

export const constructDataAttribute = (config: BlockConfigModel, attributeValue: any, attributeName: string) => {
	switch (attributeName) {
		case WIDGET_ATTRIBUTE_NAMES.GROUPS:
			return config.options.groups && config.options.groups.length > 0 ? ` ${attributeName}'[${attributeValue}]'` : '';
		case WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_PLAYERS:
			return config.options.highlightPlayers && config.options.highlightPlayers.length > 0 ? ` ${attributeName}'[${attributeValue}]'` : '';
		case WIDGET_ATTRIBUTE_NAMES.ROUNDS:
			return config.options.rounds && config.options.rounds.length > 0 ? ` ${attributeName}'[${attributeValue}]'` : '';
		case WIDGET_ATTRIBUTE_NAMES.TEAM_IDS: {
			let ids = config.options.teamIds.map((element: any) => {
				return `"${element}"`;
			});
			return config.options.teamIds && config.options.teamIds.length > 0 ? ` ${attributeName}'[${ids}]'` : '';
		}
		case WIDGET_ATTRIBUTE_NAMES.TABS:
			return config.options.tabs && config.options.tabs.length > 0
				? `${WIDGET_ATTRIBUTE_NAMES.TABS}'${tabsOptionsToJson(config.options.tabs)}'`
				: '';
		case WIDGET_ATTRIBUTE_NAMES.ODDS_DISPLAY:
		case WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY:
			return typeof attributeValue === 'boolean' ? ` ${attributeName}'${attributeValue}'` : ` ${attributeName}'false'`;
		case WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES:
			return config.options.highlightTeams && config.options.highlightTeams.length > 0 ? ` ${attributeName}'[${attributeValue}]'` : '';
		default:
			return !isValueEmpty(attributeValue) ? ` ${attributeName}'${attributeValue}'` : '';
	}
};

const constructElementsDataAttribute = (config: BlockConfigModel, attributeValue: any, attributeName: string, widgetId: string) => {
	let elements: any;

	switch (widgetId) {
		case WidgetsV2Ids.widgetFootballMostDecoratedPlayers:
			elements = config.options.elements.map((element: string) => {
				return `"${element}"`;
			});
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'[${elements}]'` : '';
		case WidgetsV2Ids.widgetFootballTopScorers:
			elements = config.options.elements.map((element: string) => {
				return `"${element}"`;
			});
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'[${elements}]'` : '';
		case WidgetsV2Ids.widgetFootballTeam:
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'${config.options.elements}'` : '';
		case WidgetsV2Ids.widgetFootballLineups:
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'[${config.options.elements}]'` : '';
		case WidgetsV2Ids.widgetFootballPlayer:
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'${config.options.elements}'` : '';
		case WidgetsV2Ids.widgetFootballPlayerH2H:
			elements = config.options.elements.map((element: string) => {
				return `"${element}"`;
			});
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'[${elements}]'` : '';
		case WidgetsV2Ids.widgetFootballTeamH2H:
		case WidgetsV2Ids.widgetFootballTeamSquad:
		case WidgetsV2Ids.widgetFootballTeamH2HMatch:
			elements = config.options.elements.map((element: string) => {
				return `"${element}"`;
			});
			return config.options.elements && config.options.elements.length > 0 ? ` ${attributeName}'[${elements}]'` : '';
		default:
			return ``;
	}
};

const constructFootballTournamentWidget = (config: BlockConfigModel) => {
	const fansUnitedConfig = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const checkIfPlayerProfile = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballPlayer);
	const checkIfTeamProfile = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTeam);
	const checkIfPlayerH2H = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballPlayerH2H);
	const checkIfTeamH2H =
		checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTeamH2H) ||
		checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTeamH2HMatch);
	const checkIfTeamTopScorers = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTopScorers);
	const checkIfProgramme =
		checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTournamentProgramme) ||
		checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballTeamProgramme);
	const checkIfMatchesH2H = checkIfCorrectWidgetType(config.widgetId, WidgetsV2Ids.widgetFootballMatchesH2H);

	const idSchema =
		config.options.fansUnitedEnabled &&
		(checkIfPlayerProfile || checkIfTeamProfile) &&
		fansUnitedConfig.configuration &&
		fansUnitedConfig.configuration.id_schema
			? constructDataAttribute(config, fansUnitedConfig.configuration.id_schema, WIDGET_ATTRIBUTE_NAMES.ID_SCHEMA)
			: ``;

	const apiKey =
		config.options.fansUnitedEnabled &&
		(checkIfPlayerProfile || checkIfTeamProfile) &&
		fansUnitedConfig.configuration &&
		fansUnitedConfig.configuration.api_key
			? constructDataAttribute(config, fansUnitedConfig.configuration.api_key, WIDGET_ATTRIBUTE_NAMES.API_KEY)
			: ``;

	const environment =
		config.options.fansUnitedEnabled &&
		(checkIfPlayerProfile || checkIfTeamProfile) &&
		fansUnitedConfig.configuration &&
		fansUnitedConfig.configuration.environment
			? constructDataAttribute(config, fansUnitedConfig.configuration.environment, WIDGET_ATTRIBUTE_NAMES.ENVIRONMENT)
			: ``;
	const clientId =
		config.options.fansUnitedEnabled &&
		(checkIfPlayerProfile || checkIfTeamProfile) &&
		fansUnitedConfig.configuration &&
		fansUnitedConfig.configuration.client_id
			? constructDataAttribute(config, fansUnitedConfig.configuration.client_id, WIDGET_ATTRIBUTE_NAMES.CLIENT_ID)
			: ``;
	const fansUnitedEnabled = setFansUnitedEnabled(config, config.widgetId);
	const fansUnitedExpanded = setFansUnitedExpanded(config, config.widgetId);
	const dataConfigCompetition = constructDataAttribute(config, config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config, config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config, config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const dataConfigRound = constructDataAttribute(config, config.options.roundId, WIDGET_ATTRIBUTE_NAMES.ROUND);
	const dataConfigRounds =
		config.options.rounds &&
		config.options.rounds.length > 0 &&
		constructDataAttribute(config, config.options.rounds, WIDGET_ATTRIBUTE_NAMES.ROUNDS);
	const dataConfigGroups =
		config.options.groups &&
		config.options.groups.length > 0 &&
		constructDataAttribute(config, config.options.groups, WIDGET_ATTRIBUTE_NAMES.GROUPS);
	const dataConfigTeam = constructDataAttribute(config, config.options.teamId, WIDGET_ATTRIBUTE_NAMES.TEAM);
	const dataConfigPlayer = constructDataAttribute(config, config.options.playerId, WIDGET_ATTRIBUTE_NAMES.PLAYER);
	const dataConfigMatch = constructDataAttribute(config, config.options.matchId, WIDGET_ATTRIBUTE_NAMES.MATCH);

	const { oddsDisplay, oddsBettingId, oddsMarket } = buildOddsDataAttributes(config, true);

	const dataHeaderDisplay = constructDataAttribute(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const date =
		config.options.date &&
		config.options.date !== '' &&
		constructDataAttribute(
			config,
			JSON.stringify({ date: moment(config.options.date).format('DD.MM.YYYY'), dateFormat: 'DD.MM.YYYY' }),
			WIDGET_ATTRIBUTE_NAMES.DATE,
		);
	const displayTabs = constructDataAttribute(config, config.options.displayTabs, WIDGET_ATTRIBUTE_NAMES.DISPLAY_TABS);
	const defaultTab = constructDataAttribute(config, config.options.defaultTab, WIDGET_ATTRIBUTE_NAMES.DEFAULT_TAB);
	const tabs = constructDataAttribute(config, config.options.tabs, WIDGET_ATTRIBUTE_NAMES.TABS);
	const headerDefaultOption = constructDataAttribute(
		config,
		config.options.headerDefaultOption,
		WIDGET_ATTRIBUTE_NAMES.HEADER_DEFAULT_OPTION,
	);

	const limit = constructDataAttribute(config, config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT);
	const dataDateFrom = config.options.dataDateFrom
		? constructDataAttribute(
				config,
				`{"date": "${moment(config.options.dataDateFrom).format('YYYY-MM-DD')}", "dateFormat": "YYYY-MM-DD"}`,
				WIDGET_ATTRIBUTE_NAMES.DATA_DATE_FROM,
		  )
		: '';
	const dataDateTo = config.options.dataDateTo
		? constructDataAttribute(
				config,
				`{"date": "${moment(config.options.dataDateTo).format('YYYY-MM-DD')}", "dateFormat": "YYYY-MM-DD"}`,
				WIDGET_ATTRIBUTE_NAMES.DATA_DATE_TO,
		  )
		: '';
	const title = constructDataAttribute(config, config.options.title, WIDGET_ATTRIBUTE_NAMES.TITLE);
	const filter = constructDataAttribute(config, config.options.filter, WIDGET_ATTRIBUTE_NAMES.FILTER);
	const highlightPlayers = constructDataAttribute(config, config.options.highlightPlayers, WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_PLAYERS);
	const selections = constructDataAttribute(config, config.options.selections, WIDGET_ATTRIBUTE_NAMES.SELECTIONS);
	const matchType =
		config.options.matchType &&
		config.options.matchType !== MatchTypes.BOTH &&
		constructDataAttribute(config, config.options.matchType, WIDGET_ATTRIBUTE_NAMES.MATCH_TYPE);
	const startFromPosition = constructDataAttribute(config, config.options.startFromPosition, WIDGET_ATTRIBUTE_NAMES.START_POSITION);
	const elements =
		config.options.elements &&
		constructElementsDataAttribute(config, config.options.elements, WIDGET_ATTRIBUTE_NAMES.ELEMENTS, config.widgetId);

	const dataPlayerOne = JSON.stringify({ id: config.options.player1Id, seasonId: config.options.player1TournamentSeasonId });
	const dataPlayerTwo = JSON.stringify({ id: config.options.player2Id, seasonId: config.options.player2TournamentSeasonId });
	const dataPlayerSportEntityOne =
		checkIfPlayerH2H && constructDataAttribute(config, dataPlayerOne, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE);
	const dataPlayerSportEntityTwo =
		checkIfPlayerH2H && constructDataAttribute(config, dataPlayerTwo, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO);

	const sportEntityOne =
		config.options.team1Id && JSON.stringify({ id: config.options.team1Id.toString(), seasonId: config.options.team1TournamentSeasonId });
	const sportEntityTwo =
		config.options.team2Id && JSON.stringify({ id: config.options.team2Id.toString(), seasonId: config.options.team2TournamentSeasonId });
	const dataTeamSportEntityOne = checkIfTeamH2H && constructDataAttribute(config, sportEntityOne, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE);
	const dataTeamSportEntityTwo = checkIfTeamH2H && constructDataAttribute(config, sportEntityTwo, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO);
	const teamIds = checkIfTeamTopScorers && constructDataAttribute(config, config.options.teamIds, WIDGET_ATTRIBUTE_NAMES.TEAM_IDS);
	const dataConfigSortDirection =
		checkIfProgramme && constructDataAttribute(config, config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataConfigSortDirectionFixtures =
		checkIfProgramme && constructDataAttribute(config, config.options.sortDirectionFixtures, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_FIXTURES);
	const dataConfigSortDirectionResults =
		checkIfProgramme && constructDataAttribute(config, config.options.sortDirectionResults, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION_RESULTS);
	const dataMatchesH2HTeamOne =
		checkIfMatchesH2H && constructDataAttribute(config, config.options.team1Id, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_ONE);
	const dataMatchesH2HTeamTwo =
		checkIfMatchesH2H && constructDataAttribute(config, config.options.team2Id, WIDGET_ATTRIBUTE_NAMES.SPORT_ENTITY_TWO);
	const widgetRefreshTime = constructDataAttribute(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const dataConfigHighlightTeams = constructDataAttribute(config, config.options.highlightTeams, WIDGET_ATTRIBUTE_NAMES.HIGHLIGHT_ENTITIES);
	const offset = constructDataAttribute(config, config.options.offset, WIDGET_ATTRIBUTE_NAMES.OFFSET);

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.FOOTBALL}' data-widget-type='tournament'`;
	const configSettings = [
		dataConfigMatch,
		dataConfigPlayer,
		dataConfigCompetition,
		dataConfigSeason,
		dataConfigTeam,
		dataConfigStage,
		dataConfigGroups,
		dataConfigRound,
		dataConfigRounds,
		date,
		defaultTab,
		displayTabs,
		oddsMarket,
		headerDefaultOption,
		oddsDisplay,
		oddsBettingId,
		dataConfigSortDirection,
		dataConfigSortDirectionResults,
		dataConfigSortDirectionFixtures,
		idSchema,
		apiKey,
		clientId,
		fansUnitedEnabled,
		fansUnitedExpanded,
		environment,
		dataHeaderDisplay,
		dataPlayerSportEntityOne,
		dataPlayerSportEntityTwo,
		dataTeamSportEntityOne,
		dataTeamSportEntityTwo,
		dataMatchesH2HTeamOne,
		dataMatchesH2HTeamTwo,
		limit,
		dataDateFrom,
		dataDateTo,
		title,
		filter,
		highlightPlayers,
		selections,
		matchType,
		startFromPosition,
		elements,
		teamIds,
		tabs,
		widgetRefreshTime,
		dataConfigHighlightTeams,
		offset,
	];

	configSettings.forEach((item: any) => (contentData = addValueIfNotEmpty(item, contentData)));
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

const constructFootballSingleEventContent = (config: BlockConfigModel) => {
	const { idSchema, apiKey, environment, clientId, fansUnitedEnabled, fansUnitedExpanded } = buildFansUnitedDataAttributes(config);
	const { oddsDisplay, oddsBettingId, oddsMarket, marketValueType } = buildOddsDataAttributes(config, true);
	const homeTeamColor = constructDataAttribute(config, config.options.homeTeamColor, WIDGET_ATTRIBUTE_NAMES.HOME_TEAM_COLOR);
	const awayTeamColor = constructDataAttribute(config, config.options.awayTeamColor, WIDGET_ATTRIBUTE_NAMES.AWAY_TEAM_COLOR);
	const mainOddsProvider =
		config.options.displayOdds && constructDataAttribute(config, config.options.mainOddProvider, WIDGET_ATTRIBUTE_NAMES.MAIN_ODD_PROVIDER_ID);
	const defaultTab = constructDataAttribute(config, config.options.defaultTab, WIDGET_ATTRIBUTE_NAMES.DEFAULT_TAB);
	const widgetRefreshTime = constructDataAttribute(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const elements =
		config.options.elements &&
		constructElementsDataAttribute(config, config.options.elements, WIDGET_ATTRIBUTE_NAMES.ELEMENTS, config.widgetId);
	const dataHeaderDisplay = constructDataAttribute(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const amount =
		config.widgetId === WidgetsV2Ids.widgetFootballStakes
			? constructDataAttribute(config, config.options.amount, WIDGET_ATTRIBUTE_NAMES.AMOUNT)
			: '';
	const fixed =
		config.widgetId === WidgetsV2Ids.widgetFootballStakes
			? constructDataAttribute(config, config.options.fixed, WIDGET_ATTRIBUTE_NAMES.FIXED)
			: '';

	let contentData = `<div data-widget-id='${config.widgetId}' data-match-id='${config.options.matchId}' data-widget-type='event' data-widget-sport='${SportTypes.FOOTBALL}'`;

	const configSettings = [
		oddsBettingId,
		mainOddsProvider,
		oddsDisplay,
		oddsMarket,
		marketValueType,
		defaultTab,
		homeTeamColor,
		awayTeamColor,
		fansUnitedExpanded,
		fansUnitedEnabled,
		idSchema,
		environment,
		apiKey,
		clientId,
		widgetRefreshTime,
		elements,
		dataHeaderDisplay,
		amount,
		fixed,
	];

	configSettings.forEach((item: any) => {
		contentData = addValueIfNotEmpty(item, contentData);
	});
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

const constructFootballSingleRoundContent = (config: BlockConfigModel) => {
	const { oddsDisplay, oddsBettingId, oddsMarket, marketValueType } = buildOddsDataAttributes(config, true);

	const dataConfigCompetition = constructDataAttribute(config, config.options.competitionId, WIDGET_ATTRIBUTE_NAMES.COMPETITION);
	const dataConfigSeason = constructDataAttribute(config, config.options.seasonId, WIDGET_ATTRIBUTE_NAMES.SEASON);
	const dataConfigStage = constructDataAttribute(config, config.options.stageId, WIDGET_ATTRIBUTE_NAMES.STAGE);
	const dataConfigRound = constructDataAttribute(config, config.options.roundId, WIDGET_ATTRIBUTE_NAMES.ROUND);
	const dataHeaderDisplay = constructDataAttribute(config, config.options.displayHeader, WIDGET_ATTRIBUTE_NAMES.HEADER_DISPLAY);
	const limit = constructDataAttribute(config, config.options.limit, WIDGET_ATTRIBUTE_NAMES.LIMIT);
	const widgetRefreshTime = constructDataAttribute(config, config.options.refreshTime, WIDGET_ATTRIBUTE_NAMES.REFRESH_TIME);
	const dataConfigSortDirection = constructDataAttribute(config, config.options.sortDirection, WIDGET_ATTRIBUTE_NAMES.SORT_DIRECTION);
	const dataDropdownDisplay = constructDataAttribute(config, config.options.withRoundDropdown, WIDGET_ATTRIBUTE_NAMES.DROPDOWN_DISPLAY);

	let contentData = `<div data-widget-id='${config.widgetId}' data-widget-sport='${SportTypes.FOOTBALL}' data-widget-type='tournament'`;
	const configSettings = [
		dataConfigCompetition,
		dataConfigSeason,
		dataConfigStage,
		dataConfigRound,
		dataConfigSortDirection,
		limit,
		dataHeaderDisplay,
		oddsDisplay,
		oddsBettingId,
		oddsMarket,
		marketValueType,
		dataDropdownDisplay,
		widgetRefreshTime,
	];

	configSettings.forEach((item: any) => (contentData = addValueIfNotEmpty(item, contentData)));
	contentData += '></div>';

	return getFormattedContentData(contentData);
};

export const extractRefreshTimeFromWidgetModel = (config: any, nestedObjName: string = 'widgetModel'): { refreshTime: string | null } => {
	return { refreshTime: (config && config[nestedObjName] && config[nestedObjName].refreshTime) || null };
};

export const extractHeaderDefaultOptionFromWidgetModel = (
	config: any,
	nestedObjName: string = 'widgetModel',
): { headerDefaultOption: string | null } => {
	return { headerDefaultOption: (config && config[nestedObjName] && config[nestedObjName].headerDefaultOption) || null };
};

const widgetFootballStandingsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		groups: config && config.widgetModel.group.map((group: GroupModel) => group.id),
		highlightTeams:
			config.widgetModel.highlightTeams &&
			config.widgetModel.highlightTeams.length > 0 &&
			config.widgetModel.highlightTeams.map((team: TeamModel) => team.id),
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
		offset: config.widgetModel.offset && config.widgetModel.offset,
		limit: config.widgetModel.limit && config.widgetModel.limit,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballKnockoutConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballMatchCenterConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		matchId: config && config.match.match.id ? config.match.match.id : '',
		displayOdds: config.match.displayOdds,
		oddClient: config.match.displayOdds ? oddClientValid : null,
		market: config.match.displayOdds && config.odds.market && config.odds.market.name ? config.odds.market.name : null,
		oddProviderIds:
			config.match.displayOdds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		preEventOnly: true,
		marketValueType: 'decimal',
		...extractRefreshTimeFromWidgetModel(config, 'match'),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleEventContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballLivescoreConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		date: config && config.widgetModel.date ? config.widgetModel.date : '',
		displayOdds: config.widgetModel.displayOdds,
		displayHeader: config.widgetModel.displayHeader,
		oddClient: config.widgetModel.displayOdds ? oddClientValid : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballTeamProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		rounds:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: RoundModel) => round.id)
				: [],
		teamId: config && config.widgetModel.team && config.widgetModel.team.id ? config.widgetModel.team.id : null,
		limit: config && config.widgetModel.limit && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? config.widgetModel.dataDateFrom : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? config.widgetModel.dataDateTo : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults
				: null,
		matchType: config && config.widgetModel.matchType && config.widgetModel.matchType.value ? config.widgetModel.matchType.value : null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? oddClientValid : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballPlayerConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId:
			config && config.widgetModel.tournamentSeason && config.widgetModel.tournamentSeason.tournamentId
				? config.widgetModel.tournamentSeason.tournamentId
				: null,
		seasonId:
			config && config.widgetModel.tournamentSeason && config.widgetModel.tournamentSeason.seasonId
				? config.widgetModel.tournamentSeason.seasonId
				: null,
		matchId: config && config.widgetModel.match && config.widgetModel.match.id ? config.widgetModel.match.id : null,
		playerId: config && config.widgetModel.player && config.widgetModel.player.id ? config.widgetModel.player.id : null,
		displayTabs: config && config.widgetModel.displayTabs,
		tabs: config && config.widgetModel.tabs ? config.widgetModel.tabs : [],
		displayOdds: config.widgetModel.displayOdds,
		market: config.widgetModel.displayOdds && config.odds.market && config.odds.market.name ? config.odds.market.name : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		defaultTab: config && config.widgetModel.defaultTab && config.widgetModel.defaultTab.value && config.widgetModel.defaultTab.value,
		fansUnitedEnabled: config.widgetModel.fansUnitedEnabled,
		fansUnitedExpanded: config.widgetModel.fansUnitedExpanded,
		elements: JSON.stringify({ info: config.widgetModel.infoParameters, stats: config.widgetModel.statsParameters }),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTeamConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		seasonId:
			config && config.widgetModel.tournamentSeason && config.widgetModel.tournamentSeason.seasonId
				? config.widgetModel.tournamentSeason.seasonId
				: null,
		matchId: config && config.widgetModel.match && config.widgetModel.match.id ? config.widgetModel.match.id : null,
		teamId: config && config.widgetModel.team && config.widgetModel.team.id ? config.widgetModel.team.id : null,
		displayTabs: config && config.widgetModel.displayTabs,
		tabs: config && config.widgetModel.tabs ? config.widgetModel.tabs : [],
		displayOdds: config.widgetModel.displayOdds,
		market: config.widgetModel.displayOdds && config.odds.market && config.odds.market.name ? config.odds.market.name : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		defaultTab: config && config.widgetModel.defaultTab && config.widgetModel.defaultTab.value && config.widgetModel.defaultTab.value,
		fansUnitedEnabled: config.widgetModel.fansUnitedEnabled,
		fansUnitedExpanded: config.widgetModel.fansUnitedExpanded,
		elements: JSON.stringify({ info: config.widgetModel.infoParameters, stats: config.widgetModel.statsParameters }),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTeamSquadConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		seasonId:
			config && config.widgetModel.tournamentSeason && config.widgetModel.tournamentSeason.seasonId
				? config.widgetModel.tournamentSeason.seasonId
				: null,
		competitionId:
			config && config.widgetModel.tournamentSeason && config.widgetModel.tournamentSeason.tournamentId
				? config.widgetModel.tournamentSeason.tournamentId
				: null,
		teamId: config && config.widgetModel.team && config.widgetModel.team.id ? config.widgetModel.team.id : null,
		headerDefaultOption: config && config.widgetModel.headerDefaultOption && config.widgetModel.headerDefaultOption.value,
		elements: config.widgetModel.elements,
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballSingleEventConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		matchId: config && config.match.match.id ? config.match.match.id : '',
		oddClient: config.match.displayOdds ? oddClientValid : null,
		market: config.match.displayOdds && config.odds.market && config.odds.market.name ? config.odds.market.name : null,
		oddProviderIds:
			config.match.displayOdds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayOdds: config.match.displayOdds,
		preEventOnly: true,
		marketValueType: 'decimal',
		fansUnitedEnabled: config.match.fansUnitedEnabled,
		fansUnitedExpanded: config.match.fansUnitedExpanded,
		...extractRefreshTimeFromWidgetModel(config, 'match'),
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleEventContent(blockConfig);

	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballTournamentProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		rounds:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: RoundModel) => round.id)
				: [],
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? oddClientValid : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		limit: config && config.widgetModel.limit && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? config.widgetModel.dataDateFrom : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? config.widgetModel.dataDateTo : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults
				: null,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballPlayerH2HConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		player1Id: config.widgetModel.player1 && config.widgetModel.player1.id && config.widgetModel.player1.id,
		player2Id: config.widgetModel.player2 && config.widgetModel.player2.id && config.widgetModel.player2.id,
		player1TournamentSeasonId:
			config.widgetModel.player1TournamentSeason &&
			config.widgetModel.player1TournamentSeason.seasonId &&
			config.widgetModel.player1TournamentSeason.seasonId,
		player2TournamentSeasonId:
			config.widgetModel.player2TournamentSeason &&
			config.widgetModel.player2TournamentSeason.seasonId &&
			config.widgetModel.player2TournamentSeason.seasonId,
		elements: config.widgetModel.stats,
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTeamH2HConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		team1Id: config.widgetModel.team1 && config.widgetModel.team1.id && config.widgetModel.team1.id,
		team2Id: config.widgetModel.team2 && config.widgetModel.team2.id && config.widgetModel.team2.id,
		team1TournamentSeasonId:
			config.widgetModel.team1TournamentSeason &&
			config.widgetModel.team1TournamentSeason.seasonId &&
			config.widgetModel.team1TournamentSeason.seasonId,
		team2TournamentSeasonId:
			config.widgetModel.team2TournamentSeason &&
			config.widgetModel.team2TournamentSeason.seasonId &&
			config.widgetModel.team2TournamentSeason.seasonId,
		elements: config.widgetModel.stats,
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTeamH2HMatchConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		team1Id: config.widgetModel.team1 && config.widgetModel.team1.id && config.widgetModel.team1.id,
		team2Id: config.widgetModel.team2 && config.widgetModel.team2.id && config.widgetModel.team2.id,
		team1TournamentSeasonId:
			config.widgetModel.team1TournamentSeason &&
			config.widgetModel.team1TournamentSeason.seasonId &&
			config.widgetModel.team1TournamentSeason.seasonId,
		team2TournamentSeasonId:
			config.widgetModel.team2TournamentSeason &&
			config.widgetModel.team2TournamentSeason.seasonId &&
			config.widgetModel.team2TournamentSeason.seasonId,
		displayHeader: config.widgetModel.displayHeader,
		elements: config.widgetModel.stats,
		matchId: config.widgetModel.match && config.widgetModel.match.id ? config.widgetModel.match.id : null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? oddClientValid : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.odds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		market: config.widgetModel.displayOdds && config.odds.market && config.odds.market.name ? config.odds.market.name : null,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballMatchesH2HConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		team1Id: config.widgetModel.team1 && config.widgetModel.team1.id,
		team2Id: config.widgetModel.team2 && config.widgetModel.team2.id,
		limit: config.widgetModel.limit && config.widgetModel.limit,
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTeamFormConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		teamId: config.widgetModel && config.widgetModel.team && config.widgetModel.team.id ? config.widgetModel.team.id : null,
		competitionId: config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		limit: config.widgetModel.limit,
		title: config.widgetModel.title,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballTopScorersConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		teamIds:
			config.widgetModel && config.widgetModel.teams && config.widgetModel.teams.length > 0
				? config.widgetModel.teams.map((team: TeamModel) => team.id)
				: [],
		competitionId: config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		filter: config.widgetModel.filter ? config.widgetModel.filter : null,
		highlightPlayers:
			config.widgetModel.topScorers && config.widgetModel.topScorers.length > 0
				? config.widgetModel.topScorers.map((player: PlayerModel) => player.id)
				: [],
		limit: config.widgetModel.limit,
		startFromPosition: config.widgetModel.startFrom,
		elements: config.widgetModel.stats,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetFootballMostDecoratedPlayersConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		limit: config.widgetModel.limit,
		startFromPosition: config.widgetModel.startFrom,
		competitionId: config.widgetModel.tournament && config.widgetModel.tournament.id,
		seasonId: config.widgetModel.season && config.widgetModel.season.id,
		highlightPlayers: config.widgetModel.highlightPlayers && config.widgetModel.highlightPlayers.map((player: PlayerModel) => player.id),
		elements: config.widgetModel.columns,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballLineupsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const coachSelected = checkIfElementSelected(config.widgetModel.coachParameters && config.widgetModel.coachParameters.name);
	const firstTeamSelected = checkIfElementSelected(config.widgetModel.firstTeamParameters && config.widgetModel.firstTeamParameters.name);
	const substitutesSelected = checkIfElementSelected(
		config.widgetModel.substitutesParameters && config.widgetModel.substitutesParameters.name,
	);
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		defaultTab: config.widgetModel.defaultTab && config.widgetModel.defaultTab.value && config.widgetModel.defaultTab.value,
		matchId: config.widgetModel.match.id ? config.widgetModel.match.id : '',
		homeTeamColor: extractColor(config.widgetModel.homeTeamColor),
		awayTeamColor: extractColor(config.widgetModel.awayTeamColor),
		displayHeader: config.widgetModel.displayHeader,
		elements:
			coachSelected || firstTeamSelected || substitutesSelected
				? [
						JSON.stringify(coachSelected ? config.widgetModel.coachParameters : null),
						JSON.stringify(firstTeamSelected ? config.widgetModel.firstTeamParameters : null),
						JSON.stringify(substitutesSelected ? config.widgetModel.substitutesParameters : null),
				  ].filter((item) => item !== 'null')
				: [],
		...extractRefreshTimeFromWidgetModel(config),
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleEventContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballOddsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		market: config.widgetModel.market && config.widgetModel.market.name ? config.widgetModel.market : null,
		matchId: config.widgetModel.match && config.widgetModel.match.id ? config.widgetModel.match.id : null,
		oddProviderIds:
			config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		oddClient: oddClientValid,
		preEventOnly: true,
		marketValueType:
			config.widgetModel.marketValueType && config.widgetModel.marketValueType.name ? config.widgetModel.marketValueType : null,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleEventContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballStakesConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
	const oddClientValid = featureConfig.oddClient ? featureConfig.oddClient : null;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		matchId: config.widgetModel.match && config.widgetModel.match.id ? config.widgetModel.match.id : null,
		amount: !isNaN(config.widgetModel.amount) && config.widgetModel.amount,
		fixed: !!config.widgetModel.fixedAmount,
		displayOdds: true,
		oddProviderIds:
			config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		oddClient: oddClientValid,
		preEventOnly: true,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleEventContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetFootballSingleRoundConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.FOOTBALL_V2),
		competitionId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		roundId: config && config.widgetModel.round && config.widgetModel.round.id ? config.widgetModel.round.id : null,
		displayHeader: config.widgetModel.displayHeader,
		limit: config && config.widgetModel.limit && config.widgetModel.limit ? config.widgetModel.limit : null,
		sortDirection:
			config && config.widgetModel.sortDirection && config.widgetModel.sortDirection.length > 0 ? config.widgetModel.sortDirection : null,
		withRoundDropdown: config.widgetModel.withRoundDropdown,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: block.data.preview.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructFootballSingleRoundContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

//Basketball widgets
const widgetBasketballSingleEventConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.BASKETBALL);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.BASKETBALL),
		gameId: config && config.game && config.game.game.id ? config.game.game.id : '',
		displayOdds: block.data.preview.game.displayOdds,
		oddClient: block.data.preview.game.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		market: block.data.preview.game.displayOdds && block.data.preview.odds.market ? block.data.preview.odds.market : MARKET_TYPES.TWO_WAY,
		oddProviderIds:
			block.data.preview.game.displayOdds && block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: SportBookmakerModel) => bookmaker.id).join(',')
				: '',
		...extractRefreshTimeFromWidgetModel(config, 'game'),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructBasketballEventWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetBasketballLivescoreConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.BASKETBALL);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.BASKETBALL),
		date: config && config.widgetModel.date ? config.widgetModel.date : '',
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructBasketballTournamentWidgetContent(blockConfig);

	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetBasketballStandingsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.BASKETBALL),
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructBasketballTournamentWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetBasketballTournamentProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.BASKETBALL);
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.BASKETBALL),
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		roundsFilter:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: BasketballRoundModel) => {
						return { stageId: config.widgetModel.stage.id, roundId: round.id };
				  })
				: [],
		limit: config && config.widgetModel.limit && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? moment(config.widgetModel.dataDateFrom).format('YYYY-MM-DD') : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? moment(config.widgetModel.dataDateTo).format('YYYY-MM-DD') : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures.toUpperCase()
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults.toUpperCase()
				: null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructBasketballTournamentWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

const widgetBasketballTeamProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.BASKETBALL);
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.BASKETBALL),
		teamId: config && config.widgetModel.team && config.widgetModel.team.id ? config.widgetModel.team.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		roundsFilter:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: BasketballRoundModel) => {
						return { stageId: config.widgetModel.stage.id, roundId: round.id };
				  })
				: [],
		matchType: config && config.widgetModel.matchType && config.widgetModel.matchType.value ? config.widgetModel.matchType.value : null,
		limit: config && config.widgetModel.limit && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? moment(config.widgetModel.dataDateFrom).format('YYYY-MM-DD') : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? moment(config.widgetModel.dataDateTo).format('YYYY-MM-DD') : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures.toUpperCase()
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults.toUpperCase()
				: null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructBasketballTournamentWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;
	return block;
};

// Tennis widgets
const widgetTennisLivescoreConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.TENNIS);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		date: config && config.widgetModel.date ? config.widgetModel.date : '',
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTennisSingleEventConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.TENNIS);

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		matchId: config && config.match.match.id ? config.match.match.id : '',
		oddClient: config.match.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		market: config.match.displayOdds && config.odds.market && config.odds.market ? config.odds.market : MARKET_TYPES.TWO_WAY,
		oddProviderIds:
			config.match.displayOdds && config.odds.bookmakers && config.odds.bookmakers.length > 0
				? config.odds.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayOdds: config.match.displayOdds,
		preEventOnly: true,
		marketValueType: 'decimal',
		...extractRefreshTimeFromWidgetModel(config, 'match'),
	};
	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisSingleEventContent(blockConfig);

	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTennisRankingConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		limit: config.ranking.limit,
		...extractRefreshTimeFromWidgetModel(config, 'ranking'),
		offset: config.ranking.offset,
		standingType:
			config.ranking.availableStandingType.standingType && config.ranking.availableStandingType.standingType.subcategory
				? config.ranking.availableStandingType.standingType.subcategory
				: '',
		highlightPlayers:
			config.ranking.highlightPlayers && config.ranking.highlightPlayers.length > 0
				? config.ranking.highlightPlayers.map((player: RankingPlayerModel) => player.id)
				: [],
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisTournamentWidget(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTennisPlayoffConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.value ? config.widgetModel.season.value : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		displayHeader: config.widgetModel.displayHeader,
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisPlayoffWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTennisAthleteProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.TENNIS);

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		playerId: config && config.widgetModel.athlete && config.widgetModel.athlete.id ? config.widgetModel.athlete.id : null,
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		season: config && config.widgetModel.season && config.widgetModel.season.value ? config.widgetModel.season.value : null,
		tournamentId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		roundsFilter:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: TennisRoundModel) => {
						return { tournamentId: config.widgetModel.tournament.id, roundId: round.id };
				  })
				: [],
		limit: config && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? moment(config.widgetModel.dataDateFrom).format('YYYY-MM-DD') : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? moment(config.widgetModel.dataDateTo).format('YYYY-MM-DD') : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures.toUpperCase()
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults.toUpperCase()
				: null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		displayHeader: config.widgetModel.displayHeader,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		gameType: config && config.widgetModel.gameType ? config.widgetModel.gameType : null,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisProgrammeWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetTennisTournamentProgrammeConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.TENNIS);

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.TENNIS),
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		season: config && config.widgetModel.season && config.widgetModel.season.value ? config.widgetModel.season.value : null,
		tournamentId: config && config.widgetModel.tournament && config.widgetModel.tournament.id ? config.widgetModel.tournament.id : null,
		roundsFilter:
			config && config.widgetModel.rounds && config.widgetModel.rounds.length > 0
				? config.widgetModel.rounds.map((round: TennisRoundModel) => {
						return { tournamentId: config.widgetModel.tournament.id, roundId: round.id };
				  })
				: [],
		limit: config && config.widgetModel.limit ? config.widgetModel.limit : null,
		dataDateFrom: config && config.widgetModel.dataDateFrom ? moment(config.widgetModel.dataDateFrom).format('YYYY-MM-DD') : null,
		dataDateTo: config && config.widgetModel.dataDateTo ? moment(config.widgetModel.dataDateTo).format('YYYY-MM-DD') : null,
		sortDirectionFixtures:
			config && config.widgetModel.sortDirectionFixtures && config.widgetModel.sortDirectionFixtures.length > 0
				? config.widgetModel.sortDirectionFixtures.toUpperCase()
				: null,
		sortDirectionResults:
			config && config.widgetModel.sortDirectionResults && config.widgetModel.sortDirectionResults.length > 0
				? config.widgetModel.sortDirectionResults.toUpperCase()
				: null,
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		gameType: config && config.widgetModel.gameType ? config.widgetModel.gameType : null,
		gender: config && config.widgetModel.gender ? config.widgetModel.gender : null,
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructTennisProgrammeWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetIceHockeyLivescoreConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.ICE_HOCKEY);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.ICE_HOCKEY),
		date: config && config.widgetModel.date ? config.widgetModel.date : '',
		displayOdds: config.widgetModel.displayOdds,
		oddClient: config.widgetModel.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		oddProviderIds:
			config.widgetModel.displayOdds && config.widgetModel.bookmakers && config.widgetModel.bookmakers.length > 0
				? config.widgetModel.bookmakers.map((bookmaker: SportOddsBookmakerModel) => bookmaker.id).join(',')
				: '',
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructIceHockeyWidgetContent(blockConfig);

	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetIceHockeySingleEventConfig = (block: BlockModel): BlockModel => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.ICE_HOCKEY);
	const project = store.getState().project.currentProject;
	const config = block.data.preview;
	const options = {
		...sportsConfigFromProject(project, FeatureTypes.ICE_HOCKEY),
		gameId: config && config.game && config.game.game.id ? config.game.game.id : '',
		displayOdds: block.data.preview.game.displayOdds,
		oddClient: block.data.preview.game.displayOdds ? (featureConfig.oddClient ? featureConfig.oddClient : null) : null,
		market: block.data.preview.game.displayOdds && block.data.preview.odds.market ? block.data.preview.odds.market : MARKET_TYPES.TWO_WAY,
		oddProviderIds:
			block.data.preview.game.displayOdds && block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
				? block.data.preview.odds.bookmakers.map((bookmaker: SportBookmakerModel) => bookmaker.id).join(',')
				: '',
		...extractRefreshTimeFromWidgetModel(config, 'game'),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructIceHockeyEventWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

const widgetIceHockeyStandingsConfig = (block: BlockModel): BlockModel => {
	const project = store.getState().project.currentProject;
	const config = block.data.preview;

	const options = {
		...sportsConfigFromProject(project, FeatureTypes.ICE_HOCKEY),
		competitionId: config && config.widgetModel.competition && config.widgetModel.competition.id ? config.widgetModel.competition.id : null,
		seasonId: config && config.widgetModel.season && config.widgetModel.season.id ? config.widgetModel.season.id : null,
		stageId: config && config.widgetModel.stage && config.widgetModel.stage.id ? config.widgetModel.stage.id : null,
		displayHeader: config.widgetModel.displayHeader,
		...extractHeaderDefaultOptionFromWidgetModel(config),
		...extractRefreshTimeFromWidgetModel(config),
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(WidgetsV2Ids[block.data.widget_type]).build();
	const blockContent = constructIceHockeyStandingsWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

export const mapWidgetV2Config = {
	widgetFootballStandings: widgetFootballStandingsConfig,
	widgetFootballLivescore: widgetFootballLivescoreConfig,
	widgetFootballTeamProgramme: widgetFootballTeamProgrammeConfig,
	widgetFootballKnockout: widgetFootballKnockoutConfig,
	widgetFootballPlayer: widgetFootballPlayerConfig,
	widgetFootballSingleEvent: widgetFootballSingleEventConfig,
	widgetFootballTeam: widgetFootballTeamConfig,
	widgetFootballTeamSquad: widgetFootballTeamSquadConfig,
	widgetFootballTournamentProgramme: widgetFootballTournamentProgrammeConfig,
	widgetFootballPlayerH2H: widgetFootballPlayerH2HConfig,
	widgetFootballTeamH2H: widgetFootballTeamH2HConfig,
	widgetFootballTeamH2HMatch: widgetFootballTeamH2HMatchConfig,
	widgetFootballMatchesH2H: widgetFootballMatchesH2HConfig,
	widgetFootballTeamForm: widgetFootballTeamFormConfig,
	widgetFootballTopScorers: widgetFootballTopScorersConfig,
	widgetFootballMostDecoratedPlayers: widgetFootballMostDecoratedPlayersConfig,
	widgetFootballLineups: widgetFootballLineupsConfig,
	widgetBasketballSingleEvent: widgetBasketballSingleEventConfig,
	widgetBasketballLivescore: widgetBasketballLivescoreConfig,
	widgetBasketballStandings: widgetBasketballStandingsConfig,
	widgetTennisLivescore: widgetTennisLivescoreConfig,
	widgetTennisSingleEvent: widgetTennisSingleEventConfig,
	widgetTennisRanking: widgetTennisRankingConfig,
	widgetTennisPlayoff: widgetTennisPlayoffConfig,
	widgetBasketballTournamentProgramme: widgetBasketballTournamentProgrammeConfig,
	widgetBasketballTeamProgramme: widgetBasketballTeamProgrammeConfig,
	widgetTennisAthleteProgramme: widgetTennisAthleteProgrammeConfig,
	widgetTennisTournamentProgramme: widgetTennisTournamentProgrammeConfig,
	widgetFootballOdds: widgetFootballOddsConfig,
	widgetFootballStakes: widgetFootballStakesConfig,
	widgetFootballSingleRound: widgetFootballSingleRoundConfig,
	widgetIceHockeySingleEvent: widgetIceHockeySingleEventConfig,
	widgetIceHockeyLivescore: widgetIceHockeyLivescoreConfig,
	widgetIceHockeyStandings: widgetIceHockeyStandingsConfig,
	widgetFootballMatchCenter: widgetFootballMatchCenterConfig,
};
