import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import FootballSingleEventEditComponent from './football-single-event-edit.component';
import { FootballSingleEventViewComponent } from './football-single-event-view.component';
import FootballSingleEventWidgetModel from '../models/football-single-event-widget.model';
import OddsWidgetModel from '../../../../blocks/widgets/odds/models/odds-widget.model';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { BlockyV2Properties } from '../../../utils/helper';

const FootballSingleEventWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <FootballSingleEventEditComponent t={t} block={block} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.FOOTBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballSingleEventViewComponent
				t={t}
				matchPreview={
					block.data.preview && block.data.preview.match ? block.data.preview.match : FootballSingleEventWidgetModel.builder().build()
				}
				oddsPreview={block.data.preview && block.data.preview.odds ? block.data.preview.odds : OddsWidgetModel.builder().build()}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballSingleEventWidget);
