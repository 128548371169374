import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { BasketballTournamentProgrammeView } from './basketball-tournament-programme-view.component';
import BasketballTournamentProgrammeEdit from './basketball-tournament-programme-edit.component';
import BasketballTournamentProgrammeWidgetModel from '../models/basketball-tournament-programme.model';
import { BlockyV2Properties } from '../../../utils/helper';

const BasketballTournamentProgrammeWidget: React.FunctionComponent<BlockyV2Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <BasketballTournamentProgrammeEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return <MonorepoWidgetPreview blockContent={props.block.data.content} config={props.block.data.config} sport={SportTypes.BASKETBALL} />;
	}

	if (view === ViewTypes.normal) {
		return (
			<BasketballTournamentProgrammeView
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: BasketballTournamentProgrammeWidgetModel.builder().build()
				}
				t={t}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(BasketballTournamentProgrammeWidget);
