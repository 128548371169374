import { TagJson } from './TagJson';
import Tag from './Tag';
import Urls from '../urls/Urls';
import { TagRequestJson } from './TagRequestJson';
import Seo from '../seo/Seo';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import EnumItem from '../enum/EnumItem';

export default class TagBuilder {
	private json: TagJson;

	constructor(tag?: Tag | TagJson) {
		if (tag && tag instanceof Tag) {
			this.json = tag.toJSON();
		} else if (tag) {
			this.json = tag;
		} else {
			this.json = {} as TagJson;
		}
	}

	withId(id: string): TagBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): TagBuilder {
		this.json.title = title;

		return this;
	}

	withDescription(description: string): TagBuilder {
		this.json.description = description;

		return this;
	}

	withCreatedAt(createdAt: string): TagBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): TagBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withCreatedBy(created_by: any[]): TagBuilder {
		this.json.created_by = created_by;

		return this;
	}

	withSeo(seo: Seo): TagBuilder {
		this.json.seo = seo;

		return this;
	}

	withUrls(urls: Urls): TagBuilder {
		this.json.urls = urls;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): TagBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	withWeight(weight: number): TagBuilder {
		this.json.weight = weight;

		return this;
	}

	withType(type: string): TagBuilder {
		this.json.type = type;

		return this;
	}

	withOrderType(type: EnumItem): TagBuilder {
		this.json.orderType = type;

		return this;
	}

	build(): Tag {
		return Tag.fromJSON(this.json);
	}

	equals(tag: Tag): boolean {
		const idEqual = this.json.id === tag.id;
		const titleEqual = this.json.title === tag.title;
		const descriptionEqual = this.json.description === tag.description;
		const createdAtEqual = this.json.createdAt === tag.createdAt;
		const updatedAttEqual = this.json.updatedAt === tag.updatedAt;
		//TODO Add a normal MainMediaCheck
		const isMainMediaSet = this.json.mainMedia === tag.mainMedia;
		const isWeightEqual = this.json.weight === tag.weight;
		const isTypeEqual = this.json.type === tag.type;
		const isOrderTypeEqual = this.json.orderType === tag.orderType;

		return (
			idEqual &&
			titleEqual &&
			descriptionEqual &&
			isMainMediaSet &&
			createdAtEqual &&
			updatedAttEqual &&
			isWeightEqual &&
			isTypeEqual &&
			isOrderTypeEqual
		);
	}

	toRequestJson(): TagRequestJson {
		let json = {} as TagRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			automatic_seo_title: false,
			automatic_slug: false,
		};

		json.id = this.json.id;
		json.title = this.json.title;
		json.description = this.json.description;
		json.external_url = this.json.urls && this.json.urls.externalUrl ? this.json.urls.externalUrl : '';
		json.canonical_url = this.json.urls && this.json.urls.canonicalUrl ? this.json.urls.canonicalUrl : '';
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.seo.automatic_seo_title = this.json.seo && this.json.seo.autoTitle ? this.json.seo.autoTitle : false;
		json.seo.automatic_slug = this.json.seo && this.json.seo.autoSlug ? this.json.seo.autoSlug : false;
		json.weight = this.json.weight;
		json.type = this.json.type;

		if (this.json.orderType && this.json.orderType.id) json.order_type = this.json.orderType.id;
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];

		return json;
	}
}
